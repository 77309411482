import React from 'react';
import PropTypes from 'prop-types';

// Components
import Picto from '../Picto';

// Utils
import cn from '../../../utils/cn';

// Style
import style from './AvatarMenu.module.css';

export const AvatarMenu = ({ image, onClick, className }) => (
  <div onClick={onClick} className={cn([style.wrapper, className])}>
    {image
      ? <img className={style.avatar} src={image} alt="dropdown handle with profil" />
      : <Picto className={style.avatar} icon="userCircle" />
    }
  </div>
);

AvatarMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string,
  className: PropTypes.string,
};

AvatarMenu.defaultProps = {
  image: null,
  className: null,
};

export default AvatarMenu;
