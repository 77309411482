import React, {
  Fragment, useState, useRef, useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Misc
import cn from '../../../utils/cn';

// Style
import style from './DropdownMenu.module.css';

export const useDropdown = () => {
  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled(!isToggled);
  return [isToggled, toggle];
};

export const DropdownMenu = ({ handle, children }) => {
  const [isToggled, setIsToggled] = useDropdown();
  const menuRef = useRef(null);
  const childrenRef = useRef(null);

  useEffect(() => {
    const outsideClickListner = (e) => {
      if (
        isToggled
        && childrenRef.current
        && !childrenRef.current.contains(e.target)
      ) {
        setIsToggled();
      }
    };
    window.addEventListener('click', outsideClickListner);
    return () => window.removeEventListener('click', outsideClickListner, false);
  }, [isToggled]);

  return (
    <div ref={menuRef} style={{ display: 'inline-block' }}>
      {React.cloneElement(
        handle,
        {
          onClick: setIsToggled,
          className: cn([style.handle, handle.props.className]),
        },
      )}
      {isToggled
        ? ReactDOM.createPortal(
          <div
            ref={childrenRef}
            className={style.menu}
            style={{
              top: menuRef.current.getBoundingClientRect().bottom,
              left: menuRef.current.getBoundingClientRect().right,
              transform: 'translateX(-100%)',
            }}
          >
            {children}
          </div>,
          document.getElementById('root'),
        )
        : null
      }
    </div>
  );
};

DropdownMenu.propTypes = {
  handle: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

export default DropdownMenu;
