import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Utils & Misc
import cn from '../../../utils/cn';
// import i18n from '../../../i18n';

// Style
import style from './LanguageSelector.module.css';

const useQuery = () => new URLSearchParams(useLocation().search);

export const LanguageSelector = ({ languages, shade }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    const lang = query.get('lang');

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [location ? location.search : null]);

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className={cn([style.chevron, style[shade]])}>
      <select value={i18n.language} className={style.select} onChange={handleChange}>
        {languages.map(lang => (
          <option
            key={lang}
            value={lang}
            className={style.option}
          >
            {lang}
          </option>
        ))}
      </select>
    </div>
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  shade: PropTypes.oneOf(['dark', 'light']),
};

LanguageSelector.defaultProps = {
  shade: 'dark',
};

export default LanguageSelector;
