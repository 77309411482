import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './TabSelector.module.css';

export const TabSelector = React.memo(({
  tabs,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleOnClick = index => setActiveTab(index);

  const handleKeyDown = (e, index) => e.key === 'Enter' && setActiveTab(index);

  return (
    <div className={style.wrapper}>
      <nav>
        <ul className={style.nav}>
          {tabs.map((({ label }, index) => (
            <li
              key={label}
              role="button"
              tabIndex="0"
              className={cn([style.navItem, (index === activeTab) && style.active])}
              onClick={() => handleOnClick(index)}
              onKeyDown={e => handleKeyDown(e, index)}
            >
              {label}
            </li>
          )))}
        </ul>
      </nav>
      <div className={style.content}>
        {tabs.map((tab, index) => index === activeTab && tab.child)}
      </div>
    </div>
  );
});

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      child: PropTypes.element,
    }).isRequired,
  ).isRequired,
};

export default TabSelector;
