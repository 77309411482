import React from 'react';
import PropTypes from 'prop-types';

// Components
import Picto from '../Picto';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './ProgressCircle.module.css';

export const ProgressCircle = ({
  number, unit, isComplete, isActive, ...propsLeft
}) => (
  <div
      className={cn([
        style.circle,
        isComplete ? style.complete : null,
        isActive ? style.active : null,
      ])}
      {...propsLeft}
    >
      {!isComplete
        ? (
          <span className={style.number}>
            {number}
            {unit}
          </span>
        )
        : <Picto icon="check" className={style.icon} />
      }
    </div>
);

ProgressCircle.propTypes = {
  number: PropTypes.number.isRequired,
  unit: PropTypes.string,
  isComplete: PropTypes.bool,
  isActive: PropTypes.bool,
};

ProgressCircle.defaultProps = {
  unit: null,
  isComplete: false,
  isActive: false,
};

export default ProgressCircle;
