export default function (urlQuery) {
  const explodedQueryArray = urlQuery
    .substr(1)
    .split('&');
  const paramsToObject = explodedQueryArray.reduce((acc, param) => {
    const splitedParam = param.split('=');
    return { ...acc, [splitedParam[0]]: splitedParam[1] };
  }, {});
  return paramsToObject;
}
