import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils & misc
import cn from '../../../utils/cn';

// Style
import style from './Accordion.module.css';

export const useAccordion = () => {
  const [isToggled, setIsToggled] = useState(false);
  const toggle = state => setIsToggled(state);
  return [isToggled, toggle];
};

export const Accordion = ({ children, isToggled, className }) => {
  const wrapperRef = useRef(null);
  const [refLoaded, setRefLoaded] = useState(false);

  useEffect(() => {
    if (wrapperRef.current) setRefLoaded(true);
  }, [wrapperRef.current]);

  return (
    <div
      ref={wrapperRef}
      className={cn([style.wrapper, isToggled && style.toggled])}
      style={{ maxHeight: (isToggled && refLoaded) ? `${wrapperRef.current.scrollHeight + 4}px` : 0 }}
    >
      <div className={cn([className, style.content])}>
        {children}
      </div>
    </div>
  );
};
Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  isToggled: PropTypes.bool,
  className: PropTypes.string,
};

Accordion.defaultProps = {
  className: null,
  isToggled: false,
};

export default Accordion;
