import React, { useContext, useEffect } from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import { KeycloakAuthContext } from './components/templates/KeycloakAuthProvider';

// Routes
import { routes, protectedRoutes } from './routes';
import Auth from './views/Auth';

const Routes = () => {
  const { keycloak } = useContext(KeycloakAuthContext);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <Switch>
      {routes.map(route => <Route key={route.path} {...route} />)}

      {keycloak.authenticated
        ? protectedRoutes.map(route => <Route key={route.path} {...route} />)
        : null}

      <Route path="/" component={Auth} />
    </Switch>
  );
};

export default Routes;
