import i18n from 'i18next';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

// Components
import NoSide from '../components/templates/NoSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';

// Utils & misc
import background from '../assets/images/login_background.png';
// import imgCard from '../assets/images/login_card.png';
import imgCard from '../assets/images/login_card_1.jpg';
import { SPA_HOSTNAME } from '../constants/constants';
import { KeycloakAuthContext } from '../components/templates/KeycloakAuthProvider';

// Style
import style from './Auth.module.css';

const Auth = ({ location }) => {
  const { t } = useTranslation();
  const { keycloak } = useContext(KeycloakAuthContext);

  const handleAuth = async () => {
    keycloak.login({
      kcLocale: i18n.language,
      redirectUri: `https://${SPA_HOSTNAME}/students`,
    });
  };

  useEffect(() => {
    const redirectToPath = location.pathname;
    const isLoginPage = location.pathname === '/' || location.pathname === '/login';

    // auto login if needed
    if (!isLoginPage && !isEmpty(keycloak) && !keycloak.authenticated) {
      keycloak.login({
        kcLocale: i18n.language,
        redirectUri: `https://${SPA_HOSTNAME}${redirectToPath}`,
      });
    }
  }, [keycloak]);

  return (
    <NoSide title={t('auth.title')} subtitle={t('auth.subtitle')} background={background}>
      <Card
        rounded
        image={imgCard}
      >
        <div className={style.textBlock}>
          <span className={style.connect}>{t('auth.connect')}</span>
          <span className={style.accent}>{t('auth.subscriptionFile')}</span>
        </div>
        <div className={style.form}>
          <Button
            className={style.submit}
            type="button"
            label={t('auth.submit')}
            onClick={handleAuth}
            hasLoader={keycloak.authenticated}
            disabled={keycloak.authenticated}
          />
        </div>
        <span className={style.legals}>{t('auth.legals')}</span>
      </Card>
    </NoSide>
  );
};
Auth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Auth;
