import { parsePhoneNumberFromString, formatPhoneNumber, isValidNumber } from 'libphonenumber-js';

export const isNumberValidFromString = (number) => {
  if (!number) return false;
  const formatted = parsePhoneNumberFromString(
    typeof number !== 'string' ? '' : number,
  );
  if (!formatted || !formatted.country || !formatted.nationalNumber) return false;
  return (isValidNumber(formatted.nationalNumber, formatted.country));
};

export const eslint = 'ESLINT';
