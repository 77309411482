import React from 'react';
import PropTypes from 'prop-types';

// Utils
import cn from '../../../utils/cn';

// Style
import style from './FormBlock.module.css';

export const FormBlock = ({ children, label, noMargin }) => (
  <div className={cn([style.block, noMargin && style.noMargin])}>
    {label ? <span className={style.label}>{label}</span> : null}
    {children}
  </div>
);

FormBlock.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node,
  noMargin: PropTypes.bool,
};

FormBlock.defaultProps = {
  label: null,
  noMargin: false,
};

export default FormBlock;
