const ORIGIN = '2019/12/30 00:00:00';

export const weekIdToDate = (weekId) => {
  const originDate = new Date(ORIGIN);
  const daysToAdd = (weekId - 1) * 7;

  originDate.setDate(originDate.getDate() + daysToAdd);
  originDate.setHours(0, 0, 0, 0);
  return originDate;
};

export const dateToWeekId = (date) => {
  const copiedDate = new Date(date.getTime());
  const originDate = new Date(ORIGIN);
  const day = 24 * 60 * 60 * 1000;

  copiedDate.setHours(0, 0, 0, 0);

  const daysElapsed = Math.abs((copiedDate - originDate) / day);
  return Math.floor(daysElapsed / 7) + 1;
};

export const getISOWeeks = (y) => {
  const d = new Date(y, 0, 1);
  const isLeap = new Date(y, 1, 29).getMonth() === 1;
  return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
};
