import React from 'react';
import PropTypes from 'prop-types';

// Utils & misc
import cn from '../../../utils/cn';

// Style
import style from './SideMenu.module.css';

export const SideMenu = ({
  title, subtitle, children, className, ...propsLeft
}) => (
  <aside className={cn([style.menu, className])} {...propsLeft}>
      {(title || subtitle)
        ? (
          <div className={style.titleBlock}>
            {title ? <span className={style.title}>{title}</span> : null}
            {subtitle ? <span className={style.subtitle}>{subtitle}</span> : null}
          </div>
        )
        : null
      }
      <nav>
        <ul className={style.childrenList}>
          {children}
        </ul>
      </nav>
    </aside>
);

SideMenu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

SideMenu.defaultProps = {
  title: null,
  subtitle: null,
  className: null,
};

export default SideMenu;
