// Regexp
import { mailRegExp, phoneRegExp } from './regexps';

export default function (values) {
  const errors = {};

  if (!values.lastname) errors.lastname = 'required';

  if (!values.firstname) errors.firstname = 'required';

  if (!values.gender) errors.gender = 'required';

  if (!values.nativeLanguage) errors.nativeLanguage = 'required';

  if (!values.spokenLanguage) errors.spokenLanguage = 'required';

  if (!values.nationality) errors.nationality = 'required';

  if (!values.phone) errors.phone = 'required';

  if (!values.email) errors.email = 'required';
  else if (!mailRegExp.test(values.email)) errors.email = 'wrongEmail';

  if (!values.address) errors.address = 'required';

  if (!values.parent1Lastname) errors.parent1Lastname = 'required';

  if (!values.parent1Firstname) errors.parent1Firstname = 'required';

  if (!values.parent1Employment) errors.parent1Employment = 'required';

  if (!values.parent1Phone) errors.parent1Phone = 'required';

  if (!values.parent1Email) errors.parent1Email = 'required';
  else if (!mailRegExp.test(values.parent1Email)) errors.parent1Email = 'wrongEmail';

  if (!values.parent1AddressRadio) errors.parent1AddressRadio = 'required';

  if (
    values.parent1AddressRadio === 'true'
    && !values.parent1Address
  ) errors.parent1Address = 'required';

  if (!values.parent2Lastname) errors.parent2Lastname = 'required';

  if (!values.parent2Firstname) errors.parent2Firstname = 'required';

  if (!values.parent2Employment) errors.parent2Employment = 'required';

  if (!values.parent2Phone) errors.parent2Phone = 'required';
  else if (!phoneRegExp.test(values.parent2Phone)) errors.parent2Phone = 'wrongNumber';

  if (!values.parent2Email) errors.parent2Email = 'required';
  else if (!mailRegExp.test(values.parent2Email)) errors.parent2Email = 'wrongEmail';

  if (!values.parent2AddressRadio) errors.parent2AddressRadio = 'required';

  if (
    values.parent2AddressRadio === 'true'
    && !values.parent2Address
  ) errors.parent2Address = 'required';

  if (
    values.parentsSeparated === 'true'
    && !values.guardianName
  ) errors.guardianName = 'required';

  if (
    !values.sendMailMother
    || !values.sendMailFather
    || !values.sendMailOther
  ) errors.sendMail = 'required';

  if (
    values.sendMailOther === true
    && !values.sendMailGender
  ) errors.sendMailGender = 'required';

  if (
    values.sendMailOther === true
    && !values.sendMailLastname
  ) errors.sendMailLastname = 'required';

  if (
    values.sendMailOther === true
    && !values.sendMailFirstname
  ) errors.sendMailFirstname = 'required';

  if (
    values.sendMailOther === true
    && !values.sendMailPhone
  ) errors.sendMailPhone = 'required';
  else if (!phoneRegExp.test(values.sendMailPhone)) errors.sendMailPhone = 'wrongNumber';

  if (
    values.sendMailOther === true
    && !values.sendMailEmail
  ) errors.sendMailEmail = 'required';
  else if (!mailRegExp.test(values.sendMailEmail)) errors.sendMailEmail = 'wrongEmail';

  if (
    values.sendMailOther === true
    && !values.sendMailAddress
  ) errors.sendMailAddress = 'required';

  if (
    values.sendFinancialMailOther === true
    && !values.sendFinancialMailGender
  ) errors.sendFinancialMailGender = 'required';

  if (
    values.sendFinancialMailOther === true
    && !values.sendFinancialMailLastname
  ) errors.sendFinancialMailLastname = 'required';

  if (
    values.sendFinancialMailOther === true
    && !values.sendFinancialMailFirstname
  ) errors.sendFinancialMailFirstname = 'required';

  if (
    values.sendFinancialMailOther === true
    && !values.sendFinancialMailPhone
  ) errors.sendFinancialMailPhone = 'required';
  else if (!phoneRegExp.test(values.sendFinancialMailPhone)) errors.sendFinancialMailPhone = 'wrongNumber';

  if (
    values.sendFinancialMailOther === true
    && !values.sendFinancialMailEmail
  ) errors.sendFinancialMailEmail = 'required';
  else if (!mailRegExp.test(values.sendFinancialMailEmail)) errors.sendFinancialMailEmail = 'wrongEmail';

  if (
    values.sendFinancialMailOther === true
    && !values.sendFinancialMailAddress
  ) errors.sendFinancialMailAddress = 'required';

  return errors;
}
