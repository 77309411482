import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import NoSide from '../components/templates/NoSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';

// Utils & misc
import background from '../assets/images/login_background.png';
import imgCard from '../assets/images/confirmation_card_1.png';

// Style
import style from './Confirmation.module.css';

const Confirmation = ({ location, history }) => {
  const { t } = useTranslation();

  const handleDashboardRedirectClick = () => {
    if (location.state && location.state.studentId) {
      history.push(`/${location.state.studentId}/dashboard`);
    }
  };

  return (
    <NoSide background={background} verticalCenter>
      <Card
        className={style.card}
        rounded
        image={imgCard}
      >
        <div className={style.cardContent}>
          <>
            <span className={style.catchLine}>{t('confirmation.header')}</span>
            <span className={style.subline}>{t('confirmation.subHeader')}</span>
            <span className={style.baseText}>
              {t('confirmation.content1')}
              <br />
              {t('confirmation.content2')}
            </span>
          </>
          <div>
            <Button className={style.cardButton} label={t('confirmation.button')} onClick={handleDashboardRedirectClick} />
          </div>

        </div>
      </Card>
    </NoSide>
  );
};

Confirmation.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default Confirmation;
