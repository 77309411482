import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// Components
import Picto from '../../atoms/Picto';

// Utils & misc
// import cn from '../../../utils/cn';

// Style
import style from './DropZone.module.css';

export const DropZone = ({
  onDrop,
  label,
  hasFile,
  file,
  fileName,
  onClearFile,
  completed,
  onFileNameClick,
  template,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClearField = (e) => {
    e.stopPropagation();
    onClearFile();
  };
  const handleOnDragOver = (e) => {
    getRootProps().ref.current.classList.add(style.dragOver);
    getRootProps().onDragOver(e);
  };
  const handleOnDragLeave = (e) => {
    getRootProps().ref.current.classList.remove(style.dragOver);
    getRootProps().onDragLeave(e);
  };
  const handleOnDrop = (e) => {
    getRootProps().ref.current.classList.remove(style.dragOver);
    getRootProps().onDrop(e);
  };

  const handleFileNameClick = (e) => {
    e.stopPropagation();
    onFileNameClick(file);
  };

  useEffect(() => {
    if (completed) getRootProps().ref.current.classList.add(style.dragOver);
    else getRootProps().ref.current.classList.remove(style.dragOver);
    return () => {};
  }, [completed]);

  return (
    <>
      <div
        className={style.zone}
        {...getRootProps()}
        onDragOver={handleOnDragOver}
        onDragLeave={handleOnDragLeave}
        onDrop={handleOnDrop}
      >
        <div className={style.drop}>
          <input {...getInputProps()} />
          <Picto
            className={style.picto}
            icon={hasFile ? 'checkFile' : 'uploadToTheCloud'}
          />
          <span className={style.dropLabel}>
            {hasFile ? (
              <>
                {completed ? (
                  <span
                    role="link"
                    tabIndex={0}
                    onKeyDown={null}
                    className={style.fileNameLink}
                    onClick={handleFileNameClick}
                  >
                    {fileName}
                  </span>
                ) : (fileName)}
              </>
            ) : (
              'Drag & Drop'
            )}
          </span>
          {hasFile ? null : (
            <span className={style.dropSublabel}>
            your file, or click to load
            </span>
          )}
        </div>
        <div className={style.label}>{label}</div>
        {hasFile ? (
          <button
            className={style.clear}
            type="button"
            onClick={handleClearField}
          >
            <Picto icon="cross" />
          </button>
        ) : null}
      </div>
      {template && (
      <div className={style.template}>
        <a href={template} download>
          {t('global.templateDownload')}
        </a>
      </div>
      )}
    </>
  );
};

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onClearFile: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  hasFile: PropTypes.bool,
  file: PropTypes.shape(),
  fileName: PropTypes.string,
  completed: PropTypes.bool,
  onFileNameClick: PropTypes.func,
  template: PropTypes.oneOfType([PropTypes.string]),
};

DropZone.defaultProps = {
  fileName: null,
  file: null,
  hasFile: false,
  onFileNameClick: null,
  template: null,
  completed: false,
};

export default DropZone;
