import React from 'react';
import PropTypes from 'prop-types';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './SideHint.module.css';

export const SideHint = ({
  title, background, children, className, alignType, ...propsLeft
}) => (
  <aside className={cn([style.wrapper, className && className])} {...propsLeft}>
    {title ? <h4 className={style.title}>{title}</h4> : null}
    {children}
    {background ? <div className={alignType === 'centered' ? style.background : style.backgroundDeffered}>{background}</div> : null}
  </aside>
);

SideHint.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  alignType: PropTypes.string,
};

SideHint.defaultProps = {
  title: null,
  className: null,
  background: null,
  alignType: 'centered',
};

export default SideHint;
