import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { noop } from 'lodash';

// Components
import LanguageSelector from '../../atoms/LanguageSelector';
import AvatarMenu from '../../atoms/AvatarMenu';
import DropdownMenu from '../../molecules/DropdownMenu';

// Utils & Misc
import cn from '../../../utils/cn';
import { ReactComponent as Logo } from '../../../assets/logos/logo-short.svg';

// Style
import style from './Header.module.css';

export const Header = ({
  languages, user, color, menuOptions,
}) => (
  <header className={cn([style.header, style[color]])}>
    <Link to="/students"><Logo className={style.logo} /></Link>
    <div className={style.contentRight}>
      <LanguageSelector languages={languages} shade={color === 'fillWhite' ? 'dark' : 'light'} />
      {user ? (
        <DropdownMenu handle={<AvatarMenu onClick={noop} className={style.user} image={user.thumbnail || ''} />}>
          <ul>
            {menuOptions.map(option => (
              <li key={option.label} className={style.menuItem}>
                <Link to={option.to} onClick={option.onClick}>
                  {option.label}
                </Link>
              </li>
            ))}
          </ul>
        </DropdownMenu>
      )
        : null}
    </div>
  </header>
);

Header.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.shape({ id: PropTypes.string, thumbnail: PropTypes.string }),
  color: PropTypes.oneOf(['blue', 'white', 'transparent', 'fillWhite']),
};

Header.defaultProps = {
  user: null,
  color: 'blue',
  menuOptions: [],
};

export default Header;
