import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getYear, addYears, format } from 'date-fns';
import { fr, enUS } from 'date-fns/esm/locale';
import { useFormik } from 'formik';
import { find, isNil } from 'lodash';

// Components
import WithSide from '../components/templates/WithSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';
import Radio from '../components/atoms/Radio';
import FormBlock from '../components/atoms/FormBlock';
import Checkbox from '../components/atoms/Checkbox';
import MediaLabel from '../components/atoms/MediaLabel';
import MediaImage from '../components/atoms/MediaImage/MediaImage';
import AutoSave from '../components/atoms/AutoSave';

// Icons
import { ReactComponent as Loader } from '../assets/loader/oval.svg';

// Utils & Misc
import cn from '../utils/cn';
import { weekIdToDate } from '../utils/weeks';

import shouldDisable from '../utils/shouldDisable';
import allowToSubmit from '../utils/allowToSubmit';
import { generalValidate } from '../utils/validators';
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import {
  FORM_INITIAL_VALUES as FIV,
  API_URL_BASE,
  ALL_GRADES,
} from '../constants/constants';

// Style
import style from './formApp.module.css';

const RatesInformation = ({ match, history }) => {
  const { t, i18n } = useTranslation();

  const [insuranceAmount, setInsuranceAmount] = useState(0);
  const [totalPriceLoading, setTotalPriceLoading] = useState(false);

  const {
    context: { activeForm, auth, progress },
    computeProgress,
  } = useAppContext();
  const [filteredProducts, setFilteredProducts] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const {
    loading,
    responseData: formData,
    responseError: formError,
    getStudentForm,
  } = useApi();
  const {
    loading: productsLoad,
    responseData: productsData,
    getProducts,
  } = useApi();
  const {
    loading: submitLoading,
    responseError: submitError,
    updateStudentForm,
  } = useApi();
  const {
    postFormEvent,
  } = useApi();
  const {
    responseData: responseForm,
    getFormInfo,
  } = useApi();
  const { getAsyncFormInfo } = useApi();

  const { values, setFieldValue } = useFormik({
    initialValues: {
      ...FIV.generalInfo,
      ...FIV.scholarshipInfo,
      ...FIV.sportInfo,
      ...FIV.medicalInfo,
      ...FIV.administrativeInfo,
      ...FIV.ratesInfo,
      selectedProducts: [],
      ...formData,
    },
    enableReinitialize: true,
    validate: generalValidate,
  });
  const isFormLocked = useMemo(() => (
    activeForm ? shouldDisable(activeForm.stateId, auth.role) : true
  ), [activeForm]);

  // Fetch data
  useEffect(() => {
    getStudentForm(match.params.id);
    getFormInfo(match.params.id);
    getProducts({
      formId: activeForm.id,
    });
  }, []);

  useEffect(() => {
    if (!submitLoading) {
      setTotalPriceLoading(true);
      getAsyncFormInfo(match.params.id).then((res) => {
        if (res && res.data) {
          setInsuranceAmount(res.data.insuranceAmount || 0);
          setTotalPriceLoading(false);
        }
      });
    }
  }, [submitLoading]);

  const isThereEducationProgram = (productsSelected, productArray) => {
    if (productArray && productsSelected && productsSelected.length) {
      if (!productArray.education.length) return true;
      for (let i = 0; i < productArray.education.length; i += 1) {
        if (productsSelected.find(selected => selected === productArray.education[i].id)) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  // Format product data
  useEffect(() => {
    if (productsData && responseForm) {
      const newProductsArray = productsData.products.reduce(
        (acc, current) => {
          if (current.activeForForm) {
            switch (current.typeId) {
              case 1:
                return {
                  ...acc,
                  program: acc.program
                    .concat([current])
                    .filter(p => (
                      p.formTypesIds.find(fId => fId === responseForm.typeId) !== undefined
                    )),
                };
              case 2:
              case 7:
                return {
                  ...acc,
                  education: acc.education
                    .concat([current])
                    .filter(p => (
                      p.formTypesIds.find(fId => fId === responseForm.typeId) !== undefined
                    )),
                };
              case 3:
                return {
                  ...acc,
                  accommodation: acc.accommodation
                    .concat([current])
                    .filter(p => (
                      p.formTypesIds.find(fId => fId === responseForm.typeId) !== undefined
                    )),
                };
              case 4:
                return {
                  ...acc,
                  intern1: acc.intern1
                    .concat([current])
                    .filter(p => (
                      p.formTypesIds.find(fId => fId === responseForm.typeId) !== undefined
                    )),
                };
              case 5:
                return {
                  ...acc,
                  intern2: acc.intern2
                    .concat([current])
                    .filter(p => (
                      p.formTypesIds.find(fId => fId === responseForm.typeId) !== undefined
                    )),
                };
              case 6:
                return {
                  ...acc,
                  options: acc.options
                    .concat([current])
                    .filter(p => (
                      p.formTypesIds.find(fId => fId === responseForm.typeId) !== undefined
                    )),
                };
              default:
                return acc;
            }
          }
          return acc;
        },
        {
          program: [],
          education: [],
          accommodation: [],
          intern1: [],
          intern2: [],
          options: [],
        },
      );
      if (!loading) setFieldValue('tennisSchoolProgramSelected', isThereEducationProgram(formData.selectedProducts || [], newProductsArray));
      setFilteredProducts(newProductsArray);

      setInsuranceAmount(responseForm.insuranceAmount);
    }
  }, [productsData, loading]);

  const saveForm = () => {
    updateStudentForm(match.params.id, values);
    computeProgress(values, activeForm);
  };

  const displayPrice = price => (price / 100).toFixed(2);

  const handleOnChecked = (e, setField, val) => {
    if (e.target.checked) {
      const updatedSelectedProducts = values.selectedProducts.concat([
        parseInt(e.target.value, 10),
      ]);
      if (isThereEducationProgram(updatedSelectedProducts, filteredProducts)) setFieldValue('tennisSchoolProgramSelected', true);
      return setField(
        'selectedProducts',
        val.selectedProducts
          ? updatedSelectedProducts
          : [parseInt(e.target.value, 10)],
      );
    }

    const updatedSelectedProducts = values.selectedProducts.filter(
      productId => productId !== parseInt(e.target.value, 10),
    );
    if (!isThereEducationProgram(updatedSelectedProducts, filteredProducts)) setFieldValue('tennisSchoolProgramSelected', false);
    return setField('selectedProducts', updatedSelectedProducts);
  };

  const handleBadgeClick = (badgeId) => {
    if (values.badge === badgeId) setFieldValue('badge', null);
    else setFieldValue('badge', badgeId);
  };

  const computeTotal = (val) => {
    const totalProducts = val.selectedProducts
      ? val.selectedProducts.reduce((acc, currentId) => {
        const selectedProduct = find(productsData.products, {
          id: currentId,
        });

        if (selectedProduct && selectedProduct.priceForForm) {
          return acc + Number(selectedProduct.priceForForm);
        }
        return acc;
      }, 0)
      : 0;
    const accommodation = find(productsData.products, {
      id: val.selectedAccommodation,
    })
      ? Number(find(productsData.products, { id: val.selectedAccommodation })
        .priceForForm)
      : 0;

    const total = (
      (totalProducts
      + accommodation
      - (activeForm.hasDiscount ? activeForm.discountAmount : 0)).toFixed(2)
    );

    return (Number(total));
  };

  const submitForm = () => {
    postFormEvent(match.params.id, 'submit').then(() => {
      setSubmitted(true);
      history.push('/confirmation', { studentId: match.params.id });
    });
  };

  const isProductAvailable = (p) => {
    if ([1, 2, 3].find(status => status === responseForm.stateId) === undefined) return true;
    // TODO: change field name (quantity available)
    const isProductActive = p.activeForForm;
    const isStockAvailable = p.stockForForm > 0;
    if (isProductActive && isStockAvailable) return true;
    return false;
  };

  // FIXME: use the locale db information, not static values
  const getLocaleId = () => {
    switch (i18n.language) {
      case 'fr':
        return 1;
      case 'en':
        return 2;
      default:
        return 1;
    }
  };

  const handleSelectAccommodation = (product) => {
    if (!isProductAvailable(product)) return;

    setFieldValue(
      'selectedAccommodation',
      values.selectedAccommodation === product.id ? null : product.id,
    );
  };

  return (
    <WithSide title={t('ratesInformation.viewTitle')}>
      {loading || productsLoad ? (
        <div />
      ) : (
        <form>
          <Card className="card-margin">
            <h2 className="card-title">{t('ratesInformation.education')}</h2>
            <p className={style.ratesOptionName}>
              { activeForm && t('ratesInformation.schoolYearSpan', {
                startMonthYear: format(weekIdToDate(activeForm.startWeekId), i18n.language === 'fr' ? 'dd MMMM yyyy' : 'MMMM dd, yyyy', {
                  locale: i18n.language === 'fr' ? fr : enUS,
                }),
                endMonthYear: format(weekIdToDate(activeForm.endWeekId - 1), i18n.language === 'fr' ? 'dd MMMM yyyy' : 'MMMM dd, yyyy', {
                  locale: i18n.language === 'fr' ? fr : enUS,
                }),
              }) }
            </p>
            {
              filteredProducts
              && filteredProducts.education
              && filteredProducts.education.map(product => (
                <div key={product.id} className="row">
                  <div className="col-xs-9 col-sm-10">
                    <Checkbox
                      className={style.ratesRadio}
                      id={`education-${product.id}`}
                      name={`education-${product.id}`}
                      label={(
                        `${
                          product.name[getLocaleId()]
                        }${
                          product.typeId === 2
                          && responseForm.grade
                          && ALL_GRADES[responseForm.grade]
                            ? ` - ${ALL_GRADES[responseForm.grade]}`
                            : ''
                        }`
                      )}
                      value={product.id}
                      checked={
                        values.selectedProducts
                          ? values.selectedProducts.includes(product.id)
                          : false
                      }
                      onChange={e => handleOnChecked(e, setFieldValue, values)}
                      disabled={isFormLocked}
                    />
                    <p className={style.ratesDescription}>
                      {product.description[getLocaleId()]}
                    </p>
                  </div>
                  <div className={cn(['col-xs-3 col-sm-2', style.ratesPrice])}>
                    {product.priceForForm
                      ? `${displayPrice(product.priceForForm)}\u00A0€`
                      : t('ratesInformation.free')}
                  </div>
                </div>
              ))
            }
          </Card>
          <Card className="card-margin">
            <h2 className="card-title">
              {t('ratesInformation.accommodationFull')}
            </h2>

            <div className={style.mediaLabelsRowAccommodations}>
              {filteredProducts.accommodation
                .map(product => (
                  <div key={product.id} className={isProductAvailable(product) ? '' : style.disabled}>
                    {product.imageKey ? (
                      <MediaImage
                        alignDirection="left"
                        key={product.id}
                        src={
                          product.imageKey
                            ? `${API_URL_BASE}${product.imageKey}`
                            : null
                        }
                        btnStyle="fill"
                        isActive={values.selectedAccommodation === product.id}
                        onClick={() => handleSelectAccommodation(product)}
                      >
                        <div>
                          <span>{product.name[getLocaleId()]}</span>
                          <br />
                          <span className={style.ratesPrice}>
                            { !isProductAvailable(product) ? `${t('ratesInformation.unavailable')} - ` : '' }
                            {product.priceForForm
                              ? `${
                                displayPrice(product.priceForForm)
                              }\u00A0€`
                              : t('ratesInformation.free')}
                          </span>
                        </div>
                      </MediaImage>
                    ) : (
                      <MediaLabel
                        alignDirection="left"
                        key={product.id}
                        icon="bunkBed"
                        btnStyle="fill"
                        isActive={values.selectedAccommodation === product.id}
                        onClick={() => handleSelectAccommodation(product)}
                      >
                        <div>
                          <span>{product.name[getLocaleId()]}</span>
                          <br />
                          <span className={style.ratesPrice}>
                            { !isProductAvailable(product) ? `${t('ratesInformation.unavailable')} - ` : '' }
                            {product.priceForForm
                              ? `${
                                displayPrice(product.priceForForm)
                              }\u00A0€`
                              : t('ratesInformation.free')}
                          </span>
                        </div>
                      </MediaLabel>
                    )}
                  </div>
                ))}
            </div>
          </Card>

          {!isNil(values.selectedAccommodation) ? (
            <Card className="card-margin" title={t('ratesInformation.interns')}>
              <h3 className={style.upperTitle}>
                {t('ratesInformation.mandatory')}
              </h3>
              {filteredProducts.intern1.map(product => (
                <div key={product.id} className="row">
                  <div className="col-xs-9 col-sm-10">
                    <Checkbox
                      className={style.ratesRadio}
                      id={`intern1-${product.id}`}
                      name={`intern1-${product.id}`}
                      label={product.name[getLocaleId()]}
                      value={product.id}
                      checked={
                        values.selectedProducts
                          ? values.selectedProducts.includes(product.id)
                          : false
                      }
                      onChange={e => handleOnChecked(e, setFieldValue, values)}
                      disabled={isFormLocked}
                    />
                    <p className={style.ratesDescription}>
                      {product.description[getLocaleId()]}
                    </p>
                  </div>
                  <div className={cn(['col-xs-3 col-sm-2', style.ratesPrice])}>
                    {product.priceForForm
                      ? `${displayPrice(product.priceForForm)}\u00A0€`
                      : t('ratesInformation.free')}
                  </div>
                </div>
              ))}
              <h3 className={style.upperTitle}>
                {t('ratesInformation.optional')}
              </h3>
              {filteredProducts.intern2.map(product => (
                <div key={product.id} className="row">
                  <div className="col-xs-9 col-sm-10">
                    <Checkbox
                      className={style.ratesRadio}
                      id={`intern2-${product.id}`}
                      name={`intern2-${product.id}`}
                      label={product.name[getLocaleId()]}
                      value={product.id}
                      checked={
                        values.selectedProducts
                          ? values.selectedProducts.includes(product.id)
                          : false
                      }
                      onChange={e => handleOnChecked(e, setFieldValue, values)}
                      disabled={isFormLocked}
                    />
                    <p className={style.ratesDescription}>
                      {product.description[getLocaleId()]}
                    </p>
                  </div>
                  <div className={cn(['col-xs-3 col-sm-2', style.ratesPrice])}>
                    {product.priceForForm
                      ? `${displayPrice(product.priceForForm)}\u00A0€`
                      : t('ratesInformation.free')}
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col-xs-12">
                  <p className={style.upperTitle}>
                    {t('ratesInformation.badge')}
                  </p>
                  <div
                    className={cn([
                      style.mediaLabelsRow,
                      style.mediaLabelsRowNoMargin,
                    ])}
                  >
                    <MediaLabel
                      icon="fitnessBracelet"
                      btnStyle="fill"
                      isActive={values.badge === 0}
                      onClick={() => handleBadgeClick(0)}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <span>{t('ratesInformation.watch')}</span>
                      </div>
                    </MediaLabel>
                    <MediaLabel
                      icon="idCard"
                      btnStyle="fill"
                      isActive={values.badge === 1}
                      onClick={() => handleBadgeClick(1)}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <span>{t('ratesInformation.card')}</span>
                      </div>
                    </MediaLabel>
                  </div>
                </div>
              </div>
            </Card>
          ) : null}

          <Card className="card-margin">
            <h2 className="card-title">{t('ratesInformation.options')}</h2>
            {filteredProducts.options.map(product => (
              <div key={product.id} className="row">
                <div className="col-xs-9 col-sm-10">
                  <Checkbox
                    className={style.ratesRadio}
                    id={`options-${product.id}`}
                    name={`options-${product.id}`}
                    label={product.name[getLocaleId()]}
                    value={product.id}
                    checked={
                      values.selectedProducts
                        ? values.selectedProducts.includes(product.id)
                        : false
                    }
                    onChange={e => handleOnChecked(e, setFieldValue, values)}
                    disabled={isFormLocked}
                  />
                  <p className={style.ratesDescription}>
                    {product.description[getLocaleId()]}
                  </p>
                </div>
                <div className={cn(['col-xs-3 col-sm-2', style.ratesPrice])}>
                  {product.priceForForm
                    ? `${displayPrice(product.priceForForm)}\u00A0€`
                    : t('ratesInformation.free')}
                </div>
              </div>
            ))}
          </Card>
          <Card className="card-margin">
            <h2 className="card-title">{t('ratesInformation.insurance')}</h2>
            <div className="row">
              <div className="col-xs-9 col-sm-10">
                <Checkbox
                  className={style.ratesRadio}
                  id="hasCancellationInsurance"
                  name="hasCancellationInsurance"
                  label={t('ratesInformation.insurance')}
                  checked={values.hasCancellationInsurance}
                  onChange={e => setFieldValue('hasCancellationInsurance', e.target.checked)}
                  disabled={isFormLocked}
                />
              </div>
            </div>
          </Card>
          <Card
            className="card-margin"
            color="blue"
            title={t('ratesInformation.total')}
          >
            <div>
              {values.selectedProducts
                ? values.selectedProducts.map((selectedProductId) => {
                  const selectedProduct = productsData.products.filter(
                    product => product.id === selectedProductId,
                  )[0];

                  if (
                    selectedProduct
                      && selectedProduct.name
                      && selectedProduct.priceForForm
                  ) {
                    return (
                      <div
                        key={selectedProductId}
                        className={cn(['row', style.recapLine])}
                      >
                        <div
                          className={cn([
                            'col-xs-6 col-sm-9',
                            style.recapLineLabel,
                          ])}
                        >
                          {selectedProduct.name[getLocaleId()]}
                        </div>
                        <div
                          className={cn([
                            'col-xs-6 col-sm-3',
                            style.ratesPrice,
                          ])}
                        >
                          {selectedProduct.priceForForm
                            ? `${
                              displayPrice(selectedProduct.priceForForm)
                            }\u00A0€`
                            : t('ratesInformation.free')}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
                : null}
              {find(productsData.products, {
                id: values.selectedAccommodation,
              }) ? (
                <div className={cn(['row', style.recapLine])}>
                  <div
                    className={cn(['col-xs-6 col-sm-9', style.recapLineLabel])}
                  >
                    {
                      find(productsData.products, {
                        id: values.selectedAccommodation,
                      }).name[getLocaleId()]
                    }
                  </div>
                  <div className={cn(['col-xs-6 col-sm-3', style.ratesPrice])}>
                    {`${
                      displayPrice(find(productsData.products, {
                        id: values.selectedAccommodation,
                      }).priceForForm)
                    }\u00A0€`}
                  </div>
                </div>
                ) : null}
              {activeForm.hasDiscount ? (
                <div className={cn(['row', style.recapLine])}>
                  <div
                    className={cn(['col-xs-6 col-sm-9', style.recapLineLabel])}
                  >
                    {activeForm.discountMessage}
                  </div>
                  <div className={cn(['col-xs-6 col-sm-3', style.ratesPrice])}>
                    {`- ${displayPrice(activeForm.discountAmount)}\u00A0€`}
                  </div>
                </div>
              ) : null}
              {values.hasCancellationInsurance ? (
                <div className={cn(['row', style.recapLine])}>
                  <div
                    className={cn(['col-xs-6 col-sm-9', style.recapLineLabel])}
                  >
                    {t('ratesInformation.insurance')}
                  </div>
                  <div className={cn(['col-xs-6 col-sm-3', style.ratesPrice])}>
                    {`${displayPrice(insuranceAmount || 0)}\u00A0€`}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className={cn(['col-xs-5 col-sm-6', style.total])}>
                {t('ratesInformation.total')}
              </div>
              <div
                className={cn([
                  'col-xs-7 col-sm-6',
                  style.ratesPrice,
                  style.total,
                  style.totalBlue,
                ])}
              >
                {totalPriceLoading ? (
                  <Loader />
                ) : (
                  `${displayPrice(computeTotal(values) + (insuranceAmount || 0))}\u00A0€`
                )}
              </div>
            </div>
          </Card>

          <Card className="card-margin">
            <h2 className="card-title">
              {t('ratesInformation.paymentChoice')}
            </h2>
            <div className="row">
              <div className="col-xs-9 col-sm-10">
                <h4 className={style.ratesOptionName}>
                  {t('ratesInformation.paymentMethod')}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-10">
                <FormBlock>
                  <Radio
                    className={style.ratesRadio}
                    onChange={() => {}}
                    id="paymentMethod1"
                    name="paymentMethod"
                    label={(
                      <div>
                        <h4
                          className={cn([
                            style.ratesRadio,
                            style.paymentMeanOptionHeader,
                          ])}
                        >
                          {t('ratesInformation.paymentOption1')}
                        </h4>
                        <div className={style.paymentMethodOptionText}>
                          {t('ratesInformation.paymentOption1Desc1')}
                        </div>
                        <div className={style.paymentMethodOptionText}>
                          {t('ratesInformation.paymentOption1Desc2', {
                            currentYear: getYear(new Date()),
                          })}
                        </div>
                      </div>
)}
                    onClick={() => setFieldValue('paymentMethod', 1)}
                    checked={values.paymentMethod === 1}
                    disabled={isFormLocked}
                  />
                </FormBlock>
                <FormBlock>
                  <Radio
                    className={style.ratesRadio}
                    onChange={() => {}}
                    id="paymentMethod2"
                    name="paymentMethod"
                    label={(
                      <div>
                        <h4
                          className={cn([
                            style.ratesRadio,
                            style.paymentMeanOptionHeader,
                          ])}
                        >
                          {t('ratesInformation.paymentOption2')}
                        </h4>
                        <div className={style.paymentMethodOptionText}>
                          {t('ratesInformation.paymentOption2Desc1')}
                        </div>
                        <div className={style.paymentMethodOptionText}>
                          {t('ratesInformation.paymentOption2Desc2', {
                            currentYear: getYear(new Date()),
                            nextYear: getYear(addYears(new Date(), 1)),
                          })}
                        </div>
                        <div className={style.paymentMethodOptionText}>
                          {t('ratesInformation.paymentOption2Desc3', {
                            currentYear: getYear(new Date()),
                            nextYear: getYear(addYears(new Date(), 1)),
                          })}
                        </div>
                      </div>
)}
                    onClick={() => setFieldValue('paymentMethod', 2)}
                    checked={values.paymentMethod === 2}
                    disabled={isFormLocked}
                  />
                </FormBlock>
              </div>
            </div>
            <div className={cn(['row', style.paymentMeanTitle])}>
              <div className="col-xs-9 col-sm-10">
                <h4 className={style.ratesOptionName}>
                  {t('ratesInformation.paymentMean')}
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-10">
                <FormBlock>
                  <Radio
                    className={style.ratesRadio}
                    onChange={() => {}}
                    id="paymentMean1"
                    name="paymentMean"
                    label={t('ratesInformation.paymentMean1')}
                    onClick={() => setFieldValue('paymentMean', 1)}
                    checked={values.paymentMean === 1}
                    disabled={isFormLocked}
                  />
                </FormBlock>
                <FormBlock>
                  <Radio
                    className={style.ratesRadio}
                    onChange={() => {}}
                    id="paymentMean2"
                    name="paymentMean"
                    label={t('ratesInformation.paymentMean2')}
                    onClick={() => setFieldValue('paymentMean', 2)}
                    checked={values.paymentMean === 2}
                    disabled={isFormLocked}
                  />
                </FormBlock>
                <FormBlock>
                  <Radio
                    className={style.ratesRadio}
                    onChange={() => {}}
                    id="paymentMean3"
                    name="paymentMean"
                    label={t('ratesInformation.paymentMean3')}
                    onClick={() => setFieldValue('paymentMean', 3)}
                    checked={values.paymentMean === 3}
                    disabled={isFormLocked}
                  />
                </FormBlock>
              </div>
            </div>
          </Card>

          <div className={style.submitBlock}>
            {formError || isFormLocked ? (
              <div>{formError || isFormLocked}</div>
            ) : (
              <AutoSave
                formId={match.params.id}
                values={values}
                isSaving={submitLoading}
                onSave={updateStudentForm}
                error={submitError}
              />
            )}
            <Button
              className={style.submitForm}
              type="button"
              color="green"
              label={
                submitted || isFormLocked
                  ? t('ratesInformation.submitApplicationSuccess')
                  : t('ratesInformation.submitApplication')
              }
              onClick={submitForm}
              disabled={!allowToSubmit(progress) || submitted || isFormLocked}
            />
            <Button
              className={style.submit}
              type="button"
              label={t('generalInformation.next')}
              onClick={() => saveForm(values)}
              disabled={submitLoading}
            />
          </div>
        </form>
      )}
    </WithSide>
  );
};

RatesInformation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape().isRequired,
};

export default RatesInformation;
