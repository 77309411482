import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Components
import Routes from './Routes.jsx';

// Context
import { AppProvider } from './context/AppContext';
import { KeycloakAuthProvider } from './components/templates/KeycloakAuthProvider';

// Style
import './constants/helpers.css';

function App() {
  return (
    <KeycloakAuthProvider>
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>
    </KeycloakAuthProvider>
  );
}

export default App;
