import React from 'react';
import PropTypes from 'prop-types';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './Card.module.css';

export const Card = ({
  children,
  title,
  isActive,
  color,
  image,
  rounded,
  className,
  noPadding,
  ...propsLeft
}) => (
  <div
      className={cn([
        style.card,
        className,
        isActive && style.active,
        rounded && style.rounded,
        style[color],
        noPadding && style.noPadding,
      ])}
      {...propsLeft}
    >
      {title ? <h2 className={style.title}>{title}</h2> : null}
      {image
        ? (
          <div className={style.withImage}>
            <div className={style.image} style={{ backgroundImage: `url(${image})` }} />
            <div className={style.content}>
              {children}
            </div>
          </div>
        )
        : (
          <div className={style.content}>
            {children}
          </div>
        )
      }
    </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'white']),
  image: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  noPadding: PropTypes.bool,
};

Card.defaultProps = {
  title: null,
  isActive: false,
  color: 'white',
  image: null,
  className: null,
  rounded: false,
  noPadding: false,
};

export default Card;
