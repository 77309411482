import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../../assets/icons/minus.svg';

// Style
import style from './Counter.module.css';

export const Counter = ({
  onPlusClick, onMinusClick, defaultNumber, min, max, step, disabled,
}) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (defaultNumber) setNumber(defaultNumber);
  }, []);

  const handlePlusClick = () => {
    const newCounter = (Number(number) + step).toFixed(Number.isInteger(step) ? null : 1);
    if (newCounter <= max) {
      setNumber(newCounter);
      if (onPlusClick) onPlusClick(newCounter);
    }
  };

  const handleMinusClick = () => {
    const newCounter = (Number(number) - step).toFixed(Number.isInteger(step) ? null : 1);
    if (newCounter >= min) {
      setNumber(newCounter);
      if (onPlusClick) onMinusClick(newCounter);
    }
  };

  return (
    <div className={style.wrapper}>
      <button disabled={disabled} className={style.btn} type="button" onClick={handleMinusClick}><Minus /></button>
      <span className={style.number}>{number}</span>
      <button disabled={disabled} className={style.btn} type="button" onClick={handlePlusClick}><Plus /></button>
    </div>
  );
};

Counter.propTypes = {
  onPlusClick: PropTypes.func,
  onMinusClick: PropTypes.func,
  defaultNumber: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
};

Counter.defaultProps = {
  onPlusClick: undefined,
  onMinusClick: undefined,
  defaultNumber: 0,
  min: 0,
  max: 99,
  step: 1,
  disabled: false,
};

export default Counter;
