import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as LogoShort } from '../../../assets/logos/logo-short.svg';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as EyeSlash } from '../../../assets/icons/eye-slash.svg';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';
import { ReactComponent as Minus } from '../../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Cross } from '../../../assets/icons/cross.svg';
import { ReactComponent as ChevronUp } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as UserCircle } from '../../../assets/icons/user-circle.svg';
import { ReactComponent as Pin } from '../../../assets/icons/pin.svg';
import { ReactComponent as Mail } from '../../../assets/icons/mail.svg';
import { ReactComponent as Phone } from '../../../assets/icons/phone.svg';
import { ReactComponent as Bed } from '../../../assets/icons/bed.svg';
import { ReactComponent as Bill } from '../../../assets/icons/bill.svg';
import { ReactComponent as BunkBed } from '../../../assets/icons/bunk-bed.svg';
import { ReactComponent as CottonPoloShirt } from '../../../assets/icons/cotton-polo-shirt.svg';
import { ReactComponent as CreditCard } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as File } from '../../../assets/icons/file.svg';
import { ReactComponent as FitnessBracelet } from '../../../assets/icons/fitness-bracelet.svg';
import { ReactComponent as Hide } from '../../../assets/icons/hide.svg';
import { ReactComponent as IdCard } from '../../../assets/icons/id-card.svg';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as Mortarboard } from '../../../assets/icons/mortarboard.svg';
import { ReactComponent as PaperCheck } from '../../../assets/icons/paper-check.svg';
import { ReactComponent as SquareTargeting } from '../../../assets/icons/square-targeting.svg';
import { ReactComponent as Stethoscope } from '../../../assets/icons/stethoscope.svg';
import { ReactComponent as TennisPlayer } from '../../../assets/icons/tennis-player.svg';
import { ReactComponent as Transfer } from '../../../assets/icons/transfer.svg';
import { ReactComponent as UploadToTheCloud } from '../../../assets/icons/upload-to-the-cloud.svg';
import { ReactComponent as Pen } from '../../../assets/icons/pen.svg';
import { ReactComponent as CheckFile } from '../../../assets/icons/checkFile.svg';

export const availablePictos = {
  logoShort: <LogoShort />,
  check: <Check />,
  eyeSlash: <EyeSlash />,
  eye: <Eye />,
  minus: <Minus />,
  plus: <Plus />,
  cross: <Cross />,
  chevronUp: <ChevronUp />,
  chevronDown: <ChevronDown />,
  userCircle: <UserCircle />,
  pin: <Pin />,
  mail: <Mail />,
  phone: <Phone />,
  bed: <Bed />,
  bill: <Bill />,
  bunkBed: <BunkBed />,
  cottonPoloShirt: <CottonPoloShirt />,
  creditCard: <CreditCard />,
  file: <File />,
  fitnessBracelet: <FitnessBracelet />,
  hide: <Hide />,
  idCard: <IdCard />,
  info: <Info />,
  mortarboard: <Mortarboard />,
  paperCheck: <PaperCheck />,
  squareTargeting: <SquareTargeting />,
  stethoscope: <Stethoscope />,
  tennisPlayer: <TennisPlayer />,
  transfer: <Transfer />,
  uploadToTheCloud: <UploadToTheCloud />,
  pen: <Pen />,
  checkFile: <CheckFile />,
};

export const Picto = ({ icon, ...props }) => (
  React.cloneElement(
    availablePictos[icon],
    props,
  )
);

Picto.propTypes = {
  icon: PropTypes.oneOf(Object.keys(availablePictos)).isRequired,
};

export default Picto;
