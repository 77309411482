import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

// Components
import Picto from '../Picto';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './TextField.module.css';

export const TextField = ({
  label, type, id, error, onBlur, className, value, onChange, ...propsLeft
}) => {
  const [inputHasContent, setInputHasContent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const { defaultValue } = propsLeft;
    if (value || defaultValue) setInputHasContent(true);
  }, [value]);

  const handleBlur = (e) => {
    if (e.target.value) setInputHasContent(true);
    else setInputHasContent(false);
    if (onBlur) onBlur(e);
  };

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleShowPasswordKeyPress = e => e.key === 'Enter' && handleShowPassword();

  return (
    <label
      htmlFor={id}
      className={cn([style.group, className, inputHasContent ? style.active : '', error ? style.hasError : ''])}
    >
      <input className={style.input} id={id} type={showPassword ? 'text' : type} onBlur={handleBlur} value={value || ''} onChange={onChange} {...propsLeft} />
      <span className={style.label}>
        {label}
        {error ? ` - ${error}` : null}
      </span>
      <span className={style.bar} />
      {type === 'password'
        ? (
          <span
            role="button"
            tabIndex="0"
            className={style.picto}
            onClick={handleShowPassword}
            onKeyDown={handleShowPasswordKeyPress}
          >
            {showPassword ? <Picto icon="eyeSlash" /> : <Picto icon="eye" />}
          </span>
        )
        : null
      }
    </label>
  );
};
TextField.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'password', 'search', 'tel', 'url', 'number', 'date']).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  error: null,
  onBlur: null,
  className: null,
  value: '',
  onChange: noop,
};

export default TextField;
