import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Components
import TextField from '../../atoms/TextField';
import Button from '../../atoms/Button';


const initialFormValues = {
  medicineName: '',
  medicineDosage: '',
  medicineHour: '',
  medicineDuration: '',
  medicineConservationMode: '',
};

const AddMedicineForm = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();
  const {
    values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, handleSubmit,
  } = useFormik({
    initialValues: initialValues || initialFormValues,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            label={t('addMedicineForm.medicineName')}
            type="text"
            name="medicineName"
            id="medicineName"
            value={values.medicineName}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            label={t('addMedicineForm.dosage')}
            type="text"
            name="medicineDosage"
            id="medicineDosage"
            value={values.medicineDosage}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            label={t('addMedicineForm.hour')}
            type="text"
            name="medicineHour"
            id="medicineHour"
            value={values.medicineHour}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            label={t('addMedicineForm.duration')}
            type="text"
            name="medicineDuration"
            id="medicineDuration"
            value={values.medicineDuration}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            label={t('addMedicineForm.conservationMode')}
            type="text"
            name="medicineConservationMode"
            id="medicineConservationMode"
            value={values.medicineConservationMode}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12" style={{ textAlign: 'center' }}>
          <Button label={t('addMedicineForm.add')} type="submit" style={{ marginTop: '2rem' }} />
        </div>
      </div>
    </form>
  );
};

AddMedicineForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(PropTypes.object),
};

AddMedicineForm.defaultProps = {
  initialValues: initialFormValues,
};

export default AddMedicineForm;
