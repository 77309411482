import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import TextField from '../TextField';

export const MapsAutocomplete = ({ id, onSelect, ...propsLeft }) => {
  const buildAdressData = (address) => {
    const addressDetails = address.reduce((acc, item) => (
      { ...acc, [item.types[0]]: item.long_name }
    ), {});

    return addressDetails;
  };

  useEffect(() => {
    // init Google autocomplete Input
    const options = {};
    // eslint-disable-next-line no-undef
    const googlePlacesAutocomplete = new google.maps.places.Autocomplete(
      document.getElementById(id),
      options,
    );
    googlePlacesAutocomplete.addListener('place_changed', () => {
      const place = googlePlacesAutocomplete.getPlace();
      onSelect(place.formatted_address, buildAdressData(place.address_components));
    });
    return () => {
      [...document.getElementsByClassName('pac-container')].forEach((element) => {
        element.remove();
      });
    };
  }, []);
  return <TextField id={id} type="text" placeholder="" {...propsLeft} />;
};

MapsAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default MapsAutocomplete;
