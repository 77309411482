/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format, parse, isValid } from 'date-fns';
import Tooltip from 'react-tooltip-lite';
import { saveAs } from 'file-saver';
import i18nCountries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesFR from 'i18n-iso-countries/langs/fr.json';
import { useFormik } from 'formik';
import {
  lowerCase, isEmpty, upperCase, isEqual,
} from 'lodash';
import {
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

// Components
import WithSide from '../components/templates/WithSide';
import Card from '../components/atoms/Card';
import TextField from '../components/atoms/TextField';
import PhoneField from '../components/atoms/PhoneField';
import Radio from '../components/atoms/Radio';
import Checkbox from '../components/atoms/Checkbox';
import Select from '../components/atoms/Select';
import FormBlock from '../components/atoms/FormBlock';
import Button from '../components/atoms/Button';
import AutoSave from '../components/atoms/AutoSave';
import TabSelector from '../components/organisms/TabSelector';
import Accordion from '../components/organisms/Accordion';
import DropZone from '../components/molecules/DropZone';
import AddressField from '../components/molecules/AddressField';
import Picto from '../components/atoms/Picto';


// Utils & Misc
import cn from '../utils/cn';
import { generalValidate } from '../utils/validators';
import shouldDisable from '../utils/shouldDisable';
import allowToSubmit from '../utils/allowToSubmit';
import filterCountryOptions from '../utils/filterCountryOptions';
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import {
  API_URL,
  ID_CARD_FRONT_ID,
  ID_CARD_BACK_ID,
  STUDENT_ID_CARD_FRONT,
  STUDENT_ID_CARD_BACK,
  FR_SHIRT_SIZES,
  COUNTRY_CODES,
  LANGUAGES_CODE,
  LEGAL_GUARDIANS,
  FORM_INITIAL_VALUES as FIV,
  PHONE_INITIAL,
} from '../constants/constants';
import i18n from '../i18n';
import computeProgression from '../utils/computeProgression';

// Style
import style from './formApp.module.css';
import flagsStyle from '../constants/flags.module.css';

const formatPhoneNumber = (number, code) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(`${code}${number}`);
  const e164 = parsedPhoneNumber ? parsedPhoneNumber.number : '';
  return { number, e164 };
};

const GeneralInformation = ({ match, history }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  i18nCountries.registerLocale(i18n.language === 'en' ? countriesEN : countriesFR);
  const {
    context: {
      activeForm, activeStudent, auth, progress,
    }, computeProgress,
  } = useAppContext();
  const {
    loading, responseData: formData, responseError: formError, getStudentForm,
  } = useApi();
  const {
    loading: submitLoading, responseError: submitError, updateStudentForm,
  } = useApi();
  const {
    postFormEvent,
  } = useApi();
  const {
    responseData: responseForm,
    getFormInfo,
  } = useApi();
  const { updateFormInfo } = useApi();
  const { getDocument } = useApi();
  const { loading: loadDocs, uploadDocument } = useApi();
  const [documents, setDocuments] = useState({});
  const {
    values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched,
  } = useFormik({
    initialValues: {
      ...FIV.generalInfo,
      ...FIV.scholarshipInfo,
      ...FIV.sportInfo,
      ...FIV.medicalInfo,
      ...FIV.administrativeInfo,
      ...FIV.ratesInfo,
      ...formData,
    },
    enableReinitialize: true,
    validate: generalValidate,
  });

  const isFormLocked = useMemo(() => (
    activeForm ? shouldDisable(activeForm.stateId, auth.role) : true
  ), [activeForm]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
  };

  const submitForm = () => {
    postFormEvent(match.params.id, 'submit').then(() => {
      setSubmitted(true);
      history.push('/confirmation', { studentId: match.params.id });
    });
    // if (allowToSubmit(progress)) postFormEvent(match.params.id, 'submit');
  };

  useEffect(() => {
    getStudentForm(match.params.id);
    getFormInfo(match.params.id);
  }, []);

  useEffect(() => {
    if (formData && activeForm) computeProgress(formData, activeForm);
  }, [formData, activeForm]);

  const saveForm = () => {
    updateStudentForm(match.params.id, values);
    if (values && activeForm) computeProgress(values, activeForm);

    // TODO Refacto WIP
    computeProgression(values);
  };

  // Pristine student form autocomplete
  useEffect(() => {
    if (activeForm) {
      setTimeout(() => {
        if (values && values.parent1Phone && !values.emergencyPhone1) {
          setFieldValue('emergencyPhone1', values.parent1Phone);
        }
        if (values && values.parent2Phone && !values.emergencyPhone2) {
          setFieldValue('emergencyPhone2', values.parent2Phone);
        }
        if (values && values.parent1Phone && isEqual(values.parent1PhoneDetails, PHONE_INITIAL)) {
          const { country, nationalNumber, countryCallingCode } = parsePhoneNumberFromString(
            typeof values.parent1Phone !== 'string' ? '' : values.parent1Phone,
          );
          const callingCode = `+${countryCallingCode}`;
          const { e164 } = formatPhoneNumber(nationalNumber, callingCode);
          setFieldValue('parent1PhoneDetails', {
            fullNumber: `${callingCode}${nationalNumber}`,
            e164,
            code: callingCode,
            iso: country,
          });
        }
        if (values && values.parent2Phone && isEqual(values.parent2PhoneDetails, PHONE_INITIAL)) {
          const { country, nationalNumber, countryCallingCode } = parsePhoneNumberFromString(
            typeof values.parent2Phone !== 'string' ? '' : values.parent2Phone,
          );
          const callingCode = `+${countryCallingCode}`;
          const { e164 } = formatPhoneNumber(nationalNumber, callingCode);
          setFieldValue('parent2PhoneDetails', {
            fullNumber: `${callingCode}${nationalNumber}`,
            e164,
            code: callingCode,
            iso: country,
          });
        }
      }, 100);
    }
  }, [values, activeForm]);

  useEffect(() => {
    if (responseForm) {
      const { documents: documentsData } = responseForm;
      if (!isEmpty(documentsData)) setDocuments({ ...documents, ...documentsData });
    }
  }, [responseForm]);

  const postDocument = (file, typeId) => {
    uploadDocument(file, activeStudent.id).then((response) => {
      const documentsUpdate = {
        ...documents,
        [typeId]: {
          name: file.name,
          id: response.id,
          typeId,
        },
      };
      updateFormInfo(match.params.id, { documents: documentsUpdate }).then(() => {
        if (typeId === ID_CARD_FRONT_ID) {
          // setFieldValue('idCardFrontUploaded', true);
        }
        if (typeId === ID_CARD_BACK_ID) {
          // setFieldValue('idCardBackUploaded', true);
        }
        setDocuments(documentsUpdate);
      });
    });
  };

  const handleDocumentClear = (typeId) => {
    const documentsUpdate = { ...documents };
    delete documentsUpdate[typeId];
    // UPDATE FORM AS WELL
    setDocuments(documentsUpdate);
    updateFormInfo(match.params.id, { documents: documentsUpdate }).then(() => {
      if (typeId === ID_CARD_FRONT_ID) {
        setFieldValue('idCardFrontUploaded', false);
      }
      if (typeId === ID_CARD_BACK_ID) {
        setFieldValue('idCardBackUploaded', false);
      }
    }).catch(() => {
      setDocuments(documents);
    });
  };

  const handleFileNameClick = (file) => {
    getDocument(file.id, match.params.id).then((response) => {
      if (response) saveAs(response.data, file.name);
    });
  };

  const getCountriesOptions = COUNTRY_CODES.map(({ code }) => (
    {
      label: (
        <span>
          <i className={cn([flagsStyle['flag-icon'], flagsStyle[`flag-icon-${lowerCase(code)}`]])} />
          &nbsp;
          {i18nCountries.getName(code, i18n.language)}
        </span>
      ),
      value: code,
    }
  ));

  const getLanguagesOptions = Object.keys(LANGUAGES_CODE[i18n.language]).map(code => (
    {
      label: <span>{LANGUAGES_CODE[i18n.language][code]}</span>,
      value: code,
    }
  ));

  const getGuardiansOptions = LEGAL_GUARDIANS.map((option) => {
    const opt = option === 'other' ? t('generalInformation.other') : option;
    return ({
      label: <span>{opt}</span>,
      value: option,
    });
  });

  const handleSeparatedParentChange = (e, formAction) => {
    if (e.target.value === 'false') {
      formAction('parentsSeparated', 'false');
      formAction('sendMailParent1', true);
      formAction('sendFinancialMailParent1', true);
    } else formAction('parentsSeparated', 'true');
  };

  let dateParsed = parse(values.birthdate, 'yyyy-MM-dd', new Date());
  if (values.birthdate) {
    if (!isValid(dateParsed)) {
      dateParsed = parse(values.birthdate, 'dd/MM/yyyy', new Date());
      setFieldValue('birthdate', format(new Date(dateParsed), 'yyyy-MM-dd'));
    }
  } else dateParsed = new Date();

  return (
    <WithSide title={t('generalInformation.viewTitle')}>
      {loading
        ? <div />
        : (
          <form>
            <Card className="card-margin">
              <h2 className="card-title">{t('generalInformation.personnalInfo')}</h2>
              <div className="row">
                <div className="col-xs-12 col-lg-10 md-10">
                  <TextField
                    label={t('generalInformation.lastname')}
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={values.lastname}
                    error={errors.lastname && touched.lastname
                      ? t(`form.errors.${errors.lastname}`) : null
                      }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />
                  <TextField
                    label={t('generalInformation.firstname')}
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={values.firstname}
                    error={errors.firstname && touched.firstname
                      ? t(`form.errors.${errors.firstname}`) : null
                      }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />

                  <TextField
                    label={t('generalInformation.birthdate')}
                    type="date"
                    name="birthdate"
                    id="birthdate"
                    value={dateParsed ? format(dateParsed, 'yyyy-MM-dd') : ''}
                    error={errors.birthdate && touched.birthdate
                      ? t(`form.errors.${errors.birthdate}`) : null
                      }
                    onChange={e => setFieldValue('birthdate', format(new Date(e.target.value), 'yyyy-MM-dd'))}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />

                  <Select
                    id="birthCountry"
                    name="birthCountry"
                    label={t('generalInformation.birthCountry')}
                    placeholder={t('scholarshipInformation.chooseCountry')}
                    value={values.birthCountry}
                    options={getCountriesOptions}
                    onChange={handleSelectChange}
                    menuPortalTarget={document.body}
                    isDisabled={isFormLocked}
                    filterOption={filterCountryOptions}
                  />

                  <TextField
                    label={t('generalInformation.birthplace')}
                    type="text"
                    name="birthplace"
                    id="birthplace"
                    value={values.birthplace}
                    error={errors.birthplace && touched.birthplace
                      ? t(`form.errors.${errors.birthplace}`) : null
                      }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />

                  <TextField
                    label={t('generalInformation.birthRegion')}
                    type="text"
                    name="birthRegion"
                    id="birthRegion"
                    value={values.birthRegion}
                    error={errors.birthRegion && touched.birthRegion
                      ? t(`form.errors.${errors.birthRegion}`) : null
                      }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />

                  <FormBlock label={t('generalInformation.gender')}>
                    <Radio
                      id="gender-male"
                      name="sex"
                      value="male"
                      checked={values.sex === 'male'}
                      label={t('generalInformation.male')}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Radio
                      id="gender-female"
                      name="sex"
                      value="female"
                      checked={values.sex === 'female'}
                      label={t('generalInformation.female')}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                  </FormBlock>

                  <FormBlock label={t('sportsInformation.shirtSize')}>
                    <div className="row">
                      <div className="col-xs-12">
                        {FR_SHIRT_SIZES.map((size, index) => (
                          <label
                            className={cn([
                              style.sizeInput,
                              values.shirtSize === size && style.sizeInputActive,
                            ])}
                            key={size}
                            htmlFor={`${size}-shirtSize`}
                          >
                            {`${upperCase(size)} ${index < 6 ? t('global.y-o') : ''}`}
                            <input
                              id={`${size}-shirtSize`}
                              name="shirtSize"
                              value={size}
                              type="radio"
                              checked={values.shirtSize === size}
                              onChange={handleChange}
                              disabled={isFormLocked}
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                  </FormBlock>
                </div>
              </div>
            </Card>
            <Card className="card-margin">
              <h2 className="card-title">{t('generalInformation.profil')}</h2>
              <div className="row">
                <div className="col-xs-12 col-lg-10 md-10">
                  <Select
                    label={t('generalInformation.nativeLanguage')}
                    id="nativeLanguage"
                    name="nativeLanguage"
                    options={getLanguagesOptions}
                    value={values.nativeLanguage}
                    error={errors.nativeLanguage && touched.nativeLanguage
                      ? t(`form.errors.${errors.nativeLanguage}`) : null
                      }
                    onBlur={handleBlur}
                    onChange={handleSelectChange}
                    required
                    isDisabled={isFormLocked}
                    filterOption={filterCountryOptions}
                    menuPortalTarget={document.body}
                  />
                  <Accordion isToggled={
                    values.nativeLanguage === 'other'
                  }
                  >
                    <TextField
                      label={t('generalInformation.specify')}
                      type="text"
                      name="nativeLanguageOther"
                      id="nativeLanguageOther"
                      value={values.nativeLanguageOther}
                      error={errors.nativeLanguageOther && touched.nativeLanguageOther
                        ? t(`form.errors.${errors.nativeLanguageOther}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </Accordion>
                  <Select
                    label={t('generalInformation.spokenLanguage')}
                    id="spokenLanguage"
                    name="spokenLanguage"
                    options={getLanguagesOptions}
                    value={values.spokenLanguage}
                    error={errors.spokenLanguage && touched.spokenLanguage
                      ? t(`form.errors.${errors.spokenLanguage}`) : null
                      }
                    onBlur={handleBlur}
                    onChange={handleSelectChange}
                    required
                    isDisabled={isFormLocked}
                    filterOption={filterCountryOptions}
                    menuPortalTarget={document.body}
                  />
                  <Accordion isToggled={
                    values.spokenLanguage === 'other'
                  }
                  >
                    <TextField
                      label={t('generalInformation.specify')}
                      type="text"
                      name="spokenLanguageOther"
                      id="spokenLanguageOther"
                      value={values.spokenLanguageOther}
                      error={errors.spokenLanguageOther && touched.spokenLanguageOther
                        ? t(`form.errors.${errors.spokenLanguageOther}`) : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </Accordion>
                  <Select
                    label={t('generalInformation.nationality')}
                    id="nationality"
                    name="nationality"
                    options={getCountriesOptions}
                    value={values.nationality}
                    error={errors.nationality && touched.nationality
                      ? t(`form.errors.${errors.nationality}`) : null
                      }
                    onBlur={handleBlur}
                    onChange={handleSelectChange}
                    required
                    isDisabled={isFormLocked}
                    filterOption={filterCountryOptions}
                    menuPortalTarget={document.body}
                  />
                  <FormBlock label={t('generalInformation.needVisa')}>
                    <Radio
                      id="needVisa-yes"
                      name="needVisa"
                      value="true"
                      checked={values.needVisa === 'true'}
                      label={t('generalInformation.yes')}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Radio
                      id="needVisa-no"
                      name="needVisa"
                      value="false"
                      checked={values.needVisa === 'false'}
                      label={t('generalInformation.no')}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                  <Accordion
                    isToggled={values.needVisa === 'true'}
                  >
                    <Select
                      label={t('generalInformation.deliveryCountry')}
                      id="deliveryCountry"
                      name="deliveryCountry"
                      options={getCountriesOptions}
                      value={values.deliveryCountry}
                      error={errors.deliveryCountry && touched.deliveryCountry
                        ? t(`form.errors.${errors.deliveryCountry}`) : null
                        }
                      onBlur={handleBlur}
                      onChange={handleSelectChange}
                      required
                      isDisabled={isFormLocked}
                      filterOption={filterCountryOptions}
                      menuPortalTarget={document.body}
                    />
                    <TextField
                      label={t('generalInformation.passportNumber')}
                      type="text"
                      name="passportNumber"
                      id="passportNumber"
                      value={values.passportNumber}
                      error={errors.passportNumber && touched.passportNumber
                        ? t(`form.errors.${errors.passportNumber}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.passportExpiryDate')}
                      type="text"
                      name="passportExpiryDate"
                      id="passportExpiryDate"
                      value={values.passportExpiryDate}
                      error={errors.passportExpiryDate && touched.passportExpiryDate
                        ? t(`form.errors.${errors.passportExpiryDate}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </Accordion>
                  <p>{t('generalInformation.needVisaTip')}</p>
                </div>
              </div>
            </Card>
            <Card className="card-margin">
              <h2 className={cn([style.tooltip, 'card-title'])}>
                {t('generalInformation.coordinates')}
                <Tooltip
                  direction="up"
                  className={style.tooltipWrapper}
                  content={(<div className={style.tooltipContent}>{t('generalInformation.emailTooltip')}</div>)}
                  arrow={false}
                  useDefaultStyles
                >
                  <Picto className={style.tooltipIcon} icon="info" />
                </Tooltip>
              </h2>
              <div className="row">
                <div className="col-xs-12 col-lg-10 md-10">
                  <PhoneField
                    id="phone"
                    name="phone"
                    label={t('generalInformation.phone')}
                    selectPlaceholder={t('generalInformation.country')}
                    countriesList={COUNTRY_CODES.map(({ code }) => code)}
                    initialValue={values.phone}
                    onPhoneChange={({
                      fullNumber, e164, code, iso,
                    }) => {
                      setFieldTouched('phone');
                      setFieldValue('phoneDetails', { e164, code, iso });
                      setFieldValue('phone', fullNumber);
                    }}
                    error={errors.phone && touched.phone
                      ? t(`form.errors.${errors.phone}`) : null
                      }
                    required
                    disabled={isFormLocked}
                  />
                  <TextField
                    label={t('generalInformation.email')}
                    type="email"
                    name="email"
                    id="email"
                    value={values.email}
                    error={errors.email && touched.email
                      ? t(`form.errors.${errors.email}`) : null
                      }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />
                  <AddressField
                    fieldName="address"
                    onAddressChange={(address) => {
                      setFieldValue('address', address);
                      if (values.parent1AddressRadio === 'true' || values.parent1AddressRadio === true) { setFieldValue('parent1Address', address); }
                      if (values.parent2SameAddress === 'true' || values.parent2SameAddress === true) { setFieldValue('parent2Address', address); }
                    }}
                    onAddressDetailsChange={(addressDetails) => {
                      setFieldValue('addressDetails', addressDetails);
                      if (values.parent1AddressRadio === 'true' || values.parent1AddressRadio === true) { setFieldValue('parent1AddressDetails', addressDetails); }
                      if (values.parent2SameAddress === 'true' || values.parent2SameAddress === true) { setFieldValue('parent2Address', addressDetails); }
                    }}
                    initialAddress={values.address}
                    initialAddressDetails={values.addressDetails}
                    isFormLocked={isFormLocked}
                  />
                </div>
              </div>
            </Card>

            {/* STUDENT ID CARD */}
            <Card className="card-margin">
              <h2 className="card-title">{t('generalInformation.idCard')}</h2>
              <div className="row">
                {/* CARTE IDENTITE - RECTO */}
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], STUDENT_ID_CARD_FRONT)}
                    onClearFile={() => handleDocumentClear(STUDENT_ID_CARD_FRONT)}
                    label={t('middleOffice.identityCardFront')}
                    completed={documents[STUDENT_ID_CARD_FRONT]}
                    hasFile={!isEmpty(documents[STUDENT_ID_CARD_FRONT])}
                    fileName={
                      documents[STUDENT_ID_CARD_FRONT]
                      && documents[STUDENT_ID_CARD_FRONT].name
                    }
                    file={documents[STUDENT_ID_CARD_FRONT]}
                    fileLink={documents[STUDENT_ID_CARD_FRONT] ? `${API_URL}/documents/${documents[STUDENT_ID_CARD_FRONT].id}` : null}
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>

                {/* CARTE IDENTITE - VERSO */}
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], STUDENT_ID_CARD_BACK)}
                    onClearFile={() => handleDocumentClear(STUDENT_ID_CARD_BACK)}
                    label={t('middleOffice.identityCardBack')}
                    completed={documents[STUDENT_ID_CARD_BACK]}
                    hasFile={!isEmpty(documents[STUDENT_ID_CARD_BACK])}
                    file={documents[STUDENT_ID_CARD_BACK]}
                    fileName={
                      documents[STUDENT_ID_CARD_BACK]
                      && documents[STUDENT_ID_CARD_BACK].name
                    }
                    fileLink={documents[STUDENT_ID_CARD_BACK] ? `${API_URL}/documents/${documents[STUDENT_ID_CARD_BACK].id}` : null}
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>
              </div>
            </Card>

            {/* LEGAL GUARDIAN 1 ID CARD */}
            <Card className="card-margin">
              <h2 className="card-title">{t('generalInformation.legalGuardianIdCard')}</h2>
              <div className="row">
                {/* CARTE IDENTITE - RECTO */}
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], ID_CARD_FRONT_ID)}
                    onClearFile={() => handleDocumentClear(ID_CARD_FRONT_ID)}
                    label={t('middleOffice.identityCardFront')}
                    completed={documents[ID_CARD_FRONT_ID]}
                    hasFile={!isEmpty(documents[ID_CARD_FRONT_ID])}
                    fileName={documents[ID_CARD_FRONT_ID] && documents[ID_CARD_FRONT_ID].name}
                    file={documents[ID_CARD_FRONT_ID]}
                    fileLink={documents[ID_CARD_FRONT_ID] ? `${API_URL}/documents/${documents[ID_CARD_FRONT_ID].id}` : null}
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>

                {/* CARTE IDENTITE - VERSO */}
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], ID_CARD_BACK_ID)}
                    onClearFile={() => handleDocumentClear(ID_CARD_BACK_ID)}
                    label={t('middleOffice.identityCardBack')}
                    completed={documents[ID_CARD_BACK_ID]}
                    hasFile={!isEmpty(documents[ID_CARD_BACK_ID])}
                    file={documents[ID_CARD_BACK_ID]}
                    fileName={documents[ID_CARD_BACK_ID] && documents[ID_CARD_BACK_ID].name}
                    fileLink={documents[ID_CARD_BACK_ID] ? `${API_URL}/documents/${documents[ID_CARD_BACK_ID].id}` : null}
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>
              </div>
            </Card>

            <Card noPadding>
              <TabSelector
                tabs={[
                  {
                    label: `${t('generalInformation.parent')} 1 / ${t('generalInformation.legalGuardian')} 1`,
                    child: (
                      <div key="generalInformationTab1" className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <FormBlock>
                            <Radio
                              id="parent1Gender-mister"
                              name="parent1Gender"
                              value="mister"
                              checked={values.parent1Gender === 'mister'}
                              label={t('generalInformation.mister')}
                              onChange={handleChange}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="parent1Gender-miss"
                              name="parent1Gender"
                              value="miss"
                              checked={values.parent1Gender === 'miss'}
                              label={t('generalInformation.miss')}
                              onChange={handleChange}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <TextField
                            label={t('generalInformation.lastname')}
                            type="text"
                            name="parent1Lastname"
                            id="parent1Lastname"
                            value={values.parent1Lastname}
                            error={errors.parent1Lastname && touched.parent1Lastname
                              ? t(`form.errors.${errors.parent1Lastname}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <TextField
                            label={t('generalInformation.firstname')}
                            type="text"
                            name="parent1Firstname"
                            id="parent1Firstname"
                            value={values.parent1Firstname}
                            error={errors.parent1Firstname && touched.parent1Firstname
                              ? t(`form.errors.${errors.parent1Firstname}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <Select
                            label={t('generalInformation.nationality')}
                            id="parent1Nationality"
                            name="parent1Nationality"
                            options={getCountriesOptions}
                            value={values.parent1Nationality}
                            error={errors.parent1Nationality && touched.parent1Nationality
                              ? t(`form.errors.${errors.parent1Nationality}`) : null
                            }
                            onBlur={handleBlur}
                            onChange={handleSelectChange}
                            required
                            isDisabled={isFormLocked}
                            filterOption={filterCountryOptions}
                          />
                          <TextField
                            label={t('generalInformation.employment')}
                            type="text"
                            name="parent1Employment"
                            id="parent1Employment"
                            value={values.parent1Employment}
                            error={errors.parent1Employment && touched.parent1Employment
                              ? t(`form.errors.${errors.parent1Employment}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <PhoneField
                            id="parent1Phone"
                            name="parent1Phone"
                            label={t('generalInformation.phone')}
                            selectPlaceholder={t('generalInformation.country')}
                            countriesList={COUNTRY_CODES.map(({ code }) => code)}
                            initialValue={values.parent1Phone}
                            onPhoneChange={({
                              fullNumber, e164, code, iso,
                            }) => {
                              setFieldTouched('parent1Phone');
                              setFieldValue('parent1PhoneDetails', { e164, code, iso });
                              setFieldValue('parent1Phone', fullNumber);
                              if (!values.emergencyPhone1) {
                                setFieldValue('emergencyPhone1', fullNumber);
                                setFieldValue('emergencyPhone1Details', { e164, code, iso });
                              }
                            }}
                            error={errors.parent1Phone && touched.parent1Phone
                              ? t(`form.errors.${errors.parent1Phone}`) : null
                      }
                            required
                            disabled={isFormLocked}
                          />
                          <TextField
                            label={t('generalInformation.email')}
                            type="email"
                            name="parent1Email"
                            id="parent1Email"
                            value={values.parent1Email}
                            error={errors.parent1Email && touched.parent1Email
                              ? t(`form.errors.${errors.parent1Email}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <FormBlock>
                            <Radio
                              id="parent1AddressRadio-yes"
                              name="parent1AddressRadio"
                              value="true"
                              checked={values.parent1AddressRadio === 'true'}
                              label={t('generalInformation.sameAddress')}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('parent1Address', values.address);
                              }}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="parent1AddressRadio-no"
                              name="parent1AddressRadio"
                              value="false"
                              checked={values.parent1AddressRadio === 'false'}
                              label={t('generalInformation.otherAddress')}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('parent1Address', '');
                              }}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <Accordion
                            isToggled={values.parent1AddressRadio === 'false'}
                          >
                            <AddressField
                              fieldName="parent1Address"
                              onAddressChange={(address) => {
                                setFieldValue('parent1Address', address);
                              }}
                              onAddressDetailsChange={(addressDetails) => {
                                setFieldValue('parent1AddressDetails', addressDetails);
                              }}
                              initialAddress={values.parent1Address}
                              initialAddressDetails={values.parent1AddressDetails}
                              isFormLocked={isFormLocked}
                            />
                          </Accordion>
                        </div>
                      </div>
                    ),
                  },
                  {
                    label: `${t('generalInformation.parent')} 2 / ${t('generalInformation.legalGuardian')} 2`,
                    child: (
                      <div key="generalInformationTab2" className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <FormBlock>
                            <Radio
                              id="parent2Gender-mister"
                              name="parent2Gender"
                              value="mister"
                              checked={values.parent2Gender === 'mister'}
                              label={t('generalInformation.mister')}
                              onChange={handleChange}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="parent2Gender-miss"
                              name="parent2Gender"
                              value="miss"
                              checked={values.parent2Gender === 'miss'}
                              label={t('generalInformation.miss')}
                              onChange={handleChange}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <TextField
                            label={t('generalInformation.lastname')}
                            type="text"
                            name="parent2Lastname"
                            id="parent2Lastname"
                            value={values.parent2Lastname}
                            error={errors.parent2Lastname && touched.parent2Lastname
                              ? t(`form.errors.${errors.parent2Lastname}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <TextField
                            label={t('generalInformation.firstname')}
                            type="text"
                            name="parent2Firstname"
                            id="parent2Firstname"
                            value={values.parent2Firstname}
                            error={errors.parent2Firstname && touched.parent2Firstname
                              ? t(`form.errors.${errors.parent2Firstname}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <Select
                            label={t('generalInformation.nationality')}
                            id="parent2Nationality"
                            name="parent2Nationality"
                            options={getCountriesOptions}
                            value={values.parent2Nationality}
                            error={errors.parent2Nationality && touched.parent2Nationality
                              ? t(`form.errors.${errors.parent2Nationality}`) : null
                            }
                            onBlur={handleBlur}
                            onChange={handleSelectChange}
                            required
                            isDisabled={isFormLocked}
                            filterOption={filterCountryOptions}
                          />
                          <TextField
                            label={t('generalInformation.employment')}
                            type="text"
                            name="parent2Employment"
                            id="parent2Employment"
                            value={values.parent2Employment}
                            error={errors.parent2Employment && touched.parent2Employment
                              ? t(`form.errors.${errors.parent2Employment}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <PhoneField
                            id="parent2Phone"
                            name="parent2Phone"
                            label={t('generalInformation.phone')}
                            selectPlaceholder={t('generalInformation.country')}
                            countriesList={COUNTRY_CODES.map(({ code }) => code)}
                            initialValue={values.parent2Phone}
                            onPhoneChange={({
                              fullNumber, e164, code, iso,
                            }) => {
                              setFieldTouched('parent2Phone');
                              setFieldValue('parent2PhoneDetails', { e164, code, iso });
                              setFieldValue('parent2Phone', fullNumber);
                              if (!values.emergencyPhone2) {
                                setFieldValue('emergencyPhone2', fullNumber);
                                setFieldValue('emergencyPhone2Details', { e164, code, iso });
                              }
                            }}
                            error={errors.parent2Phone && touched.parent2Phone
                              ? t(`form.errors.${errors.parent2Phone}`) : null
                      }
                            required
                            disabled={isFormLocked}
                          />
                          <TextField
                            label={t('generalInformation.email')}
                            type="email"
                            name="parent2Email"
                            id="parent2Email"
                            value={values.parent2Email}
                            error={errors.parent2Email && touched.parent2Email
                              ? t(`form.errors.${errors.parent2Email}`) : null
                              }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                          <FormBlock>
                            <Radio
                              id="parent2SameAddress-yes"
                              name="parent2SameAddress"
                              value="true"
                              checked={values.parent2SameAddress === 'true'}
                              label={t('generalInformation.sameAddress')}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('parent2Address', values.address);
                              }}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="parent2SameAddress-no"
                              name="parent2SameAddress"
                              value="false"
                              checked={values.parent2SameAddress === 'false'}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('parent2Address', '');
                              }}
                              label={t('generalInformation.otherAddress')}
                              disabled={isFormLocked}
                            />
                            <Accordion
                              isToggled={values.parent2SameAddress === 'false'}
                            >
                              <AddressField
                                fieldName="parent2Address"
                                onAddressChange={(address) => {
                                  setFieldValue('parent2Address', address);
                                }}
                                onAddressDetailsChange={(addressDetails) => {
                                  setFieldValue('parent2AddressDetails', addressDetails);
                                }}
                                initialAddress={values.parent2Address}
                                initialAddressDetails={values.parent2AddressDetails}
                                isFormLocked={isFormLocked}
                              />
                            </Accordion>
                          </FormBlock>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
              <div className={cn(['row', style.cardPadding])}>
                <div className="col-xs-12 col-lg-10 md-10">
                  <FormBlock>
                    <Checkbox
                      id="provideParent2Info"
                      name="provideParent2Info"
                      label={t('generalInformation.provideParent2InfoLabel')}
                      checked={values.provideParent2Info}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                </div>
                <div className="col-xs-12 col-lg-10 md-10">
                  <FormBlock label={t('generalInformation.separatedParents')}>
                    <Radio
                      id="parentsSeparated-yes"
                      name="parentsSeparated"
                      value="true"
                      checked={values.parentsSeparated === 'true'}
                      label={t('generalInformation.yes')}
                      onChange={e => handleSeparatedParentChange(e, setFieldValue)}
                      disabled={isFormLocked}
                    />
                    <Radio
                      id="parentsSeparated-no"
                      name="parentsSeparated"
                      value="false"
                      checked={values.parentsSeparated === 'false'}
                      label={t('generalInformation.no')}
                      onChange={e => handleSeparatedParentChange(e, setFieldValue)}
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                  <Accordion isToggled={values.parentsSeparated === 'true'}>
                    <Select
                      label={t('generalInformation.selectGuardian')}
                      id="guardian"
                      name="guardian"
                      options={getGuardiansOptions}
                      value={values.guardian}
                      error={errors.guardian && touched.guardian
                        ? t(`form.errors.${errors.guardian}`) : null
                      }
                      onBlur={handleBlur}
                      onChange={handleSelectChange}
                      required
                      isDisabled={isFormLocked}
                      filterOption={filterCountryOptions}
                      menuPortalTarget={document.body}
                    />
                  </Accordion>
                  <Accordion isToggled={values.guardian === 'other' && values.parentsSeparated === 'true'}>
                    <FormBlock label={t('generalInformation.gender')}>
                      <Radio
                        id="guardian-male"
                        name="guardianGender"
                        value="male"
                        checked={values.guardianGender === 'male'}
                        label={t('generalInformation.male')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="guardian-female"
                        name="guardianGender"
                        value="female"
                        checked={values.guardianGender === 'female'}
                        label={t('generalInformation.female')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                    <TextField
                      label={t('generalInformation.lastname')}
                      type="text"
                      name="guardianLastname"
                      id="guardianLastname"
                      value={values.guardianLastname}
                      error={errors.guardianLastname && touched.guardianLastname
                        ? t(`form.errors.${errors.guardianLastname}`) : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.firstname')}
                      type="text"
                      name="guardianFirstname"
                      id="guardianFirstname"
                      value={values.guardianFirstname}
                      error={errors.guardianFirstname && touched.guardianFirstname
                        ? t(`form.errors.${errors.guardianFirstname}`) : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <PhoneField
                      id="guardianPhone"
                      name="guardianPhone"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.guardianPhone}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('guardianPhone');
                        setFieldValue('guardianPhoneDetails', { e164, code, iso });
                        setFieldValue('guardianPhone', fullNumber);
                      }}
                      error={errors.guardianPhone && touched.guardianPhone
                        ? t(`form.errors.${errors.guardianPhone}`) : null
                      }
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.email')}
                      type="email"
                      name="guardianEmail"
                      id="guardianEmail"
                      value={values.guardianEmail}
                      error={errors.guardianEmail && touched.guardianEmail
                        ? t(`form.errors.${errors.guardianEmail}`) : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <AddressField
                      fieldName="guardianAddress"
                      onAddressChange={(address) => {
                        setFieldValue('guardianAddress', address);
                      }}
                      onAddressDetailsChange={(addressDetails) => {
                        setFieldValue('guardianAddressDetails', addressDetails);
                      }}
                      initialAddress={values.guardianAddress}
                      initialAddressDetails={values.guardianAddressDetails}
                      isFormLocked={isFormLocked}
                    />
                  </Accordion>
                  <FormBlock label={t('generalInformation.sendMail')}>
                    <Checkbox
                      id="sendMail-Parent1"
                      name="sendMailParent1"
                      label="Parent 1"
                      checked={values.sendMailParent1}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Checkbox
                      id="sendMail-Parent2"
                      name="sendMailParent2"
                      label="Parent 2"
                      checked={values.sendMailParent2}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Checkbox
                      id="sendMail-other"
                      name="sendMailOther"
                      label={t('generalInformation.other')}
                      checked={values.sendMailOther}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                  <Accordion isToggled={values.sendMailOther}>
                    <h2 className={style.accordionTitle}>{t('generalInformation.sendMailOther')}</h2>
                    <FormBlock label={t('generalInformation.gender')}>
                      <Radio
                        id="sendMailGender-male"
                        name="sendMailGender"
                        value="male"
                        checked={values.sendMailGender === 'male'}
                        label={t('generalInformation.male')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="sendMailGender-female"
                        name="sendMailGender"
                        value="female"
                        checked={values.sendMailGender === 'female'}
                        label={t('generalInformation.female')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                    <TextField
                      label={t('generalInformation.lastname')}
                      type="text"
                      name="sendMailLastname"
                      id="sendMailLastname"
                      value={values.sendMailLastname}
                      error={errors.sendMailLastname && touched.sendMailLastname
                        ? t(`form.errors.${errors.sendMailLastname}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.firstname')}
                      type="text"
                      name="sendMailFirstname"
                      id="sendMailFirstname"
                      value={values.sendMailFirstname}
                      error={errors.sendMailFirstname && touched.sendMailFirstname
                        ? t(`form.errors.${errors.sendMailFirstname}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <PhoneField
                      id="sendMailPhone"
                      name="sendMailPhone"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.sendMailPhone}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('sendMailPhone');
                        setFieldValue('sendMailPhoneDetails', { e164, code, iso });
                        setFieldValue('sendMailPhone', fullNumber);
                      }}
                      error={errors.sendMailPhone && touched.sendMailPhone
                        ? t(`form.errors.${errors.sendMailPhone}`) : null
                      }
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.email')}
                      type="email"
                      name="sendMailEmail"
                      id="sendMailEmail"
                      value={values.sendMailEmail}
                      error={errors.sendMailEmail && touched.sendMailEmail
                        ? t(`form.errors.${errors.sendMailEmail}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <AddressField
                      fieldName="sendMailAddress"
                      onAddressChange={(address) => {
                        setFieldValue('sendMailAddress', address);
                      }}
                      onAddressDetailsChange={(addressDetails) => {
                        setFieldValue('sendMailAddressDetails', addressDetails);
                      }}
                      initialAddress={values.sendMailAddress}
                      initialAddressDetails={values.sendMailAddressDetails}
                      isFormLocked={isFormLocked}
                    />
                  </Accordion>
                  <FormBlock label={t('generalInformation.sendFinancialMail')}>
                    <Checkbox
                      id="sendFinancialMail-parent1"
                      name="sendFinancialMailParent1"
                      label="Parent 1"
                      checked={values.sendFinancialMailParent1}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Checkbox
                      id="sendFinancialMail-Parent2"
                      name="sendFinancialMailParent2"
                      label="Parent 2"
                      checked={values.sendFinancialMailParent2}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Checkbox
                      id="sendFinancialMail-other"
                      name="sendFinancialMailOther"
                      label={t('generalInformation.other')}
                      checked={values.sendFinancialMailOther}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                  <Accordion
                    isToggled={values.sendFinancialMailOther}
                  >
                    <h2 className={style.accordionTitle}>{t('generalInformation.sendFinancialMailOther')}</h2>
                    <FormBlock label={t('generalInformation.gender')}>
                      <Radio
                        id="sendFinancialMailGender-male"
                        name="sendFinancialMailGender"
                        value="male"
                        checked={values.sendFinancialMailGender === 'male'}
                        label={t('generalInformation.male')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="sendFinancialMailGender-female"
                        name="sendFinancialMailGender"
                        value="female"
                        checked={values.sendFinancialMailGender === 'female'}
                        label={t('generalInformation.female')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                    <TextField
                      label={t('generalInformation.lastname')}
                      type="text"
                      name="sendFinancialMailLastname"
                      id="sendFinancialMailLastname"
                      value={values.sendFinancialMailLastname}
                      error={errors.sendFinancialMailLastname
                          && touched.sendFinancialMailLastname
                        ? t(`form.errors.${errors.sendFinancialMailLastname}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.firstname')}
                      type="text"
                      name="sendFinancialMailFirstname"
                      id="sendFinancialMailFirstname"
                      value={values.sendFinancialMailFirstname}
                      error={errors.sendFinancialMailFirstname
                          && touched.sendFinancialMailFirstname
                        ? t(`form.errors.${errors.sendFinancialMailFirstname}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <PhoneField
                      id="sendFinancialMailPhone"
                      name="sendFinancialMailPhone"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.sendFinancialMailPhone}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('sendFinancialMailPhone');
                        setFieldValue('sendFinancialMailPhoneDetails', { e164, code, iso });
                        setFieldValue('sendFinancialMailPhone', fullNumber);
                      }}
                      error={errors.sendFinancialMailPhone && touched.sendFinancialMailPhone
                        ? t(`form.errors.${errors.sendFinancialMailPhone}`) : null
                      }
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('generalInformation.email')}
                      type="email"
                      name="sendFinancialMailEmail"
                      id="sendFinancialMailEmail"
                      value={values.sendFinancialMailEmail}
                      error={errors.sendFinancialMailEmail && touched.sendFinancialMailEmail
                        ? t(`form.errors.${errors.sendFinancialMailEmail}`) : null
                        }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <AddressField
                      fieldName="sendFinancialMailAddress"
                      onAddressChange={(address) => {
                        setFieldValue('sendFinancialMailAddress', address);
                      }}
                      onAddressDetailsChange={(addressDetails) => {
                        setFieldValue('sendFinancialMailAddressDetails', addressDetails);
                      }}
                      initialAddress={values.sendFinancialMailAddress}
                      initialAddressDetails={values.sendFinancialMailAddressDetails}
                      isFormLocked={isFormLocked}
                    />
                  </Accordion>
                </div>
              </div>
            </Card>
            <div className={style.submitBlock}>
              {formError || isFormLocked ? (
                <div>{formError || isFormLocked}</div>
              ) : (
                <AutoSave
                  formId={match.params.id}
                  values={values}
                  isSaving={submitLoading}
                  onSave={updateStudentForm}
                  error={submitError}
                />
              )}
              <Button
                className={style.submitForm}
                type="button"
                color="green"
                label={
                submitted || isFormLocked
                  ? t('ratesInformation.submitApplicationSuccess')
                  : t('ratesInformation.submitApplication')
              }
                onClick={submitForm}
                disabled={!allowToSubmit(progress) || submitted || isFormLocked}
              />
              <Button
                className={style.submit}
                type="button"
                label={t('generalInformation.next')}
                onClick={() => saveForm(values)}
                disabled={submitLoading}
              />
            </div>
          </form>
        )
      }
    </WithSide>
  );
};

GeneralInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  history: PropTypes.shape().isRequired,
};

export default GeneralInformation;
