import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';

// Constants
import { AUTH_HOSTNAME } from '../../constants/constants';
import useAppContext from '../../hooks/useAppContext';

const keycloakConfig = {
  realm: 'mouratoglou',
  url: `https://${AUTH_HOSTNAME}/auth`,
  clientId: 'se-service',
};

const initConfig = {
  // onLoad: "check-sso",
  // silentCheckSsoRedirectUri: `https://${SPA_HOSTNAME}/silent-check-sso.html`,
  checkLoginIframe: false,
  silentCheckSsoFallback: false,
};

export const KeycloakAuthContext = createContext();

export const KeycloakAuthProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState({});

  const { clearAuth } = useAppContext();

  useEffect(() => {
    const keycloakInit = new Keycloak(keycloakConfig);

    keycloakInit.init(initConfig)
      .then(() => {
        setKeycloak(keycloakInit);
      })
      .catch((error) => {
        console.error(error);
      });

    keycloakInit.onAuthLogout = () => {
      clearAuth();
      setKeycloak({});
    };
  }, []);

  return (
    <KeycloakAuthContext.Provider value={{ keycloak }}>
      {children}
    </KeycloakAuthContext.Provider>
  );
};

KeycloakAuthProvider.propTypes = {
  children: PropTypes.node,
};

KeycloakAuthProvider.defaultProps = {
  children: null,
};
