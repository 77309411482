import { useContext } from 'react';

// Utils & misc
import { AppContext } from '../context/AppContext';
import computeProgression from '../utils/computeProgression';

const useAppContext = () => {
  const [state, setState] = useContext(AppContext);

  const setIsAdmin = () => {
    setState(prevState => ({ ...prevState, auth: { ...prevState.auth, role: 'admin' } }));
  };

  const clearAuth = () => {
    localStorage.removeItem('context');
    // setState({ auth: { role: 'guardian' } });
  };

  const setActiveStudent = (activeStudent) => {
    setState(prevState => ({ ...prevState, activeStudent }));
  };

  const setActiveForm = (activeForm) => {
    setState(prevState => ({ ...prevState, activeForm }));
  };

  const computeProgress = (values, activeForm) => {
    const { progress, errors } = computeProgression(values, activeForm);
    setState(prevState => ({
      ...prevState,
      progress,
      errors,
    }));
  };

  return {
    context: state,
    setIsAdmin,
    clearAuth,
    setActiveStudent,
    computeProgress,
    setActiveForm,
  };
};

export default useAppContext;
