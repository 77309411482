import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { format } from 'date-fns';

// Components
import { isEmpty, isNil } from 'lodash';
import WithSide from '../components/templates/WithSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';
import TextField from '../components/atoms/TextField';
import Radio from '../components/atoms/Radio';
import Checkbox from '../components/atoms/Checkbox';
import AutoSave from '../components/atoms/AutoSave';
import FormBlock from '../components/atoms/FormBlock';
import FlashMessage from '../components/molecules/FlashMessage/FlashMessage';

// Utils & Misc
import { generalValidate } from '../utils/validators';
import shouldDisable from '../utils/shouldDisable';
import allowToSubmit from '../utils/allowToSubmit';
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import { FORM_INITIAL_VALUES as FIV, API_URL_BASE, MANDATORY_FIELDS } from '../constants/constants';

// Style
import style from './formApp.module.css';
import adminInfoStyle from './AdministrativeInformation.module.css';

const AdministrativeInformation = ({ match, history }) => {
  const { t, i18n } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const { context: { activeForm, auth, progress }, computeProgress } = useAppContext();
  const {
    loading, responseData: formData, responseError: formError, getStudentForm,
  } = useApi();
  const {
    loading: submitLoading, responseError: submitError, updateStudentForm,
  } = useApi();
  const {
    postFormEvent,
  } = useApi();
  const {
    values, errors, touched, handleChange, handleBlur, setFieldValue,
  } = useFormik({
    initialValues: {
      ...FIV.generalInfo,
      ...FIV.scholarshipInfo,
      ...FIV.sportInfo,
      ...FIV.medicalInfo,
      ...FIV.administrativeInfo,
      ...FIV.ratesInfo,
      ...formData,
    },
    enableReinitialize: true,
    validate: generalValidate,
  });

  const isFormLocked = useMemo(() => (
    activeForm ? shouldDisable(activeForm.stateId, auth.role) : true
  ), [activeForm]);

  const submitForm = () => {
    postFormEvent(match.params.id, 'submit').then(() => {
      setSubmitted(true);
      history.push('/confirmation', { studentId: match.params.id });
    });
    // if (allowToSubmit(progress)) postFormEvent(match.params.id, 'submit');
  };

  useEffect(() => {
    getStudentForm(match.params.id);
  }, []);

  const saveForm = () => {
    updateStudentForm(match.params.id, values);
    computeProgress(values, activeForm);
  };

  const termsUrl = useMemo(() => {
    if (activeForm && activeForm.typeId && i18n) {
      return `${API_URL_BASE}public/terms-${activeForm.typeId}-${i18n.language}.pdf`;
    }
    return '';
  }, [activeForm, i18n]);

  const hasInsurance = useMemo(() => (
    values.hasInsurance === true || values.hasInsurance === 'true'
  ), [values]);
  const noInsurance = useMemo(() => (
    values.hasInsurance === false || values.hasInsurance === 'false'
  ), [values]);

  const isOnlyMissingTermsAcceptance = useMemo(() => {
    if (!progress || !values || isEmpty(progress) || isEmpty(values)) return false;

    // check if all fields from admin section are completed
    const allRequiredAdminFieldsAreCompleted = MANDATORY_FIELDS.administrativeInformation
      .filter(field => field !== 'acceptTerms')
      .every(val => !isNil(values[val]) && values[val] !== '');

    // check if every fields of every other sections are completed
    const everyOtherSectionsAreCompleted = Object.keys(progress)
      .filter(section => section !== 'administrativeInformation')
      .every(section => progress[section] === 1);

    // check if terms are accepted or not
    const termsAreNotAccepted = values.acceptTerms !== true && values.acceptTerms !== 'true';

    return (
      allRequiredAdminFieldsAreCompleted
      && everyOtherSectionsAreCompleted
      && termsAreNotAccepted
    );
  }, [values, progress]);

  return (
    <WithSide title={t('administrativeInformation.viewTitle')}>
      {loading
        ? <div />
        : (
          <form>
            <Card className="card-margin">
              <h2 className="card-title">{t('administrativeInformation.insurance')}</h2>

              <FormBlock label={t('administrativeInformation.hasInsurance')}>
                <Radio
                  id="hasInsurance-yes"
                  name="hasInsurance"
                  value="true"
                  label={t('medicalInformation.yes')}
                  onChange={() => setFieldValue('hasInsurance', true)}
                  checked={hasInsurance}
                  disabled={isFormLocked}
                />
                <Radio
                  id="hasInsurance-no"
                  name="hasInsurance"
                  value="false"
                  label={t('medicalInformation.no')}
                  onChange={() => setFieldValue('hasInsurance', false)}
                  checked={noInsurance}
                  disabled={isFormLocked}
                />
              </FormBlock>

              {hasInsurance ? (
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <TextField
                      label={t('administrativeInformation.insurerName')}
                      type="text"
                      name="insurerName"
                      id="insurerName"
                      value={values.insurerName}
                      error={errors.insurerName && touched.insurerName
                        ? t(`form.errors.${errors.insurerName}`) : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                    <TextField
                      label={t('administrativeInformation.contractNo')}
                      type="text"
                      name="contractNo"
                      id="contractNo"
                      value={values.contractNo}
                      error={errors.contractNo && touched.contractNo
                        ? t(`form.errors.${errors.contractNo}`) : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
              ) : null}

              {noInsurance ? (
                <p>{t('administrativeInformation.insuranceSubscriptionCommitment')}</p>
              ) : null}

              <h2 className="card-title card-title-margin">{t('administrativeInformation.activities')}</h2>
              <p>{t('administrativeInformation.activitiesText1')}</p>
              <p>{t('administrativeInformation.activitiesText2')}</p>
              <Radio
                id="activitiesAuth-yes"
                name="activitiesAuth"
                value="true"
                label={t('administrativeInformation.authorize')}
                onChange={handleChange}
                checked={values.activitiesAuth === 'true'}
                disabled={isFormLocked}
              />
              <Radio
                id="activitiesAuth-no"
                name="activitiesAuth"
                value="false"
                label={t('administrativeInformation.doNotAuthorize')}
                onChange={handleChange}
                checked={values.activitiesAuth === 'false'}
                disabled={isFormLocked}
              />
              <p>{t('administrativeInformation.activitiesText3')}</p>
              <h2 className="card-title card-title-margin">{t('administrativeInformation.transport')}</h2>
              <Radio
                id="transportAuth-yes"
                name="transportAuth"
                value="true"
                label={t('administrativeInformation.authorize')}
                onChange={handleChange}
                checked={values.transportAuth === 'true'}
                disabled={isFormLocked}
              />
              <Radio
                id="transportAuth-no"
                name="transportAuth"
                value="false"
                label={t('administrativeInformation.doNotAuthorize')}
                onChange={handleChange}
                checked={values.transportAuth === 'false'}
                disabled={isFormLocked}
              />
              <p>
                {t('administrativeInformation.transportText')}
              </p>
            </Card>


            {/* General terms */}

            <Card className="card-margin">
              <h2 className="card-title">{t('administrativeInformation.generalTerms')}</h2>

              <FlashMessage className={adminInfoStyle.cgv_required}>
                {t('administrativeInformation.acceptTermsRequired')}
              </FlashMessage>

              <Button
                label={t('administrativeInformation.openTermsDoc')}
                onClick={() => window.open(termsUrl, '_blank')}
                className={adminInfoStyle.cgv_btn}
              />

              <div className={style.dateBlock}>
                <span>Date:&nbsp;</span>
                <span className={style.date}>{format(new Date(), 'dd/MM/yyyy')}</span>
              </div>
              <Checkbox
                id="acceptTerms"
                name="acceptTerms"
                label={t('administrativeInformation.acceptTerms')}
                onChange={handleChange}
                checked={values.acceptTerms}
                disabled={isFormLocked}
              />
            </Card>


            <div className={style.submitBlock}>
              {isOnlyMissingTermsAcceptance ? (
                <FlashMessage color="success" className={adminInfoStyle.cgv_required}>
                  {t('administrativeInformation.onlyMissingTerms')}
                </FlashMessage>
              ) : null}

              {formError || isFormLocked ? (
                <div>{formError || isFormLocked}</div>
              ) : (
                <AutoSave
                  formId={match.params.id}
                  values={values}
                  isSaving={submitLoading}
                  onSave={updateStudentForm}
                  error={submitError}
                />
              )}
              <Button
                className={style.submitForm}
                type="button"
                color="green"
                label={
                submitted || isFormLocked
                  ? t('ratesInformation.submitApplicationSuccess')
                  : t('ratesInformation.submitApplication')
              }
                onClick={submitForm}
                disabled={!allowToSubmit(progress) || submitted || isFormLocked}
              />
              <Button
                className={style.submit}
                type="button"
                label={t('generalInformation.next')}
                onClick={() => saveForm(values)}
                disabled={submitLoading}
              />
            </div>
          </form>
        )
      }
    </WithSide>
  );
};

AdministrativeInformation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape().isRequired,
};

export default AdministrativeInformation;
