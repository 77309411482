/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Component
import Header from '../organisms/Header';
import Footer from '../organisms/Footer';
import ProfilCard from '../molecules/ProfilCard';
import Modal, { useModal } from '../molecules/Modal';
import EditPLayerForm from './forms/EditPlayerForm';

// Utils & misc
import useAppContext from '../../hooks/useAppContext';
import useApi from '../../hooks/useApi';

// Style
import style from './WithUserSide.module.css';

// Utils
import makeId from '../../utils/makeId';

const WithUserSide = ({
  user, children, headerStyle, footerStyle,
}) => {
  const [displayPlayerModal, togglePlayerModal] = useModal();
  const { context: { activeStudent } } = useAppContext();
  const { updateStudent, uploadThumbnail, getThumbnail } = useApi();
  const [thumbnailImage, setThumbnailImage] = useState(null);

  const fetchThumbnail = async (thumbnailId, studentId) => {
    try {
      const resp = await getThumbnail(thumbnailId, studentId);
      const { data: thumbnail } = resp;

      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(thumbnail);
      fileReaderInstance.onload = () => {
        setThumbnailImage(fileReaderInstance.result);
      };
    } catch (error) {
      console.error('error in fetchThumbnail:', error);
    }
  };

  useEffect(() => {
    // Get the document
    if (activeStudent.thumbnailId) {
      fetchThumbnail(activeStudent.thumbnailId, activeStudent.id);
    }
  }, [activeStudent ? activeStudent.thumbnailId : null]);

  const handleSubmitEditFrom = async (values, fileName) => {
    const updatedValues = values;
    try {
      const { data: thumbnail } = await uploadThumbnail(fileName, values.thumbnail, activeStudent.id);

      if (!thumbnail || !thumbnail.id) {
        alert('une erreur est survenue (code: 0)');
      }

      updatedValues.thumbnailId = thumbnail.id;
      delete updatedValues.thumbnail;

      updateStudent(values);
      togglePlayerModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error in handleSubmitEditFrom:', error);
      alert('une erreur est survenue (code: 1)');
    }
  };

  return (
    <div className={style.wrapper}>
      <Header
        color={headerStyle}
        languages={['fr', 'en']}
      />
      <main className={style.main}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-md-2">
              <ProfilCard
                label={`${user.firstname} ${user.lastname}`}
                picture={thumbnailImage}
                onEditClick={togglePlayerModal}
              />
            </div>
            <div className="col-xs-12 col-md-8">
              {children}
            </div>
          </div>
        </div>
      </main>
      <Modal isDisplayed={displayPlayerModal} onClose={togglePlayerModal}>
        <EditPLayerForm onSubmit={handleSubmitEditFrom} initialValues={activeStudent} />
      </Modal>
      <Footer color={footerStyle} />
    </div>
  );
};

WithUserSide.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    thumbnail: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  headerStyle: PropTypes.oneOf(['blue', 'white', 'transparent', 'fillWhite']),
  footerStyle: PropTypes.oneOf(['white', 'black']),
};

WithUserSide.defaultProps = {
  headerStyle: 'white',
  footerStyle: 'black',
};

export default WithUserSide;
