import React from 'react';
import PropTypes from 'prop-types';

// Component
import Header from '../organisms/Header';
import Footer from '../organisms/Footer';

// Style
import style from './NoSide.module.css';

// Utils
import cn from '../../utils/cn';

const NoSide = ({
  children, title, subtitle, background, headerStyle, footerStyle, noHeader, verticalCenter,
}) => (
  <div className={cn([style.wrapper, verticalCenter ? style.wrapperCenter : ''])} style={{ backgroundImage: `url(${background})` }}>
    {!noHeader
      ? (
        <Header
          color={headerStyle}
          languages={['fr', 'en']}
        />
      )
      : null
      }
    <main className={style.main}>
      {(title || subtitle)
        ? (
          <div className={style.titlesBlock}>
            {title ? <span className={style.title}>{title}</span> : null}
            {subtitle ? <span className={style.subtitle}>{subtitle}</span> : null}
          </div>
        )
        : null
        }
      {children}
    </main>
    <Footer color={footerStyle} />
  </div>
);

NoSide.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.string,
  headerStyle: PropTypes.oneOf(['blue', 'white', 'transparent']),
  footerStyle: PropTypes.oneOf(['white', 'black']),
  noHeader: PropTypes.bool,
  verticalCenter: PropTypes.bool,
};

NoSide.defaultProps = {
  title: '',
  subtitle: '',
  background: '',
  headerStyle: 'transparent',
  footerStyle: 'white',
  noHeader: false,
  verticalCenter: false,
};

export default NoSide;
