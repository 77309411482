import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { useTranslation, Trans } from 'react-i18next';
import i18nCountries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesFR from 'i18n-iso-countries/langs/fr.json';
import { useFormik } from 'formik';
import { lowerCase, isEmpty } from 'lodash';

// Components
import WithSide from '../components/templates/WithSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import TextField from '../components/atoms/TextField';
import Radio from '../components/atoms/Radio';
import RadioCustom from '../components/atoms/RadioCustom';
import Checkbox from '../components/atoms/Checkbox';
import Counter from '../components/molecules/Counter';
import Range from '../components/atoms/Range';
import FormBlock from '../components/atoms/FormBlock';
import AutoSave from '../components/atoms/AutoSave';
import PhoneField from '../components/atoms/PhoneField';
import Accordion from '../components/organisms/Accordion';
import DropZone from '../components/molecules/DropZone';
import AddressField from '../components/molecules/AddressField';

// Utils & Misc
import cn from '../utils/cn';
import { scholarshipValidate } from '../utils/validators';
import shouldDisable from '../utils/shouldDisable';
import allowToSubmit from '../utils/allowToSubmit';
import filterCountryOptions from '../utils/filterCountryOptions';
import {
  API_URL,
  LV2_OPTIONS,
  FR_GRADES,
  FR_GRADES_PREV,
  COUNTRY_CODES,
  INT_GRADES,
  PROJECT_AFTER_SCHOOL,
  FRANCE_STATES,
  SCHOOL_OWNERSHIP,
  HIGHSCHOOL_SPECIALITIES_GRADE_11,
  HIGHSCHOOL_SPECIALITIES_GRADE_12,
  LANGUAGES_CODE,
  REQUEST_FOR_STUDENT_RECORDS,
  PSYCHO_EDUCATIONAL_REPORT,
  API_URL_BASE,
  FORM_INITIAL_VALUES as FIV,
} from '../constants/constants';
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import i18n from '../i18n';

// Style
import style from './formApp.module.css';
import flagsStyle from '../constants/flags.module.css';

// TODO: remove all useAccordion and refacto should disable

const ScholarshipInformation = ({ match, history }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [documents, setDocuments] = useState({});
  i18nCountries.registerLocale(i18n.language === 'en' ? countriesEN : countriesFR);
  const {
    context: {
      activeStudent, activeForm, auth, progress,
    }, computeProgress,
  } = useAppContext();
  const {
    loading, responseData: formData, responseError: formError, getStudentForm,
  } = useApi();

  const {
    responseData: responseForm,
    getFormInfo,
  } = useApi();
  const { updateFormInfo } = useApi();
  const {
    loading: submitLoading, responseError: submitError, updateStudentForm,
  } = useApi();
  const {
    postFormEvent,
  } = useApi();
  const { loading: loadDocs, uploadDocument } = useApi();
  const { getDocument } = useApi();
  const {
    values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched,
  } = useFormik({
    initialValues: {
      ...FIV.generalInfo,
      ...FIV.scholarshipInfo,
      ...FIV.sportInfo,
      ...FIV.medicalInfo,
      ...FIV.administrativeInfo,
      ...FIV.ratesInfo,
      ...formData,
    },
    enableReinitialize: true,
    validate: scholarshipValidate,
  });
  const isFormLocked = useMemo(() => (
    activeForm ? shouldDisable(activeForm.stateId, auth.role) : true
  ), [activeForm]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
  };

  const submitForm = () => {
    postFormEvent(match.params.id, 'submit').then(() => {
      setSubmitted(true);
      history.push('/confirmation', { studentId: match.params.id });
    });
    // if (allowToSubmit(progress)) postFormEvent(match.params.id, 'submit');
  };

  useEffect(() => {
    getStudentForm(match.params.id);
    getFormInfo(match.params.id);
  }, []);

  useEffect(() => {
    if (responseForm) {
      const { documents: documentsData } = responseForm;
      if (!isEmpty(documentsData)) setDocuments({ ...documents, ...documentsData });
    }
  }, [responseForm]);

  const saveForm = () => {
    updateStudentForm(match.params.id, values);
    computeProgress(values, activeForm);
  };

  const getLv2Options = () => Object.keys(LV2_OPTIONS[i18n.language]).map(countryIso => ({
    value: countryIso,
    label: (
      <span>
        <i className={cn([flagsStyle['flag-icon'], flagsStyle[`flag-icon-${countryIso === 'en' ? 'gb' : lowerCase(countryIso)}`]])} />
        &nbsp;
        {LV2_OPTIONS[i18n.language][countryIso]}
      </span>
    ),
  }));

  const getCountriesOptions = () => COUNTRY_CODES.map(({ code }) => (
    {
      value: code,
      label: (
        <span>
          <i className={cn([flagsStyle['flag-icon'], flagsStyle[`flag-icon-${lowerCase(code)}`]])} />
          &nbsp;
          {i18nCountries.getName(code, i18n.language)}
        </span>
      ),
    }
  ));

  const specialisationCount = values.askingGrade === 'T' ? 2 : 3;
  const HIGHSCHOOL_SPECIALITIES = values.askingGrade === 'T' ? HIGHSCHOOL_SPECIALITIES_GRADE_12 : HIGHSCHOOL_SPECIALITIES_GRADE_11;

  const handleSpecialitiesChoice = (e, formAction, choice, formValues) => {
    const isChecked = e.target.checked === true;

    const isSubjectSelected = sub => (formValues.gradeSpecialities
      ? (formValues.gradeSpecialities.find(id => HIGHSCHOOL_SPECIALITIES[id] === sub) !== undefined)
      : false);

    const hasMaximumValues = formValues.gradeSpecialities
      ? formValues.gradeSpecialities.length > (specialisationCount - 1)
      : false;

    let newArray = formValues.gradeSpecialities
      ? formValues.gradeSpecialities.slice(0)
      : [];

    if (isChecked) {
      // Subject choice
      const mathsSelected = isSubjectSelected('maths');
      const historySelected = isSubjectSelected('history');
      const physicSelected = isSubjectSelected('physic');
      const economySelected = isSubjectSelected('economy');
      const biologySelected = isSubjectSelected('biology');
      const geopoliticSelected = isSubjectSelected('geopolitic');

      if (values.askingGrade === 'T' && HIGHSCHOOL_SPECIALITIES[choice] === 'history' && mathsSelected) {
        newArray = newArray.filter(id => HIGHSCHOOL_SPECIALITIES[id] !== 'maths');
        newArray.push(choice);
      } else if (values.askingGrade === 'T' && HIGHSCHOOL_SPECIALITIES[choice] === 'maths' && historySelected) {
        newArray = newArray.filter(id => HIGHSCHOOL_SPECIALITIES[id] !== 'history');
        newArray.push(choice);
      } else if (HIGHSCHOOL_SPECIALITIES[choice] === 'economy' && physicSelected) {
        newArray = newArray.filter(id => HIGHSCHOOL_SPECIALITIES[id] !== 'physic');
        newArray.push(choice);
      } else if (HIGHSCHOOL_SPECIALITIES[choice] === 'physic' && economySelected) {
        newArray = newArray.filter(id => HIGHSCHOOL_SPECIALITIES[id] !== 'economy');
        newArray.push(choice);
      } else if (HIGHSCHOOL_SPECIALITIES[choice] === 'geopolitic' && biologySelected) {
        newArray = newArray.filter(id => HIGHSCHOOL_SPECIALITIES[id] !== 'biology');
        newArray.push(choice);
      } else if (HIGHSCHOOL_SPECIALITIES[choice] === 'biology' && geopoliticSelected) {
        newArray = newArray.filter(id => HIGHSCHOOL_SPECIALITIES[id] !== 'geopolitic');
        newArray.push(choice);
      } else if (!hasMaximumValues) {
        newArray.push(choice);
      }
    } else if (!isChecked) {
      newArray = newArray.filter(id => id !== choice);
    }

    newArray = newArray.sort((a, b) => a - b);
    formAction(
      'gradeSpecialities',
      newArray,
    );
  };

  const getStateOptions = FRANCE_STATES.map(state => ({
    value: state,
    label: state,
  }));
  const getSchoolOwnershipOptions = SCHOOL_OWNERSHIP.map(state => ({
    value: state,
    label: state,
  }));
  const getLanguagesOptions = Object.keys(LANGUAGES_CODE[i18n.language]).map(code => (
    {
      label: <span>{LANGUAGES_CODE[i18n.language][code]}</span>,
      value: code,
    }
  ));

  const postDocument = (file, typeId) => {
    uploadDocument(file, activeStudent.id).then((response) => {
      const documentsUpdate = {
        ...documents,
        [typeId]: {
          name: file.name,
          id: response.id,
          typeId,
        },
      };
      updateFormInfo(match.params.id, { documents: documentsUpdate }).then(() => {
        setDocuments(documentsUpdate);
      });
    });
  };

  const handleDocumentClear = (typeId) => {
    const documentsUpdate = { ...documents };
    delete documentsUpdate[typeId];
    setDocuments(documentsUpdate);
    updateFormInfo(match.params.id, { documents: documentsUpdate }).then(() => {
      //
    }).catch(() => {
      setDocuments(documents);
    });
  };

  const handleFileNameClick = (file) => {
    getDocument(file.id, match.params.id).then((response) => {
      if (response) saveAs(response.data, file.name);
    });
  };

  const IS_CENTER = useMemo(() => (
    responseForm
      ? (responseForm.typeId === 2 || responseForm.typeId === 3 || responseForm.typeId === 7)
      : false
  ), [responseForm]);

  const IS_PRO_TEAM = useMemo(
    () => (responseForm ? responseForm.typeId === 5 : false), [responseForm],
  );

  const IS_FRENCH_SCHOOL = useMemo(
    () => (activeForm ? activeForm.schoolId === 1 : false), [activeForm],
  );

  const IS_INTERNATIONAL_SCHOOL = useMemo(
    () => (activeForm ? activeForm.schoolId === 2 : false), [activeForm],
  );

  return (
    <WithSide title={t('scholarshipInformation.viewTitle')}>
      {loading && !responseForm
        ? <div />
        : (
          <form>
            {IS_PRO_TEAM
              ? (
                <Card className="card-margin">
                  <div className="col-xs-12 col-lg-12">
                    <FormBlock label={t('scholarshipInformation.followOtherOnlineProgram')}>
                      <Radio
                        id="followsOtherOnlineProgram-yes"
                        name="followsOtherOnlineProgram"
                        value="true"
                        checked={values.followsOtherOnlineProgram === 'true'}
                        label={t('scholarshipInformation.yes')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="followsOtherOnlineProgram-no"
                        name="followsOtherOnlineProgram"
                        value="false"
                        checked={values.followsOtherOnlineProgram === 'false'}
                        label={t('scholarshipInformation.no')}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                    {values.followsOtherOnlineProgram === 'true'
                      ? (
                        <div className={style.mt2}>
                          <TextField
                            label={t('scholarshipInformation.whichProgram')}
                            type="text"
                            name="whichOnlineProgram"
                            id="whichOnlineProgram"
                            value={values.whichOnlineProgram}
                            error={errors.whichOnlineProgram && touched.whichOnlineProgram
                              ? t(`form.errors.${errors.whichOnlineProgram}`) : null
                          }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </div>
                      ) : null}
                  </div>
                </Card>
              ) : null
            }

            {!IS_CENTER && !IS_PRO_TEAM ? (
              <>
                <Card className="card-margin">
                  <h2 className="card-title">{t('scholarshipInformation.grade')}</h2>
                  <div className="row">
                    <div className="col-xs-12 col-lg-12">
                      <div className={cn([style.radioList, style.customRadioList])}>
                        {IS_FRENCH_SCHOOL
                          ? FR_GRADES.map(grade => (
                            <RadioCustom
                              key={grade.subLabel}
                              id={`${grade.subLabel}-${grade.label}`}
                              name="askingGrade"
                              label={grade.label}
                              subLabel={grade.subLabel}
                              value={grade.subLabel}
                              checked={values.askingGrade === grade.subLabel}
                              disabled
                            />
                          ))
                          : INT_GRADES.map(grade => (
                            <RadioCustom
                              key={grade.subLabel}
                              id={`${grade.subLabel}-${grade.label}`}
                              name="askingGrade"
                              label={grade.label}
                              subLabel={grade.subLabel}
                              value={grade.subLabel}
                              checked={values.askingGrade === grade.subLabel}
                              disabled
                            />
                          ))
                        }
                      </div>


                      <Accordion isToggled={values.askingGrade === '1' || values.askingGrade === 'T'}>
                        <FormBlock label={t('scholarshipInformation.specialitiyLabel', { count: specialisationCount })}>
                          {
                            HIGHSCHOOL_SPECIALITIES.map((spec, index) => (
                              <Checkbox
                                key={spec}
                                id={`gradeSpecialities-speciality-${index}`}
                                name={`gradeSpecialitiy${index}`}
                                label={t(`scholarshipInformation.${spec}`)}
                                onChange={(e) => {
                                  handleSpecialitiesChoice(e, setFieldValue, index, values);
                                }}
                                checked={values.gradeSpecialities
                                  ? values.gradeSpecialities.includes(index)
                                  : false
                                }
                                disabled={isFormLocked}
                                className={style.specialityBox}
                              />
                            ))}

                          <div className={style.topMargin}>
                            {values.gradeSpecialities.length >= specialisationCount
                              ? t('scholarshipInformation.selectSpecialitiesNone')
                              : (
                                <Trans
                                  i18nKey="scholarshipInformation.selectSpecialities"
                                  count={
                                    specialisationCount - values.gradeSpecialities.length
                                  }
                                >
                                  Select
                                  <strong>
                                    {{
                                      number: values.gradeSpecialities
                                        ? (
                                          specialisationCount
                                          - values.gradeSpecialities.length
                                        )
                                        : specialisationCount,
                                    }}
                                  </strong>
                                  specialities from :
                                </Trans>
                              )
                            }
                          </div>
                        </FormBlock>
                      </Accordion>
                    </div>
                  </div>
                </Card>

                {IS_FRENCH_SCHOOL ? (
                  <Card className="card-margin">
                    <h2 className="card-title">{t('scholarshipInformation.languages')}</h2>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10">
                        <Range
                          className={style.inputMargin}
                          id="writtenEnglishLevel"
                          name="writtenEnglishLevel"
                          label={t('scholarshipInformation.writtenEnglishLevel')}
                          min="0"
                          max="4"
                          step="1"
                          defaultValue={values.writtenEnglishLevel || '0'}
                          value={values.writtenEnglishLevel || '0'}
                          showSteps
                          rangeLabels={[
                            t('scholarshipInformation.beginner'),
                            t('scholarshipInformation.fluent'),
                          ]}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                      </div>
                      <div className="col-xs-12 col-lg-10">
                        <Range
                          className={style.inputMargin}
                          id="spokenEnglishLevel"
                          name="spokenEnglishLevel"
                          label={t('scholarshipInformation.spokenEnglishLevel')}
                          min="0"
                          max="4"
                          step="1"
                          defaultValue={values.spokenEnglishLevel || '0'}
                          value={values.spokenEnglishLevel || '0'}
                          showSteps
                          rangeLabels={[
                            t('scholarshipInformation.beginner'),
                            t('scholarshipInformation.fluent'),
                          ]}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                      </div>
                      <div className="col-xs-12 col-lg-10 md-10">
                        <Select
                          className={style.selectCountry}
                          id="lv2"
                          name="lv2"
                          label={t('scholarshipInformation.foreignLanguage2')}
                          placeholder={t('scholarshipInformation.chooseForeignLanguage')}
                          value={values.lv2}
                          options={getLv2Options()}
                          onChange={handleSelectChange}
                          menuPortalTarget={document.body}
                          isDisabled={isFormLocked}
                          filterOption={filterCountryOptions}
                        />
                      </div>

                      <div className="col-xs-12 col-lg-10">
                        <Range
                          className={style.inputMargin}
                          id="writtenforeignLanguage2Level"
                          name="writtenforeignLanguage2Level"
                          label={t('scholarshipInformation.writtenforeignLanguage2Level')}
                          min="0"
                          max="4"
                          step="1"
                          defaultValue={values.writtenforeignLanguage2Level || '0'}
                          value={values.writtenforeignLanguage2Level || '0'}
                          showSteps
                          rangeLabels={[
                            t('scholarshipInformation.beginner'),
                            t('scholarshipInformation.fluent'),
                          ]}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                      </div>

                      <div className="col-xs-12 col-lg-10">
                        <Range
                          className={style.inputMargin}
                          id="spokenforeignLanguage2Level"
                          name="spokenforeignLanguage2Level"
                          label={t('scholarshipInformation.spokenforeignLanguage2Level')}
                          min="0"
                          max="4"
                          step="1"
                          defaultValue={values.spokenforeignLanguage2Level || '0'}
                          value={values.spokenforeignLanguage2Level || '0'}
                          showSteps
                          rangeLabels={[
                            t('scholarshipInformation.beginner'),
                            t('scholarshipInformation.fluent'),
                          ]}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                      </div>
                    </div>
                  </Card>
                ) : null}

                <Card className="card-margin">
                  <h2 className="card-title">{t('scholarshipInformation.prevSchool')}</h2>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <TextField
                        label={t('scholarshipInformation.previousSchool')}
                        type="text"
                        name="previousSchoolName"
                        id="previousSchoolName"
                        value={values.previousSchoolName}
                        error={errors.previousSchoolName && touched.previousSchoolName
                          ? t(`form.errors.${errors.previousSchoolName}`) : null
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={isFormLocked}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <TextField
                        label={t('scholarshipInformation.previousSchoolEmail')}
                        type="email"
                        name="previousSchoolEmail"
                        id="previousSchoolEmail"
                        value={values.previousSchoolEmail}
                        error={errors.previousSchoolEmail && touched.previousSchoolEmail
                          ? t(`form.errors.${errors.previousSchoolEmail}`) : null
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        disabled={isFormLocked}
                      />
                    </div>
                  </div>

                  {IS_INTERNATIONAL_SCHOOL ? (
                    <>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <PhoneField
                            id="previousSchoolPhone"
                            name="previousSchoolPhone"
                            label={t('generalInformation.phone')}
                            selectPlaceholder={t('generalInformation.country')}
                            countriesList={COUNTRY_CODES.map(({ code }) => code)}
                            initialValue={values.previousSchoolPhone}
                            onPhoneChange={({
                              fullNumber, e164, code, iso,
                            }) => {
                              setFieldTouched('previousSchoolPhone');
                              setFieldValue('previousSchoolPhoneDetails', { e164, code, iso });
                              setFieldValue('previousSchoolPhone', fullNumber);
                            }}
                            error={
                              (errors.previousSchoolPhone && touched.previousSchoolPhone)
                                ? t(`form.errors.${errors.previousSchoolPhone}`)
                                : null
                            }
                            required
                            disabled={isFormLocked}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <AddressField
                            fieldName="previousSchoolAddress"
                            onAddressChange={(address) => {
                              setFieldValue('previousSchoolAddress', address);
                            }}
                            onAddressDetailsChange={(addressDetails) => {
                              setFieldValue('previousSchoolAddressDetails', addressDetails);
                            }}
                            initialAddress={values.previousSchoolAddress}
                            initialAddressDetails={values.previousSchoolAddressDetails}
                            isFormLocked={isFormLocked}
                          />
                          <Select
                            id="schoolCountry"
                            name="schoolCountry"
                            label={t('scholarshipInformation.schoolCountry')}
                            placeholder={t('scholarshipInformation.chooseCountry')}
                            value={values.schoolCountry}
                            options={getCountriesOptions()}
                            onChange={handleSelectChange}
                            menuPortalTarget={document.body}
                            isDisabled={isFormLocked}
                            filterOption={filterCountryOptions}
                          />
                          <TextField
                            label={t('scholarshipInformation.schoolState')}
                            type="text"
                            name="schoolState"
                            id="schoolState"
                            value={values.schoolState}
                            error={errors.schoolState && touched.schoolState
                              ? t(`form.errors.${errors.schoolState}`) : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <TextField
                            label={t('scholarshipInformation.americanCurriculum')}
                            type="text"
                            name="americanCurriculum"
                            id="americanCurriculum"
                            value={values.americanCurriculum}
                            error={errors.americanCurriculum && touched.americanCurriculum
                              ? t(`form.errors.${errors.americanCurriculum}`) : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />

                          <FormBlock label={t('scholarshipInformation.didRepeat')}>
                            <Radio
                              id="didRepeat-yes"
                              name="didRepeat"
                              value="true"
                              label={t('scholarshipInformation.yes')}
                              onChange={handleChange}
                              checked={values.didRepeat === 'true'}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="didRepeat-no"
                              name="didRepeat"
                              value="false"
                              label={t('scholarshipInformation.no')}
                              onChange={handleChange}
                              checked={values.didRepeat === 'false'}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <Accordion isToggled={values.didRepeat === 'true'}>
                            <TextField
                              label={t('scholarshipInformation.specifyClass')}
                              type="text"
                              name="didRepeatSpecify"
                              id="didRepeatSpecify"
                              value={values.didRepeatSpecify}
                              error={errors.didRepeatSpecify && touched.didRepeatSpecify
                                ? t(`form.errors.${errors.didRepeatSpecify}`) : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              disabled={isFormLocked}
                            />
                          </Accordion>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {IS_FRENCH_SCHOOL ? (
                    <>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <Select
                            id="schoolCountry"
                            name="schoolCountry"
                            label={t('scholarshipInformation.schoolCountry')}
                            placeholder={t('scholarshipInformation.chooseCountry')}
                            value={values.schoolCountry}
                            options={getCountriesOptions()}
                            onChange={handleSelectChange}
                            menuPortalTarget={document.body}
                            isDisabled={isFormLocked}
                            filterOption={filterCountryOptions}
                          />
                          <Accordion isToggled>
                            {/* If country selected is France, display a region select */}
                            { values.schoolCountry === 'FR'
                              ? (
                                <Select
                                  id="schoolState"
                                  name="schoolState"
                                  label={t('scholarshipInformation.schoolState')}
                                  placeholder={t('scholarshipInformation.chooseState')}
                                  value={values.schoolState}
                                  options={getStateOptions}
                                  onChange={handleSelectChange}
                                  menuPortalTarget={document.body}
                                  isDisabled={isFormLocked}
                                  isSearchable={false}
                                />
                              ) : (
                                <TextField
                                  label={t('scholarshipInformation.schoolState')}
                                  type="text"
                                  name="schoolState"
                                  id="schoolState"
                                  value={values.schoolState}
                                  error={errors.schoolState && touched.schoolState
                                    ? t(`form.errors.${errors.schoolState}`) : null
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                  disabled={isFormLocked}
                                />
                              )}
                            <Select
                              id="schoolOwnership"
                              name="schoolOwnership"
                              label={t('scholarshipInformation.schoolOwnership')}
                              placeholder={t('scholarshipInformation.chooseSchoolOwnership')}
                              value={values.schoolOwnership}
                              options={getSchoolOwnershipOptions}
                              onChange={handleSelectChange}
                              menuPortalTarget={document.body}
                              isDisabled={isFormLocked}
                              isSearchable={false}
                            />
                          </Accordion>
                          <Accordion isToggled={values.schoolCountry && values.schoolCountry !== 'FR'}>
                            <TextField
                              label={t('scholarshipInformation.schoolCity')}
                              type="text"
                              name="schoolCity"
                              id="schoolCity"
                              value={values.schoolCity}
                              error={errors.schoolCity && touched.schoolCity
                                ? t(`form.errors.${errors.schoolCity}`) : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              disabled={isFormLocked}
                            />
                          </Accordion>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10">
                          <FormBlock label={t('scholarshipInformation.previousGrade')}>
                            <div className={cn([style.radioList, style.customRadioList])}>
                              {FR_GRADES_PREV.map(grade => (
                                <RadioCustom
                                  key={grade.subLabel}
                                  id={`previousGrade-${grade.subLabel}-${grade.label}`}
                                  name="previousGrade"
                                  label={grade.label}
                                  subLabel={grade.subLabel}
                                  value={grade.subLabel}
                                  checked={values.previousGrade === grade.subLabel}
                                  onChange={handleChange}
                                  disabled={isFormLocked}
                                />
                              ))}
                            </div>
                          </FormBlock>
                          <Accordion isToggled={values.previousGrade === ''}>
                            <TextField
                              label={t('scholarshipInformation.otherGrade')}
                              type="text"
                              name="otherGrade"
                              id="otherGrade"
                              value={values.otherGrade}
                              error={errors.otherGrade && touched.otherGrade
                                ? t(`form.errors.${errors.otherGrade}`) : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              disabled={isFormLocked}
                            />
                          </Accordion>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <FormBlock label={t('scholarshipInformation.didRepeat')}>
                            <Radio
                              id="didRepeat-yes"
                              name="didRepeat"
                              value="true"
                              label={t('scholarshipInformation.yes')}
                              onChange={handleChange}
                              checked={values.didRepeat === 'true'}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="didRepeat-no"
                              name="didRepeat"
                              value="false"
                              label={t('scholarshipInformation.no')}
                              onChange={handleChange}
                              checked={values.didRepeat === 'false'}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <Accordion isToggled={values.didRepeat === 'true'}>
                            <TextField
                              label={t('scholarshipInformation.specifyClass')}
                              type="text"
                              name="didRepeatSpecify"
                              id="didRepeatSpecify"
                              value={values.didRepeatSpecify}
                              error={errors.didRepeatSpecify && touched.didRepeatSpecify
                                ? t(`form.errors.${errors.didRepeatSpecify}`) : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              disabled={isFormLocked}
                            />
                          </Accordion>

                          <FormBlock label={t('scholarshipInformation.difficultiesLearn')}>
                            <Radio
                              id="difficultiesLearn-yes"
                              name="difficultiesLearn"
                              value="true"
                              label={t('scholarshipInformation.yes')}
                              onChange={handleChange}
                              checked={values.difficultiesLearn === 'true' || values.difficultiesLearn === true}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="difficultiesLearn-no"
                              name="difficultiesLearn"
                              value="false"
                              label={t('scholarshipInformation.no')}
                              onChange={handleChange}
                              checked={values.difficultiesLearn === 'false' || values.difficultiesLearn === false}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <Accordion isToggled={values.difficultiesLearn === 'true'}>
                            <div className={cn([style.mt2, style.spacingRight])}>
                              <DropZone
                                onDrop={(droppedFile) => {
                                  postDocument(droppedFile[0], PSYCHO_EDUCATIONAL_REPORT);
                                }}
                                onClearFile={() => {
                                  handleDocumentClear(PSYCHO_EDUCATIONAL_REPORT);
                                }}
                                label={t('scholarshipInformation.psychoeducationalReport')}
                                completed={documents[PSYCHO_EDUCATIONAL_REPORT]}
                                hasFile={!isEmpty(documents[PSYCHO_EDUCATIONAL_REPORT])}
                                fileName={
                                  documents[PSYCHO_EDUCATIONAL_REPORT]
                                  && documents[PSYCHO_EDUCATIONAL_REPORT].name
                                }
                                file={documents[PSYCHO_EDUCATIONAL_REPORT]}
                                fileLink={documents[PSYCHO_EDUCATIONAL_REPORT] ? `${API_URL}/documents/${documents[PSYCHO_EDUCATIONAL_REPORT].id}` : null}
                                disabled={loadDocs}
                                onFileNameClick={handleFileNameClick}
                              />
                            </div>
                          </Accordion>

                          <FormBlock label={t('scholarshipInformation.specialArrangements')}>
                            <Radio
                              id="schoolSpecialArrangements-yes"
                              name="schoolSpecialArrangements"
                              value="true"
                              label={t('scholarshipInformation.yes')}
                              onChange={handleChange}
                              checked={values.schoolSpecialArrangements === 'true' || values.schoolSpecialArrangements === true}
                              disabled={isFormLocked}
                            />
                            <Radio
                              id="schoolSpecialArrangements-no"
                              name="schoolSpecialArrangements"
                              value="false"
                              label={t('scholarshipInformation.no')}
                              onChange={handleChange}
                              checked={values.schoolSpecialArrangements === 'false' || values.schoolSpecialArrangements === false}
                              disabled={isFormLocked}
                            />
                          </FormBlock>

                          {values.schoolSpecialArrangements === 'true' || values.schoolSpecialArrangements === true ? (
                            <p>{t('scholarshipInformation.specialArrangementsInformation')}</p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {IS_INTERNATIONAL_SCHOOL
                    ? (
                      <>
                        <h2 className={cn(['card-title', style.dropZoneLabel])}>{t('middleOffice.studentRecord')}</h2>
                        <div className={cn(['row', style.dropzoneRow])}>
                          <div
                            className={cn([
                              style.dropZoneWrapper,
                              'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                            ])}
                          >
                            <DropZone
                              onDrop={(droppedFile) => {
                                postDocument(droppedFile[0], REQUEST_FOR_STUDENT_RECORDS);
                              }}
                              onClearFile={() => {
                                handleDocumentClear(REQUEST_FOR_STUDENT_RECORDS);
                              }}
                              label={t('middleOffice.studentRecordRequest')}
                              completed={documents[REQUEST_FOR_STUDENT_RECORDS]}
                              hasFile={!isEmpty(documents[REQUEST_FOR_STUDENT_RECORDS])}
                              fileName={
                                documents[REQUEST_FOR_STUDENT_RECORDS]
                                && documents[REQUEST_FOR_STUDENT_RECORDS].name
                              }
                              file={documents[REQUEST_FOR_STUDENT_RECORDS]}
                              fileLink={documents[REQUEST_FOR_STUDENT_RECORDS] ? `${API_URL}/documents/${documents[REQUEST_FOR_STUDENT_RECORDS].id}` : null}
                              disabled={loadDocs}
                              onFileNameClick={handleFileNameClick}
                              template={`${API_URL_BASE}public/request_for_student_records.docx`}
                            />
                          </div>
                        </div>
                      </>
                    ) : null
                  }
                </Card>

                {IS_INTERNATIONAL_SCHOOL ? (
                  <Card className="card-margin">
                    <h2 className="card-title">{t('scholarshipInformation.schoolTeachedLanguages')}</h2>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 md-10">
                        <Select
                          className={style.selectCountry}
                          id="languageTeachedMin1"
                          name="languageTeachedMin1"
                          label="2021-2022"
                          placeholder={t('scholarshipInformation.chooseOtherLanguage')}
                          value={values.languageTeachedMin1}
                          options={getLanguagesOptions}
                          onChange={handleSelectChange}
                          menuPortalTarget={document.body}
                          isDisabled={isFormLocked}
                          filterOption={filterCountryOptions}
                        />
                        <Accordion isToggled={values.languageTeachedMin1 === 'other'}>
                          <TextField
                            label={t('generalInformation.specify')}
                            type="text"
                            name="languageTeachedMin1Other"
                            id="languageTeachedMin1Other"
                            value={values.languageTeachedMin1Other}
                            error={
                              (
                                errors.languageTeachedMin1Other
                                && touched.languageTeachedMin1Other
                              )
                                ? t(`form.errors.${errors.languageTeachedMin1Other}`)
                                : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </Accordion>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 md-10">
                        <Select
                          className={style.selectCountry}
                          id="languageTeachedMin2"
                          name="languageTeachedMin2"
                          label="2020-2021"
                          placeholder={t('scholarshipInformation.chooseOtherLanguage')}
                          value={values.languageTeachedMin2}
                          options={getLanguagesOptions}
                          onChange={handleSelectChange}
                          menuPortalTarget={document.body}
                          isDisabled={isFormLocked}
                          filterOption={filterCountryOptions}
                        />
                        <Accordion isToggled={values.languageTeachedMin2 === 'other'}>
                          <TextField
                            label={t('generalInformation.specify')}
                            type="text"
                            name="languageTeachedMin2Other"
                            id="languageTeachedMin2Other"
                            value={values.languageTeachedMin2Other}
                            error={
                              (
                                errors.languageTeachedMin2Other
                                && touched.languageTeachedMin2Other
                              )
                                ? t(`form.errors.${errors.languageTeachedMin2Other}`)
                                : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </Accordion>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 md-10">
                        <Select
                          className={style.selectCountry}
                          id="languageTeachedMin3"
                          name="languageTeachedMin3"
                          label="2019-2020"
                          placeholder={t('scholarshipInformation.chooseOtherLanguage')}
                          value={values.languageTeachedMin3}
                          options={getLanguagesOptions}
                          onChange={handleSelectChange}
                          menuPortalTarget={document.body}
                          isDisabled={isFormLocked}
                          filterOption={filterCountryOptions}
                        />
                        <Accordion isToggled={values.languageTeachedMin3 === 'other'}>
                          <TextField
                            label={t('generalInformation.specify')}
                            type="text"
                            name="languageTeachedMin3Other"
                            id="languageTeachedMin3Other"
                            value={values.languageTeachedMin3Other}
                            error={
                              (
                                errors.languageTeachedMin3Other
                                && touched.languageTeachedMin3Other
                              )
                                ? t(`form.errors.${errors.languageTeachedMin3Other}`)
                                : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </Accordion>
                      </div>
                    </div>
                  </Card>
                ) : null}

                {(values.askingGrade === '2' || values.askingGrade === 'T')
                  && IS_FRENCH_SCHOOL
                  ? (
                    <Card className="card-margin">
                      <h2 className="card-title">{t('scholarshipInformation.gradesAttained')}</h2>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          {values.askingGrade === '2'
                            ? (
                              <FormBlock noMargin label={t('scholarshipInformation.brevetScore')}>
                                <Counter
                                  onPlusClick={grade => setFieldValue('brevetScore', grade)}
                                  onMinusClick={grade => setFieldValue('brevetScore', grade)}
                                  defaultNumber={values.brevetScore}
                                  min={0}
                                  max={20}
                                  disabled={isFormLocked}
                                />
                              </FormBlock>
                            )
                            : null}
                          {IS_INTERNATIONAL_SCHOOL
                            ? (
                              <FormBlock noMargin label={t('scholarshipInformation.gpa')}>
                                <Counter
                                  onPlusClick={grade => setFieldValue('gpa', grade)}
                                  onMinusClick={grade => setFieldValue('gpa', grade)}
                                  defaultNumber={values.gpa}
                                  step={0.1}
                                  min={0}
                                  max={4}
                                  disabled={isFormLocked}
                                />
                              </FormBlock>
                            )
                            : null}
                          {values.askingGrade === 'T'
                            ? (
                              <Fragment>
                                <FormBlock noMargin label={t('scholarshipInformation.writtenFrenchGrade')}>
                                  <Counter
                                    onPlusClick={grade => setFieldValue('writtenFrenchGrade', grade)}
                                    onMinusClick={grade => setFieldValue('writtenFrenchGrade', grade)}
                                    defaultNumber={values.writtenFrenchGrade}
                                    min={0}
                                    max={20}
                                    disabled={isFormLocked}
                                  />
                                </FormBlock>
                                <FormBlock label={t('scholarshipInformation.oralFrenchGrade')}>
                                  <Counter
                                    onPlusClick={grade => setFieldValue('oralFrenchGrade', grade)}
                                    onMinusClick={grade => setFieldValue('oralFrenchGrade', grade)}
                                    defaultNumber={values.oralFrenchGrade}
                                    min={0}
                                    max={20}
                                    disabled={isFormLocked}
                                  />
                                </FormBlock>
                                <FormBlock label={t('scholarshipInformation.sciencesFrenchGrade')}>
                                  <Counter
                                    onPlusClick={grade => setFieldValue('sciencesFrenchGrade', grade)}
                                    onMinusClick={grade => setFieldValue('sciencesFrenchGrade', grade)}
                                    defaultNumber={values.sciencesFrenchGrade}
                                    min={0}
                                    max={20}
                                    disabled={isFormLocked}
                                  />
                                </FormBlock>
                                <FormBlock label={t('scholarshipInformation.tpeFrenchGrade')}>
                                  <Counter
                                    onPlusClick={grade => setFieldValue('tpeFrenchGrade', grade)}
                                    onMinusClick={grade => setFieldValue('tpeFrenchGrade', grade)}
                                    defaultNumber={values.tpeFrenchGrade}
                                    min={0}
                                    max={20}
                                    disabled={isFormLocked}
                                  />
                                </FormBlock>
                              </Fragment>
                            )
                            : null
                          }
                        </div>
                      </div>
                    </Card>
                  ) : null}

                {IS_INTERNATIONAL_SCHOOL ? (
                  <Card className="card-margin">
                    <h2 className="card-title">{t('scholarshipInformation.otherInfo')}</h2>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10">
                        <FormBlock noMargin label={t('scholarshipInformation.ageStartedSchool')}>
                          <Counter
                            onPlusClick={ageReturned => setFieldValue('ageStartedSchool', ageReturned)}
                            onMinusClick={ageReturned => setFieldValue('ageStartedSchool', ageReturned)}
                            defaultNumber={values.ageStartedSchool}
                            min={3}
                            max={10}
                            disabled={isFormLocked}
                          />
                        </FormBlock>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 md-10">
                        <FormBlock label={t('scholarshipInformation.skippedGrade')}>
                          <Radio
                            id="skippedGrade-yes"
                            name="skippedGrade"
                            value="true"
                            label={t('scholarshipInformation.yes')}
                            onChange={handleChange}
                            checked={values.skippedGrade === 'true'}
                            disabled={isFormLocked}
                          />
                          <Radio
                            id="skippedGrade-no"
                            name="skippedGrade"
                            value="false"
                            label={t('scholarshipInformation.no')}
                            onChange={handleChange}
                            checked={values.skippedGrade === 'false'}
                            disabled={isFormLocked}
                          />
                        </FormBlock>
                        <Accordion isToggled={values.skippedGrade === 'true'}>
                          <TextField
                            label={t('scholarshipInformation.specify')}
                            type="text"
                            name="skippedGradeSpec"
                            id="skippedGradeSpec"
                            value={values.skippedGradeSpec}
                            error={errors.skippedGradeSpec && touched.skippedGradeSpec
                              ? t(`form.errors.${errors.skippedGradeSpec}`) : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </Accordion>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10">
                        <FormBlock label={t('scholarshipInformation.yearsOfSchoolLeft')}>
                          <Counter
                            onPlusClick={ageReturned => setFieldValue('yearsOfSchoolLeft', ageReturned)}
                            onMinusClick={ageReturned => setFieldValue('yearsOfSchoolLeft', ageReturned)}
                            defaultNumber={values.yearsOfSchoolLeft}
                            min={1}
                            max={10}
                            disabled={isFormLocked}
                          />
                        </FormBlock>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 md-10">
                        <FormBlock label={t('scholarshipInformation.expelledFromSchool')}>
                          <Radio
                            id="expelledFromSchool-yes"
                            name="expelledFromSchool"
                            value="true"
                            label={t('scholarshipInformation.yes')}
                            onChange={handleChange}
                            checked={values.expelledFromSchool === 'true'}
                            disabled={isFormLocked}
                          />
                          <Radio
                            id="expelledFromSchool-no"
                            name="expelledFromSchool"
                            value="false"
                            label={t('scholarshipInformation.no')}
                            onChange={handleChange}
                            checked={values.expelledFromSchool === 'false'}
                            disabled={isFormLocked}
                          />
                        </FormBlock>
                        <Accordion isToggled={values.expelledFromSchool === 'true'}>
                          <TextField
                            label={t('scholarshipInformation.specifyExpelled')}
                            type="text"
                            name="expelledFromSchoolSpec"
                            id="expelledFromSchoolSpec"
                            value={values.expelledFromSchoolSpec}
                            error={
                              (
                                errors.expelledFromSchoolSpec
                                && touched.expelledFromSchoolSpec
                              )
                                ? t(`form.errors.${errors.expelledFromSchoolSpec}`)
                                : null
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </Accordion>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 md-10">
                        <FormBlock label={t('scholarshipInformation.difficultiesLearn')}>
                          <Radio
                            id="difficultiesLearn-yes"
                            name="difficultiesLearn"
                            value="true"
                            label={t('scholarshipInformation.yes')}
                            onChange={handleChange}
                            checked={values.difficultiesLearn === 'true'}
                            disabled={isFormLocked}
                          />
                          <Radio
                            id="difficultiesLearn-no"
                            name="difficultiesLearn"
                            value="false"
                            label={t('scholarshipInformation.no')}
                            onChange={handleChange}
                            checked={values.difficultiesLearn === 'false'}
                            disabled={isFormLocked}
                          />
                        </FormBlock>

                        <Accordion isToggled={values.difficultiesLearn === 'true'}>
                          <div className={cn([style.mt2, style.spacingRight])}>
                            <DropZone
                              onDrop={(droppedFile) => {
                                postDocument(droppedFile[0], PSYCHO_EDUCATIONAL_REPORT);
                              }}
                              onClearFile={() => {
                                handleDocumentClear(PSYCHO_EDUCATIONAL_REPORT);
                              }}
                              label={t('scholarshipInformation.psychoeducationalReport')}
                              completed={documents[PSYCHO_EDUCATIONAL_REPORT]}
                              hasFile={!isEmpty(documents[PSYCHO_EDUCATIONAL_REPORT])}
                              fileName={
                                documents[PSYCHO_EDUCATIONAL_REPORT]
                                && documents[PSYCHO_EDUCATIONAL_REPORT].name
                              }
                              file={documents[PSYCHO_EDUCATIONAL_REPORT]}
                              fileLink={documents[PSYCHO_EDUCATIONAL_REPORT] ? `${API_URL}/documents/${documents[PSYCHO_EDUCATIONAL_REPORT].id}` : null}
                              disabled={loadDocs}
                              onFileNameClick={handleFileNameClick}
                            />
                          </div>
                        </Accordion>

                        <FormBlock label={t('scholarshipInformation.specialArrangements')}>
                          <Radio
                            id="schoolSpecialArrangements-yes"
                            name="schoolSpecialArrangements"
                            value="true"
                            label={t('scholarshipInformation.yes')}
                            onChange={handleChange}
                            checked={values.schoolSpecialArrangements === 'true' || values.schoolSpecialArrangements === true}
                            disabled={isFormLocked}
                          />
                          <Radio
                            id="schoolSpecialArrangements-no"
                            name="schoolSpecialArrangements"
                            value="false"
                            label={t('scholarshipInformation.no')}
                            onChange={handleChange}
                            checked={values.schoolSpecialArrangements === 'false' || values.schoolSpecialArrangements === false}
                            disabled={isFormLocked}
                          />
                        </FormBlock>

                        {values.schoolSpecialArrangements === 'true' || values.schoolSpecialArrangements === true ? (
                          <p>{t('scholarshipInformation.specialArrangementsInformation')}</p>
                        ) : null}
                      </div>
                    </div>
                  </Card>
                ) : null}

                <Card className="card-margin">
                  <h2 className="card-title">
                    {t('scholarshipInformation.certificatesAndExams')}
                  </h2>

                  {IS_FRENCH_SCHOOL ? (
                    <>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <FormBlock noMargin label={t('scholarshipInformation.roadSafetyCertif')}>
                            <Checkbox
                              id="roadSafetyCertif-ASSR1"
                              name="roadSafetyCertif1"
                              label="ASSR1"
                              onChange={handleChange}
                              checked={values.roadSafetyCertif1}
                              disabled={isFormLocked}
                            />
                            <Checkbox
                              id="roadSafetyCertif-ASSR2"
                              name="roadSafetyCertif2"
                              label="ASSR2"
                              onChange={handleChange}
                              checked={values.roadSafetyCertif2}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-lg-10 md-10">
                          <FormBlock label={t('scholarshipInformation.examsAndScores')}>
                            <Checkbox
                              id="examsAndScores-TOEFL"
                              name="hasToefl"
                              label="TOEFL"
                              onChange={handleChange}
                              checked={values.hasToefl}
                              disabled={isFormLocked}
                            />
                            <Checkbox
                              id="examsAndScores-SAT"
                              name="hasSat"
                              label="SAT"
                              onChange={handleChange}
                              checked={values.hasSat}
                              disabled={isFormLocked}
                            />
                          </FormBlock>
                          <Accordion isToggled={values.hasToefl}>
                            <TextField
                              label={t('scholarshipInformation.toeflGrade')}
                              type="text"
                              name="toeflGrade"
                              id="toeflGrade"
                              value={values.toeflGrade}
                              error={errors.toeflGrade && touched.toeflGrade
                                ? t(`form.errors.${errors.toeflGrade}`) : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              disabled={isFormLocked}
                            />
                          </Accordion>
                          <Accordion isToggled={values.hasSat}>
                            <TextField
                              label={t('scholarshipInformation.satGrade')}
                              type="text"
                              name="satGrade"
                              id="satGrade"
                              value={values.satGrade}
                              error={errors.satGrade && touched.satGrade
                                ? t(`form.errors.${errors.satGrade}`) : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              disabled={isFormLocked}
                            />
                          </Accordion>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className={cn(['row', style.topMargin])}>
                    <div className="col-xs-12 col-lg-10 md-10">
                      <Select
                        id="projectAfterBac"
                        name="projectAfterBac"
                        label={t('scholarshipInformation.projectAfterBac')}
                        placeholder={t('scholarshipInformation.chooseProject')}
                        value={values.projectAfterBac}
                        options={PROJECT_AFTER_SCHOOL.map(option => ({
                          label: t(`global.projectAfterSchool.${option}`),
                          value: option,
                        }))}
                        onChange={handleSelectChange}
                        menuPortalTarget={document.body}
                        isDisabled={isFormLocked}
                      />
                      <Accordion isToggled={values.projectAfterBac === 'other'}>
                        <TextField
                          label={t('scholarshipInformation.specify')}
                          type="text"
                          name="projectAfterBacSpec"
                          id="projectAfterBacSpec"
                          value={values.projectAfterBacSpec}
                          error={errors.projectAfterBacSpec && touched.projectAfterBacSpec
                            ? t(`form.errors.${errors.projectAfterBacSpec}`) : null
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          disabled={isFormLocked}
                        />
                      </Accordion>
                    </div>
                  </div>
                </Card>
              </>
            ) : null}

            {IS_CENTER ? (
              <>
                <Card className="card-margin">
                  <h2 className="card-title">{t('scholarshipInformation.scholarship')}</h2>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <FormBlock label={t('scholarshipInformation.followProgram')}>
                        <Radio
                          id="followsSchoolProgram-yes"
                          name="followsSchoolProgram"
                          value="true"
                          checked={values.followsSchoolProgram === 'true'}
                          label={t('scholarshipInformation.yes')}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                        <Radio
                          id="followsSchoolProgram-no"
                          name="followsSchoolProgram"
                          value="false"
                          checked={values.followsSchoolProgram === 'false'}
                          label={t('scholarshipInformation.no')}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                      </FormBlock>
                      <Accordion
                        isToggled={values.followsSchoolProgram === 'true'}
                      >
                        <TextField
                          label={t('scholarshipInformation.whichProgram')}
                          type="text"
                          name="whichSchoolProgram"
                          id="whichSchoolProgram"
                          value={values.whichSchoolProgram}
                          error={errors.whichSchoolProgram && touched.whichSchoolProgram
                            ? t(`form.errors.${errors.whichSchoolProgram}`) : null
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          disabled={isFormLocked}
                        />
                      </Accordion>
                    </div>
                  </div>
                </Card>
                <Card className="card-margin">
                  <h2 className="card-title">{t('scholarshipInformation.languageSkills')}</h2>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <Range
                        className={style.inputMargin}
                        id="writtenFrenchLevel"
                        name="writtenFrenchLevel"
                        label={t('scholarshipInformation.writtenFrenchLevel')}
                        min="0"
                        max="4"
                        step="1"
                        defaultValue={values.writtenFrenchLevel || '0'}
                        value={values.writtenFrenchLevel || '0'}
                        showSteps
                        rangeLabels={[
                          t('scholarshipInformation.beginner'),
                          t('scholarshipInformation.fluent'),
                        ]}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <Range
                        className={style.inputMargin}
                        id="spokenFrenchLevel"
                        name="spokenFrenchLevel"
                        label={t('scholarshipInformation.spokenFrenchLevel')}
                        min="0"
                        max="4"
                        step="1"
                        defaultValue={values.spokenFrenchLevel || '0'}
                        value={values.spokenFrenchLevel || '0'}
                        showSteps
                        rangeLabels={[
                          t('scholarshipInformation.beginner'),
                          t('scholarshipInformation.fluent'),
                        ]}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <Range
                        className={style.inputMargin}
                        id="writtenEnglishLevel"
                        name="writtenEnglishLevel"
                        label={t('scholarshipInformation.writtenEnglishLevel')}
                        min="0"
                        max="4"
                        step="1"
                        defaultValue={values.writtenEnglishLevel || '0'}
                        value={values.writtenEnglishLevel || '0'}
                        showSteps
                        rangeLabels={[
                          t('scholarshipInformation.beginner'),
                          t('scholarshipInformation.fluent'),
                        ]}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-10 md-10">
                      <Range
                        className={style.inputMargin}
                        id="spokenEnglishLevel"
                        name="spokenEnglishLevel"
                        label={t('scholarshipInformation.spokenEnglishLevel')}
                        min="0"
                        max="4"
                        step="1"
                        defaultValue={values.spokenEnglishLevel || '0'}
                        value={values.spokenEnglishLevel || '0'}
                        showSteps
                        rangeLabels={[
                          t('scholarshipInformation.beginner'),
                          t('scholarshipInformation.fluent'),
                        ]}
                        onChange={handleChange}
                        disabled={isFormLocked}
                      />
                    </div>

                  </div>
                </Card>
              </>
            ) : null}


            {/* Submit block */}

            <div className={style.submitBlock}>
              {formError || isFormLocked ? (
                <div>{formError || isFormLocked}</div>
              ) : (
                <AutoSave
                  formId={match.params.id}
                  values={values}
                  isSaving={submitLoading}
                  onSave={updateStudentForm}
                  error={submitError}
                />
              )}
              <Button
                className={style.submitForm}
                type="button"
                color="green"
                label={
                  submitted || isFormLocked
                    ? t('ratesInformation.submitApplicationSuccess')
                    : t('ratesInformation.submitApplication')
                }
                onClick={submitForm}
                disabled={!allowToSubmit(progress) || submitted || isFormLocked}
              />
              <Button
                className={style.submit}
                type="button"
                label={t('generalInformation.next')}
                onClick={() => saveForm(values)}
                disabled={submitLoading}
              />
            </div>
          </form>
        )
      }
    </WithSide>
  );
};

ScholarshipInformation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape().isRequired,
};

export default ScholarshipInformation;
