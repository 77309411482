import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

// Components
import TextField from '../../atoms/TextField';
import Button from '../../atoms/Button';

// style
import style from './EditPLayerForm.module.css';

const EditPlayerForm = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);

  const handlePictureChange = (e, setFieldValue) => {
    setFileName(e.target.files[0].name);
    setFieldValue('thumbnail', e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImageUpload(reader.result);
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, fileName)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <div className="row">
            <div className="col-xs-12">
              <TextField
                label={t('editPlayerForm.firstname')}
                type="text"
                name="firstname"
                id="firstname"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <TextField
                label={t('editPlayerForm.lastname')}
                type="text"
                name="lastname"
                id="lastname"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
              <TextField
                label={t('editPlayerForm.birthdate')}
                type="text"
                name="birthdate"
                id="birthdate"
                value={values.birthdate}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
            </div>
            <div className="col-xs-12">
              {imageUpload && (
              <div
                className={style.preview}
                style={{ backgroundImage: `url(${imageUpload})` }}
                alt="player thumbnail"
              />
              )}
              <div className={style.thumbnailInput}>
                <label htmlFor="thumbnail" className={style.thumbnailBtn}>
                  {t('editPlayerForm.choosePicture')}
                </label>
                <i className={style.thumbnailInfo}>
                  {t('editPlayerForm.acceptedFiles')}
                    : jpg, jpeg, png
                </i>
                <input
                  id="thumbnail"
                  type="file"
                  accept="image/x-png,image/jpg,image/jpeg"
                  onChange={e => handlePictureChange(e, setFieldValue)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12" style={{ textAlign: 'center' }}>
              <Button label={t('editPlayerForm.confirm')} type="submit" style={{ marginTop: '2rem' }} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditPlayerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

EditPlayerForm.defaultProps = {
  initialValues: {
    playerName: '',
  },
};

export default EditPlayerForm;
