import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  isEmpty, lowerCase, pickBy,
} from 'lodash';
import i18nCountries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesFR from 'i18n-iso-countries/langs/fr.json';

// Components
import MapsAutocomplete from '../../atoms/MapsAutocomplete';
import FormBlock from '../../atoms/FormBlock';
import Checkbox from '../../atoms/Checkbox';
import TextField from '../../atoms/TextField';
import Select from '../../atoms/Select';

// Constants
import filterCountryOptions from '../../../utils/filterCountryOptions';
import cn from '../../../utils/cn';
import i18n from '../../../i18n';
import flagsStyle from '../../../constants/flags.module.css';
import { COUNTRY_CODES } from '../../../constants/constants';

const ADDRESS_TYPES = {
  GOOGLE: 'google',
  DEFAULT: 'default',
};

const defaultAddressDetails = {
  street_number: '',
  route: '',
  country: '',
  postal_code: '',
  city: '',
  addressType: ADDRESS_TYPES.GOOGLE,
};

export const AddressField = ({
  fieldName,
  onAddressChange,
  onAddressDetailsChange,
  initialAddress,
  initialAddressDetails,
  isFormLocked,
}) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);

  const [addressType, setAddressType] = useState(
    (initialAddressDetails && initialAddressDetails.addressType)
      ? initialAddressDetails.addressType
      : ADDRESS_TYPES.GOOGLE,
  );
  const [address, setAddress] = useState(initialAddress);
  const [addressDetails, setAddressDetails] = useState(initialAddressDetails || { ...defaultAddressDetails, route: initialAddress || '' });

  i18nCountries.registerLocale(i18n.language === 'en' ? countriesEN : countriesFR);
  const getCountriesOptions = COUNTRY_CODES.map(({ code, name }) => (
    {
      label: (
        <span>
          <i className={cn([flagsStyle['flag-icon'], flagsStyle[`flag-icon-${lowerCase(code)}`]])} />
          &nbsp;
          {i18nCountries.getName(code, i18n.language)}
        </span>
      ),
      value: name,
    }
  ));

  const handleAdressTypeCheck = () => {
    const value = (addressType === ADDRESS_TYPES.GOOGLE)
      ? ADDRESS_TYPES.DEFAULT
      : ADDRESS_TYPES.GOOGLE;

    setAddressType(value);
    let newAddressDetails = { ...addressDetails, addressType: value };
    if (value === ADDRESS_TYPES.DEFAULT) {
      newAddressDetails = pickBy(
        newAddressDetails,
        (v, key) => Object.keys(defaultAddressDetails).find(k => k === key) !== undefined,
      );
    }
    onAddressDetailsChange(newAddressDetails);
    setAddressDetails(newAddressDetails);
  };

  const handleAddressDetailFieldChange = (field, value) => {
    if (!value || value === '') {
      setAddress('');
      onAddressChange('');
    }
    setAddressDetails({ ...addressDetails, [field]: value, addressType: ADDRESS_TYPES.DEFAULT });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    onAddressDetailsChange(addressDetails);
    if (
      addressDetails.country
      && addressDetails.street_number
      && addressDetails.route
      && addressDetails.postal_code
      && addressDetails.city
    ) {
      const addr = `${addressDetails.street_number} ${addressDetails.route}, ${addressDetails.postal_code} ${addressDetails.city}, ${addressDetails.country}`;
      setAddress(addr);
      onAddressChange(addr);
    }
  }, [addressDetails]);

  return (
    <div>
      <FormBlock>
        <Checkbox
          id={`${fieldName}_checkbox`}
          label={t('form.addressField.notFound')}
          checked={addressType === ADDRESS_TYPES.DEFAULT}
          onChange={handleAdressTypeCheck}
          disabled={isFormLocked}
        />
      </FormBlock>
      {
        addressType === ADDRESS_TYPES.GOOGLE
          ? (
            <MapsAutocomplete
              label={t('generalInformation.address')}
              name={fieldName}
              id={fieldName}
              onSelect={(formated, details) => {
                setAddress(formated);
                setAddressDetails({ ...details, addressType: ADDRESS_TYPES.GOOGLE });
                onAddressChange(formated);
                onAddressDetailsChange({ ...details, addressType: ADDRESS_TYPES.GOOGLE });
              }}
              onChange={(e) => {
                if (!e.target.value) onAddressDetailsChange(defaultAddressDetails);
                setAddress(e.target.value);
              }}

              value={address}
              error={(
                isEmpty(addressDetails)
              )
                ? t('generalInformation.selectAddressInList') : null
        }
              required
              disabled={isFormLocked}
            />
          ) : null}
      { addressType === ADDRESS_TYPES.DEFAULT ? (
        <div>
          <TextField
            label={t('generalInformation.streetNumber')}
            type="text"
            name="streetNumber"
            value={addressDetails.street_number}
            onChange={e => handleAddressDetailFieldChange('street_number', e.target.value)}
            onBlur={e => handleAddressDetailFieldChange('street_number', e.target.value)}
            required
            disabled={isFormLocked}
          />
          <TextField
            label={t('generalInformation.streetName')}
            type="text"
            name="route"
            value={addressDetails.route}
            onChange={e => handleAddressDetailFieldChange('route', e.target.value)}
            onBlur={e => handleAddressDetailFieldChange('route', e.target.value)}
            required
            disabled={isFormLocked}
          />
          <TextField
            label={t('generalInformation.zip')}
            type="text"
            name="postalCode"
            value={addressDetails.postal_code}
            onChange={e => handleAddressDetailFieldChange('postal_code', e.target.value)}
            onBlur={e => handleAddressDetailFieldChange('postal_code', e.target.value)}
            required
            disabled={isFormLocked}
          />
          <TextField
            label={t('generalInformation.city')}
            type="text"
            name="city"
            value={addressDetails.city}
            onChange={e => handleAddressDetailFieldChange('city', e.target.value)}
            onBlur={e => handleAddressDetailFieldChange('city', e.target.value)}
            required
            disabled={isFormLocked}
          />
          <Select
            label={t('generalInformation.country')}
            name="country"
            options={getCountriesOptions}
            value={addressDetails.country}
            onChange={e => handleAddressDetailFieldChange('country', e.target.value)}
            isDisabled={isFormLocked}
            filterOption={filterCountryOptions}
            menuPortalTarget={document.body}
          />
        </div>
      ) : null }
    </div>
  );
};

AddressField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onAddressDetailsChange: PropTypes.func.isRequired,
  initialAddress: PropTypes.string,
  initialAddressDetails: PropTypes.shape(),
  isFormLocked: PropTypes.bool.isRequired,
};

AddressField.defaultProps = {
  initialAddress: null,
  initialAddressDetails: null,
};

export default AddressField;
