import React from 'react';
import PropTypes from 'prop-types';

// Utils & misc
import cn from '../../../utils/cn';

// Style
import style from './StatusMessage.module.css';

export const StatusMessage = ({ status, date, message }) => (
  <li className={style.wrapper}>
    <div className="row top-xs">
      <div className="col-xs-1">
        <div className={cn([style.status, style[status]])} />
      </div>
      <div className="col-xs-3">
        <span className={style.date}>{date}</span>
      </div>
      <div className="col-xs-8">
        <p className={style.message}>{message}</p>
      </div>
    </div>
  </li>
);

StatusMessage.propTypes = {
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default StatusMessage;
