import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils & misc
import cn from '../../../utils/cn';

// Style
import style from './Radio.module.css';

export const Radio = ({
  label, id, className, ...propsLeft
}) => {
  const [absolute, setAbsolute] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setAbsolute(style.absolute);
    }, 100);
    return () => {};
  }, []);

  return (
    <label className={cn([style.group, className])} htmlFor={id}>
      <input className={style.input} id={id} type="radio" {...propsLeft} />
      <span className={cn([style.label, absolute])}>{label}</span>
    </label>
  );
};

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Radio.defaultProps = {
  className: null,
};

export default Radio;
