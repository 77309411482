import {
  MANDATORY_FIELDS,
  FORM_INITIAL_VALUES as FIV,
  ID_CARD_BACK_ID,
  ID_CARD_FRONT_ID,
  STUDENT_ID_CARD_BACK,
  STUDENT_ID_CARD_FRONT,
} from '../constants/constants';

// TODO optimize some algos and memoize calls to avoid too much processing

// Rules General Information

const visaRule = (values) => {
  let ruleMandatories = [];

  if (values.needVisa === 'true') {
    ruleMandatories = ruleMandatories.concat([
      'deliveryCountry',
      'passportNumber',
      'passportExpiryDate',
    ]);
  }

  return ruleMandatories;
};

const parentSeparatedRule = (values) => {
  let ruleMandatories = [];

  if (values.parentsSeparated === 'true' && !values.provideParent2Info) {
    ruleMandatories = ruleMandatories.concat([
      'parent2Lastname',
      'parent2Firstname',
      'parent2Employment',
      'parent2Phone',
      'parent2Email',
      'parent2Address',
      'guardian',
    ]);
  }

  if (!values.provideParent2Info) {
    ruleMandatories = ruleMandatories.concat([
      'parent2Lastname',
      'parent2Firstname',
      'parent2Employment',
      'parent2Phone',
      'parent2Email',
      'parent2Address',
      'parent2PhoneDetails',
    ]);
  }

  if (values.parentsSeparated === 'true' && values.guardian === 'other') {
    ruleMandatories = ruleMandatories.concat([
      'guardianGender',
      'guardianLastname',
      'guardianFirstname',
      'guardianPhone',
      'guardianEmail',
      'guardianAddress',
    ]);
  }

  return ruleMandatories;
};

const sendMailRule = (values) => {
  let ruleMandatories = [];
  const hasAtLeastOneMailRecipient = (
    values.sendMailParent1
    || values.sendMailParent2
    || values.sendMailOther
  );
  const hasAtLeastOneFinancialRecipient = (
    values.sendFinancialMailParent1
    || values.sendFinancialMailParent2
    || values.sendFinancialMailOther
  );

  if (!hasAtLeastOneMailRecipient) {
    ruleMandatories = ruleMandatories.concat([
      'sendMailParent1',
      'sendMailParent2',
      'sendMailOther',
    ]);
  }

  if (!hasAtLeastOneFinancialRecipient) {
    ruleMandatories = ruleMandatories.concat([
      'sendFinancialMailParent1',
      'sendFinancialMailParent2',
      'sendFinancialMailOther',
    ]);
  }

  return ruleMandatories;
};

const generalInformationMandatories = values => [
  ...MANDATORY_FIELDS.generalInformation,
  ...parentSeparatedRule(values),
  ...sendMailRule(values),
  ...visaRule(values),
];

// Rules Scholarship information
// TODO no verifications are made for the international from

const otherLanguageRule = (values) => {
  let ruleMandatories = [];

  if (values.otherLanguage === 'other') {
    ruleMandatories = ruleMandatories.concat([
      'otherLanguageName',
    ]);
  }
  return ruleMandatories;
};

const projectAfterSchoolRule = (values) => {
  let ruleMandatories = [];

  if (values.projectAfterBac === 'other') {
    ruleMandatories = ruleMandatories.concat([
      'projectAfterBacSpec',
    ]);
  }
  return ruleMandatories;
};

const specialitiesRule = (values) => {
  let ruleMandatories = [];

  if (values.askingGrade === '1') {
    ruleMandatories = ruleMandatories.concat([
      'gradeSpecialities',
    ]);
  }
  return ruleMandatories;
};

const schoolProgramRule = (values) => {
  let ruleMandatories = [];

  if (values.followsSchoolProgram === 'true') {
    ruleMandatories = ruleMandatories.concat([
      'whichSchoolProgram',
    ]);
  }
  return ruleMandatories;
};

const schoolProTeamRule = (values) => {
  let ruleMandatories = [];

  if (values.followsOtherOnlineProgram === 'true') {
    ruleMandatories = ruleMandatories.concat(['whichOnlineProgram']);
  }
  return ruleMandatories;
};

const sportProgramRule = (values) => {
  let ruleMandatories = [];

  if (values.followSportsProgram === 'true') {
    ruleMandatories = ruleMandatories.concat([
      'sport',
      'trainingHours',
      'doesCompetition',
    ]);
    if (values.sport === 'other') {
      ruleMandatories = ruleMandatories.concat(['otherSport']);
    }
    if (values.sport === 'golf') {
      ruleMandatories = ruleMandatories.concat(['golfAcademyMember']);
    }
  }
  return ruleMandatories;
};

const scholarshipInformationMandatories1 = values => [
  ...MANDATORY_FIELDS.scholarshipInformationSchool1,
  ...otherLanguageRule(values),
  ...projectAfterSchoolRule(values),
  ...specialitiesRule(values),
];

const scholarshipInformationMandatories2 = values => [
  ...MANDATORY_FIELDS.scholarshipInformationSchool2,
  ...otherLanguageRule(values),
  ...projectAfterSchoolRule(values),
  ...specialitiesRule(values),
];

const scholarshipInformationCenterMandatories = values => [
  ...MANDATORY_FIELDS.scholarshipInformationCenter,
  ...schoolProgramRule(values),
];

const scholarshipInformationProTeamMandatories = values => [
  ...MANDATORY_FIELDS.scholarshipInformationProTeam,
  ...schoolProTeamRule(values),
];

// Compute progression and errors fields
const getScholarshipMandatories = (values, activeForm) => {
  if (!activeForm) return scholarshipInformationMandatories2(values);
  // Specific for centers
  if (activeForm.typeId === 2 || activeForm.typeId === 3 || activeForm.typeId === 7) {
    return scholarshipInformationCenterMandatories(values);
  }
  // Specific for pro team
  if (activeForm.typeId === 5) {
    return [...scholarshipInformationProTeamMandatories(values)];
  }
  // Default behavior for int & fr schools
  if (activeForm.schoolId === 1) return scholarshipInformationMandatories1(values);
  return scholarshipInformationMandatories2(values);
};

// Rules Medical information

const medicalPlseaseSpecifyRule = (values) => {
  let ruleMandatories = [];

  if (values.specificTreatment === 'true') {
    ruleMandatories = ruleMandatories.concat([
      'treatment',
      'prescriptions',
    ]);
  }
  return ruleMandatories;
};

const medicalInformationMandatories = values => [
  ...MANDATORY_FIELDS.medicalInformation,
  ...medicalPlseaseSpecifyRule(values),
];

// Rules Sports information

const sportsInformationMandatories = () => [
  ...MANDATORY_FIELDS.sportsInformation,
];

const sportsInformationSchoolProgramMandatories = values => [
  ...MANDATORY_FIELDS.sportsInformationSchoolProgram,
  ...sportProgramRule(values),
];

// Rules Administrative information

const administrativeInformationMandatories = () => [
  ...MANDATORY_FIELDS.administrativeInformation,
];

// Rules Rates information

const ratesInformationMandatories = () => [
  ...MANDATORY_FIELDS.ratesInformation,
];

const getMandatoryFields = (values, activeForm) => ({
  generalInformation: generalInformationMandatories(values),
  scholarshipInformation: getScholarshipMandatories(values, activeForm),
  medicalInformation: medicalInformationMandatories(values),
  sportsInformation: activeForm.typeId === 4
    ? sportsInformationSchoolProgramMandatories(values)
    : sportsInformationMandatories(values),
  administrativeInformation: administrativeInformationMandatories(values),
  ratesInformation: ratesInformationMandatories(values),
});

const checkValueFilled = (values, fieldToCheck, activeForm) => {
  // Allow to check 2 levels deep
  // Check on Undefined & Null because some answers can be false for
  // checkboxes
  const splitedFieldToCheck = fieldToCheck.split('.');

  // Checking for products selected
  if (splitedFieldToCheck[0] === 'tennisSchoolProgramSelected') {
    if (values[splitedFieldToCheck[0]] === true) {
      return values.selectedProducts !== undefined && values.selectedProducts !== null;
    }
  }

  // Checking from active form
  if (splitedFieldToCheck[0] === 'studentIdCardFront') {
    if (!activeForm || !activeForm.documents) return false;
    return !!activeForm.documents[STUDENT_ID_CARD_FRONT];
  }
  if (splitedFieldToCheck[0] === 'studentIdCardBack') {
    if (!activeForm || !activeForm.documents) return false;
    return !!activeForm.documents[STUDENT_ID_CARD_BACK];
  }
  if (splitedFieldToCheck[0] === 'parent1IdCardFront') {
    if (!activeForm || !activeForm.documents) return false;
    return !!activeForm.documents[ID_CARD_FRONT_ID];
  }
  if (splitedFieldToCheck[0] === 'parent1IdCardBack') {
    if (!activeForm || !activeForm.documents) return false;
    return !!activeForm.documents[ID_CARD_BACK_ID];
  }

  // Specific completion rules
  if (splitedFieldToCheck[0] === 'acceptTerms') {
    return (!!values[splitedFieldToCheck[0]]);
  }
  if (splitedFieldToCheck[0] === 'tennisSchoolProgramSelected') {
    return (!!values[splitedFieldToCheck[0]]);
  }

  // medical
  if (splitedFieldToCheck[0] === 'prescriptions') {
    if (!values[splitedFieldToCheck[0]] || values[splitedFieldToCheck[0]].length < 1) return false;
  }

  if (splitedFieldToCheck.length > 1) {
    return ((
      values[splitedFieldToCheck[0]][splitedFieldToCheck[1]] !== undefined
      && values[splitedFieldToCheck[0]][splitedFieldToCheck[1]] !== null
      && values[splitedFieldToCheck[0]][splitedFieldToCheck[1]] !== ''
    ) && true);
  }

  return ((
    values[fieldToCheck] !== undefined
    && values[fieldToCheck] !== null
    && values[fieldToCheck] !== ''
  )
    && true);
};

const computeCompletionAndErrors = (acc, formPart, mandatoryFields, values, activeForm) => {
  // Filters the mandatory values compared to the values filled from the form to
  // return the fields in error and the completion ratio
  const errors = {};
  const totalMandatoryField = mandatoryFields[formPart].length;
  const totalValuesFilled = mandatoryFields[formPart]
    .filter((requiredFormField) => {
      const isFilled = checkValueFilled(values, requiredFormField, activeForm);
      if (!isFilled) errors[requiredFormField] = 'required';

      return isFilled;
    })
    .length;
  const formCompletionRatio = totalValuesFilled / totalMandatoryField;

  return {
    progress: {
      ...acc.progress,
      [formPart]: formCompletionRatio,
    },
    errors: {
      ...acc.errors,
      ...errors,
    },
  };
};

const formatFormCompletionState = (values, activeForm) => {
  const initialValues = {
    ...FIV.generalInfo,
    ...FIV.scholarshipInfo,
    ...FIV.sportInfo,
    ...FIV.medicalInfo,
    ...FIV.administrativeInfo,
    ...FIV.ratesInfo,
  };
  const initFormCompletionState = { errors: {}, progress: {} };
  const mandatoryFields = getMandatoryFields({ ...initialValues, ...values }, activeForm);
  const formCompletionState = Object.keys(mandatoryFields).reduce(
    (acc, formPart) => computeCompletionAndErrors(
      acc,
      formPart,
      mandatoryFields,
      { ...initialValues, ...values },
      activeForm,
    ),
    initFormCompletionState,
  );

  return formCompletionState;
};

export const getCompletionFormValues = (values) => {
  const completionFormValues = {
    ...FIV.generalInfo,
    ...FIV.scholarshipInfo,
    ...FIV.sportInfo,
    ...FIV.medicalInfo,
    ...FIV.administrativeInfo,
    ...FIV.ratesInfo,
    ...values,
  };
  return completionFormValues;
};

export default formatFormCompletionState;
