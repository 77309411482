import { useState } from 'react';

/*
The argument passed to the launchTimeout function
is transfered to the callback call
*/
const useTimeoutQueue = (callback, delay) => {
  const [savedTimeout, setSavedTimeout] = useState(null);

  const launchTimeout = (param) => {
    if (savedTimeout) clearTimeout(savedTimeout);
    const timeout = setTimeout(() => {
      callback(param);
    }, delay);
    setSavedTimeout(timeout);
  };
  return launchTimeout;
};

export default useTimeoutQueue;
