import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { API_URL_BASE } from '../../../constants/constants';

// Components
import Picto from '../../atoms/Picto';

// Utils
import cn from '../../../utils/cn';

// Style
import style from './Footer.module.css';

export const Footer = ({ color, className }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* FOOTER MOBILE */}
      <footer className={cn([style.footerMobile, style[color], className])}>
        <div className={style.footerMobileElementSpacing}>
          <Picto icon="pin" className={style.picto} />

          <b>MOURATOGLOU Academy</b>
          {' '}
- Mouratoglou Resort, 3550 Route des Dolines, 06410 Biot
        </div>

        <div>
          <div className={style.footerMobileElementSpacing}>
            <Picto icon="mail" className={style.picto} />
            &nbsp;admissions@mouratoglou.com
            <br />
            <Picto icon="phone" className={style.picto} />
            &nbsp;+33(0) 483 881 430
          </div>

          <div className={style.footerMobileElementSpacing}>
            <a href={`${API_URL_BASE}public/terms.pdf`} className={style.terms}><b>{t('footer.terms')}</b></a>
            <br />
            <a href={`${API_URL_BASE}public/privacy.pdf`} className={style.terms}><b>{t('footer.privacy')}</b></a>
          </div>
        </div>
      </footer>

      {/* FOOTER DESKTOP */}
      <footer className={cn([style.footer, style[color], className])}>
        <span className={cn([style.textBlock, style.textAddress])}>
          <Picto icon="pin" className={style.picto} />

          <b>MOURATOGLOU Academy</b>
          {' '}
- Mouratoglou Resort, 3550 Route des Dolines, 06410 Biot
        </span>
        <div className={style.contactAndTerms}>
          <div className={style.contactWrapper}>
            <span className={style.textBlock}>
              <Picto icon="mail" className={style.picto} />
            &nbsp;admissions@mouratoglou.com
            </span>
            <span className={style.textBlock}>
              <Picto icon="phone" className={style.picto} />
            &nbsp;+33(0) 483 881 430
            </span>
          </div>
          <span className={cn([style.textBlock, style.termsWrapper])}>
            <a href={`${API_URL_BASE}public/terms.pdf`} className={style.terms}><b>{t('footer.terms')}</b></a>
            <a href={`${API_URL_BASE}public/privacy.pdf`} className={style.terms}><b>{t('footer.privacy')}</b></a>
          </span>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  color: PropTypes.oneOf(['white', 'black']),
  className: PropTypes.string,
};

Footer.defaultProps = {
  color: 'white',
  className: null,
};

export default Footer;
