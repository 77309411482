import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n';
import App from './App';
import './index.css';
import 'flexboxgrid';

import { ENV } from './constants/constants';

if (ENV !== 'dev') {
  Sentry.init({
    dsn:
    'https://441ab72c46014943ba1a55ad2593fc1e@o125949.ingest.sentry.io/5432174',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: ENV,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
