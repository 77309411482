import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// Utils & misc
import useAppContext from '../../../hooks/useAppContext';
import useTimeoutQueue from '../../../hooks/useTimeoutQueue';
import { getCompletionFormValues } from '../../../utils/computeProgression';

// Style
import style from './AutoSave.module.css';

const SAVE_DELAY = 1000; // in ms

export const AutoSave = ({
  formId, values, onSave, isSaving,
}) => {
  const {
    context: {
      activeForm,
    }, computeProgress,
  } = useAppContext();
  const savedOnSave = useCallback(({ values: nValues, formId: nFormId, activeF }) => {
    onSave(nFormId, nValues);
    computeProgress(getCompletionFormValues(nValues), activeF);
  }, []);
  const launchSaveTimeout = useTimeoutQueue(savedOnSave, SAVE_DELAY);
  const firstEffectRun = useRef(true);

  useEffect(() => {
    if (!activeForm) return;
    if (firstEffectRun.current) {
      firstEffectRun.current = false;
      return;
    }

    launchSaveTimeout({ values, formId, activeF: { ...activeForm } });
  }, [values, activeForm]);

  return isSaving
    ? <span className={style.submitStatus}>saving...</span>
    : (
      <span className={style.submitStatus}>
        saved
        {' '}
        {format(new Date(), 'dd/MM/yyyy HH:mm:ss')}
      </span>
    );
};

AutoSave.propTypes = {
  values: PropTypes.shape({}).isRequired,
  formId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default AutoSave;
