import { useContext, useState } from 'react';
import axios from 'axios';

// Utils & misc
import { API_URL } from '../constants/constants';

// Context
import useAppContext from './useAppContext';
import { KeycloakAuthContext } from '../components/templates/KeycloakAuthProvider';

const useApi = () => {
  // configs
  const {
    setActiveForm, setActiveStudent,
  } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState(null);
  const [responseError] = useState(null);
  const { keycloak } = useContext(KeycloakAuthContext);
  const uuid = JSON.parse(sessionStorage.getItem('session'));

  const request = (method, url, data, headersOptions, config = {}) => {
    setLoading(true);
    const requestConfig = {
      headers: {
        ...headersOptions,
        'X-Authorization': `Bearer ${keycloak.token}`,
      },
      method,
      url,
      data,
      ...config,
    };

    return keycloak.updateToken(30).then(() => axios(requestConfig));
  };

  const errorHandler = (res) => {
    if (res && res.response && res.response.status === 500) {
      window.location.replace('');
    }
  };

  const uploadThumbnail = (fileName, file, studentId) => request(
    'POST',
    `${API_URL}/v1/students-thumbnails/${fileName}`,
    file,
    { 'X-Student-Id': studentId },
  );

  const getThumbnail = (thumbnailId, studentId) => request(
    'GET',
    `${API_URL}/v1/students-thumbnails/${thumbnailId}`,
    null,
    { 'X-Student-Id': studentId },
    { responseType: 'blob' },
  );

  const getGuardian = () => request(
    'GET',
    `${API_URL}/v1/students/mine`,
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      // setMe(response.data);
      return response.data;
    });

  const updateStudent = student => request(
    'PUT',
    `${API_URL}/v1/students/${student.id}`,
    student,
  )
    .then((response) => {
      setResponseData(response.data);
      setActiveStudent(response.data);
      setLoading(false);
      return response.data;
    });

  const getStudentForm = formId => request(
    'GET',
    `${API_URL}/v1/forms/${formId}/data`,
    null,
    {
      'X-Form-Lock-Id': uuid,
    },
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    })
    .catch((res) => {
      if (res && res.response && res.response.status === 500) {
        window.location.replace('/students');
      }
    });

  const getFormInfo = formId => request(
    'GET',
    `${API_URL}/v1/forms/${formId}`,
    null,
    {
      'X-Form-Lock-Id': uuid,
    },
  )
    .then((response) => {
      setResponseData(response.data);
      setActiveForm(response.data);

      if (response.data && response.data.student) {
        setActiveStudent(response.data.student);
      }

      setLoading(false);
      return response.data;
    })
    .catch(errorHandler);

  const getAsyncFormInfo = formId => request(
    'GET',
    `${API_URL}/v1/forms/${formId}`,
    null,
    {
      'X-Form-Lock-Id': uuid,
    },
  );

  const updateFormInfo = (formId, form) => request(
    'PUT',
    `${API_URL}/v1/forms/${formId}/documents`,
    form,
    {
      'X-Form-Lock-Id': uuid,
    },
  )
    .then((response) => {
      setResponseData(response.data);
      // FIXME: Temporary fix, as the PUT request doesnt return the form info
      request(
        'GET',
        `${API_URL}/v1/forms/${formId}`,
        null,
        {
          'X-Form-Lock-Id': uuid,
        },
      ).then((r) => {
        setActiveForm(r.data);
      });
      // setActiveForm(response.data);
      setLoading(false);
      return response.data;
    })
    .catch(errorHandler);

  const updateStudentForm = (formId, form) => request(
    'PUT',
    `${API_URL}/v1/forms/${formId}/data`,
    form,
    {
      'X-Form-Lock-Id': uuid,
    },
  )
    .then((response) => {
      if (response) setResponseData(response.data);
      setLoading(false);
      return response;
    })
    .catch(errorHandler);

  const uploadDocument = (file, studentId) => request(
    'POST',
    `${API_URL}/v1/documents/${file.name}`,
    file,
    { 'X-Student-Id': studentId },
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    });

  const getDocument = (fileId, studentId) => request(
    'GET',
    `${API_URL}/v1/documents/${fileId}`,
    {},
    { 'X-Student-Id': studentId },
    { responseType: 'blob' },
  );

  const getProducts = ({ formId }) => request(
    'GET',
    `${API_URL}/v1/products`,
    null,
    {},
    {
      params: {
        formId,
      },
    },
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    });

  const getFormMessages = formId => request(
    'GET',
    `${API_URL}/v1/messages?form=${formId}`,
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    });

  const postFormEvent = (formId, transition) => request(
    'POST',
    `${API_URL}/v1/form_events`,
    { formId, transition },
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    });

  const getSigningUrl = formId => request(
    'GET',
    `${API_URL}/v1/forms/${formId}/signing_url`,
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    });

  const getConfig = () => request(
    'GET',
    `${API_URL}/v1/config`,
  )
    .then((response) => {
      setResponseData(response.data);
      setLoading(false);
      return response.data;
    });

  return {
    loading,
    responseData,
    responseError,
    getGuardian,
    uploadThumbnail,
    getThumbnail,
    updateStudent,
    getStudentForm,
    updateStudentForm,
    uploadDocument,
    getDocument,
    getProducts,
    getFormInfo,
    getAsyncFormInfo,
    getFormMessages,
    updateFormInfo,
    postFormEvent,
    getSigningUrl,
    getConfig,
  };
};

export default useApi;
