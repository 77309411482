// Regexp
import { mailRegExp, urlRegExp } from './regexps';

export default function (values) {
  const errors = {};

  if (values.previousSchoolEmail
    && !mailRegExp.test(values.previousSchoolEmail)
  ) errors.previousSchoolEmail = 'wrongEmail';

  if (values.previousSchoolWebsite
    && !urlRegExp.test(values.previousSchoolWebsite)
  ) errors.previousSchoolWebsite = 'wrongUrl';

  return errors;
}
