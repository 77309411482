import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Components
import Picto from '../../atoms/Picto';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './MenuList.module.css';

export const MenuList = ({
  label, icon, isActive, children, disabled, to,
}) => (
  <NavLink to={to} activeClassName={style.active} className={cn([style.wrapper, isActive ? style.active : null, disabled && style.disabled])}>
      {icon ? <Picto className={style.icon} icon={icon} /> : null}
      <span className={cn([style.label, !icon ? style.noIcon : null])}>{label}</span>
      <span className={style.children}>
        {children}
      </span>
    </NavLink>
);

MenuList.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

MenuList.defaultProps = {
  icon: null,
  isActive: false,
  disabled: false,
  children: null,
};

export default MenuList;
