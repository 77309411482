import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

// Components
import Picto from '../Picto';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './MediaLabel.module.css';

export const MediaLabel = ({
  icon, isActive, children, onClick, btnStyle, onDelete, alignDirection,
}) => (
  <div className={alignDirection === 'left' ? style.wrapperLeft : style.wrapper}>
    {onDelete
      ? (
        <button
          type="button"
          onClick={onDelete}
          className={style.closeBtn}
        >
          <Picto icon="cross" />
        </button>
      )
      : null
      }
    <button
      type="button"
      onClick={onClick}
      className={cn([
        style.media,
        isActive ? style.active : null,
        btnStyle === 'border' ? style.btnBorder : null,
      ])}
    >
      <Picto icon={icon} />
    </button>
    {children}
  </div>
);

MediaLabel.propTypes = {
  alignDirection: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  btnStyle: PropTypes.oneOf(['fill', 'border']),
};

MediaLabel.defaultProps = {
  alignDirection: 'center',
  isActive: false,
  children: null,
  btnStyle: 'fill',
  onDelete: null,
  onClick: noop,
};

export default MediaLabel;
