import React from 'react';
import PropTypes from 'prop-types';

// Utils
import cn from '../../../utils/cn';

// Style
import style from './RadioCustom.module.css';

export const RadioCustom = ({
  label, id, subLabel, className, ...propsLeft
}) => (
  <div className="col">
    <div className={cn([style.group, className])}>
      <label className={cn([style.label, propsLeft.disabled ? style.disabled : ''])} htmlFor={id}>
        <input className={style.input} id={id} type="radio" {...propsLeft} />
        <div className={cn([style.subLabel, propsLeft.checked && style.active])}>
          {subLabel}
        </div>
        <span className={cn([style.label, propsLeft.disabled ? style.disabled : ''])}>{label}</span>
      </label>
    </div>
  </div>
);

RadioCustom.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RadioCustom.defaultProps = {
  className: null,
};

export default RadioCustom;
