import React, {
  Fragment, useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
  isValidNumber,
} from 'libphonenumber-js';
import { upperCase, lowerCase, omit } from 'lodash';

// Components
import TextField from '../TextField';
import Select from '../Select';

// Utils & Misc
import cn from '../../../utils/cn';
import { isNumberValidFromString } from '../../../utils/phone';

// Style
import flagsStyle from '../../../constants/flags.module.css';
import style from './PhoneField.module.css';

const formatPhoneNumber = (number, code) => {
  // Parse phone number to replace or add calling code from selected country
  const parsedPhoneNumber = parsePhoneNumberFromString(`${code}${number}`);
  // const rawNumber = parsedPhoneNumber
  //   ? parsedPhoneNumber.nationalNumber
  //   : number;
  const e164 = parsedPhoneNumber ? parsedPhoneNumber.number : '';
  // const asYouTypeNumber = new AsYouType(country).input(rawNumber);
  // if (code && number) return { number: asYouTypeNumber, e164 };
  return { number, e164 };
};

export const PhoneField = ({
  countriesList,
  defaultCountry,
  defaultPhone,
  className,
  selectPlaceholder,
  disabled,
  initialValue,
  onPhoneChange,
  error,
  ...propsLeft
}) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const formatted = parsePhoneNumberFromString(
    typeof initialValue !== 'string' ? '' : initialValue,
  );

  const [country, setCountry] = useState({
    codeISO: formatted ? formatted.country : '',
    callingCode: formatted ? `+${formatted.countryCallingCode}` : '',
  });

  const [phoneValue, setPhoneValue] = useState(
    formatted ? formatted.nationalNumber : '',
  );

  // Formatted option template for country select
  const getCountriesOptions = () => countriesList.map(countryIso => ({
    value: countryIso,
    label: (
      <Fragment>
        <span
          className={cn([
            flagsStyle['flag-icon'],
            flagsStyle[`flag-icon-${lowerCase(countryIso)}`],
          ])}
        />
        <span className={style.country}>{upperCase(countryIso)}</span>
      </Fragment>
    ),
  }));

  // Called when selecting a country code
  const handleCountryChange = (e, option) => {
    const codeISO = option.value;
    const callingCode = `+${getCountryCallingCode(option.value)}`;

    setCountry({ codeISO, callingCode });
  };

  const handleNumberChange = (e) => {
    setPhoneValue(e.target.value);
  };

  // Check for a complete and valid phone number change
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (phoneValue && phoneValue.length > 0 && country.codeISO && country.callingCode) {
      const { number, e164 } = formatPhoneNumber(phoneValue, country.callingCode,
        country.codeISO);
      if (isValidNumber(number, country.codeISO)) {
        onPhoneChange({
          fullNumber: `${country.callingCode}${phoneValue}`,
          e164,
          code: country.callingCode,
          iso: country.codeISO,
        });
      }
    }
  }, [phoneValue, country]);

  const phoneNumberCheck = () => {
    if (country.codeISO && phoneValue) {
      if (!isNumberValidFromString(`${country.callingCode}${phoneValue}`)) {
        return (t('form.errors.wrongNumber'));
      }
    }
    return null;
  };

  return (
    <div className={cn([style.inputGroup, className])}>
      <Select
        className={style.selectCountry}
        isSearchable
        value={country.codeISO}
        placeholder={selectPlaceholder}
        options={getCountriesOptions()}
        onChange={handleCountryChange}
        menuPortalTarget={document.body}
        isDisabled={disabled}
      />
      <span className={style.phoneCode}>
        {country && country.callingCode ? country.callingCode : null}
      </span>
      <TextField
        className={style.textField}
        type="tel"
        disabled={disabled}
        onChange={handleNumberChange}
        value={phoneValue}
        error={error || phoneNumberCheck()}
        {...(omit(propsLeft, ['phoneCode', 'phoneIso']) || {})}
      />
    </div>
  );
};

PhoneField.propTypes = {
  countriesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
  defaultPhone: PropTypes.string,
  phoneCode: PropTypes.string,
  phoneIso: PropTypes.string,
  className: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.string]),
};

PhoneField.defaultProps = {
  defaultCountry: '',
  defaultPhone: '',
  phoneCode: '',
  phoneIso: '',
  className: null,
  selectPlaceholder: null,
  disabled: false,
  initialValue: null,
  error: null,
};

export default PhoneField;
