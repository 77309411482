import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import uuid from 'uuid/v4';

const AppContext = React.createContext([{}, () => { }]);

const initContext = JSON.parse(localStorage.getItem('context')) || { auth: { role: 'guardian' } };

const AppProvider = ({ children }) => {
  const [state, setState] = useState(initContext);

  useEffect(() => {
    if (!sessionStorage.getItem('session')) {
      sessionStorage.setItem('session', JSON.stringify(uuid()));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(state)) localStorage.setItem('context', JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext, AppProvider };
