import React from 'react';

// Components
import NoSide from '../components/templates/NoSide';

const LegalTerms = () => (
  <NoSide headerStyle="blue" footerStyle="black">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <h1>Legal heading</h1>
          <h2>Legal subheading</h2>
          <p>Legal paragraph</p>
        </div>
      </div>
    </div>
  </NoSide>
);

export default LegalTerms;
