import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { lowerCase } from 'lodash';
import i18nCountries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesFR from 'i18n-iso-countries/langs/fr.json';

// Components
import WithSide from '../components/templates/WithSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import TextField from '../components/atoms/TextField';
import Radio from '../components/atoms/Radio';
import FormBlock from '../components/atoms/FormBlock';
import AutoSave from '../components/atoms/AutoSave';
import Counter from '../components/molecules/Counter';
import Accordion from '../components/organisms/Accordion';
import { useAccordion } from '../components/organisms/Accordion/Accordion';

// Utils & Misc
import cn from '../utils/cn';
import { generalValidate } from '../utils/validators';
import shouldDisable from '../utils/shouldDisable';
import allowToSubmit from '../utils/allowToSubmit';
import ISO_COUNTRIES_LIST from '../constants/isoCountriesList.json';
import { FORM_INITIAL_VALUES as FIV } from '../constants/constants';
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import i18n from '../i18n';

// Style
import style from './formApp.module.css';
import flagsStyle from '../constants/flags.module.css';

// TODO remove all useAccordion and refacto should disable

const SportInformation = ({ match, history }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  i18nCountries.registerLocale(i18n.language === 'en' ? countriesEN : countriesFR);

  const [isRankedAccordion, setIsRankedAccordion] = useAccordion();

  const { context: { activeForm, auth, progress }, computeProgress } = useAppContext();
  const {
    responseData: responseForm,
    getFormInfo,
  } = useApi();
  const {
    loading, responseData: formData, responseError: formError, getStudentForm,
  } = useApi();
  const {
    loading: submitLoading, responseError: submitError, updateStudentForm,
  } = useApi();
  const {
    postFormEvent,
  } = useApi();
  const {
    values, errors, touched, handleChange, handleBlur, setFieldValue,
  } = useFormik({
    initialValues: {
      ...FIV.generalInfo,
      ...FIV.scholarshipInfo,
      ...FIV.sportInfo,
      ...FIV.medicalInfo,
      ...FIV.administrativeInfo,
      ...FIV.ratesInfo,
      ...formData,
    },
    enableReinitialize: true,
    validate: generalValidate,
  });
  const isFormLocked = useMemo(() => (
    activeForm ? shouldDisable(activeForm.stateId, auth.role) : true
  ), [activeForm]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
  };

  const submitForm = () => {
    postFormEvent(match.params.id, 'submit').then(() => {
      setSubmitted(true);
      history.push('/confirmation', { studentId: match.params.id });
    });
    // if (allowToSubmit(progress)) postFormEvent(match.params.id, 'submit');
  };

  useEffect(() => {
    getStudentForm(match.params.id);
    getFormInfo(match.params.id);
  }, []);

  const saveForm = () => {
    updateStudentForm(match.params.id, values);
    computeProgress(values, activeForm);
  };

  const getCountriesOptions = () => ISO_COUNTRIES_LIST.map(({ Code }) => ({
    value: Code,
    label: (
      <Fragment>
        <span className={cn([flagsStyle['flag-icon'], flagsStyle[`flag-icon-${lowerCase(Code)}`]])} />
        <span className={style.country}>{i18nCountries.getName(Code, i18n.language)}</span>
      </Fragment>
    ),
  }));

  const handleIsRankedChange = (e, formAction) => {
    if (e.target.value === 'true') setIsRankedAccordion(true);
    else setIsRankedAccordion(false);
    formAction(e);
  };

  const IS_SCHOOL_PROGRAM = responseForm ? responseForm.typeId === 4 : false;

  useEffect(() => {
    if (values.doAffiliateToMouratoglouFtt === 'false' || values.doAffiliateToMouratoglouFtt === false) {
      setFieldValue('isAlreadyAffiliatedFtt', null);
      setFieldValue('otherFttAffiliationClub', null);
    }

    if (values.doAffiliateToMouratoglouFtt === 'true' || values.doAffiliateToMouratoglouFtt === true) {
      setFieldValue('otherAffiliationClub', null);
    }

    if (values.isAlreadyAffiliatedFtt === 'false' || values.isAlreadyAffiliatedFtt === false) {
      setFieldValue('otherFttAffiliationClub', null);
    }
  }, [values]);

  return (
    <WithSide title={t('sportsInformation.viewTitle')}>
      {loading
        ? <div />
        : (
          <form>
            <Card className="card-margin">
              <div className="row">
                <FormBlock label={t('sportsInformation.doAffiliateToMouratoglouFtt')}>
                  <Radio
                    id="doAffiliateToMouratoglouFtt-yes"
                    name="doAffiliateToMouratoglouFtt"
                    value="true"
                    label={t('sportsInformation.yes')}
                    checked={values.doAffiliateToMouratoglouFtt === 'true' || values.doAffiliateToMouratoglouFtt === true}
                    onChange={handleChange}
                    disabled={isFormLocked}
                  />
                  <Radio
                    id="doAffiliateToMouratoglouFtt-no"
                    name="doAffiliateToMouratoglouFtt"
                    value="false"
                    label={t('sportsInformation.no')}
                    checked={values.doAffiliateToMouratoglouFtt === 'false' || values.doAffiliateToMouratoglouFtt === false}
                    onChange={handleChange}
                    disabled={isFormLocked}
                  />
                </FormBlock>
                <Accordion isToggled={values.doAffiliateToMouratoglouFtt === 'true' || values.doAffiliateToMouratoglouFtt === true}>
                  <FormBlock label={t('sportsInformation.isAlreadyAffiliatedFtt')}>
                    <Radio
                      id="isAlreadyAffiliatedFtt-yes"
                      name="isAlreadyAffiliatedFtt"
                      value="true"
                      label={t('sportsInformation.yes')}
                      checked={values.isAlreadyAffiliatedFtt === 'true' || values.isAlreadyAffiliatedFtt === true}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                    <Radio
                      id="isAlreadyAffiliatedFtt-no"
                      name="isAlreadyAffiliatedFtt"
                      value="false"
                      label={t('sportsInformation.no')}
                      checked={values.isAlreadyAffiliatedFtt === 'false' || values.isAlreadyAffiliatedFtt === false}
                      onChange={handleChange}
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                </Accordion>
                <Accordion
                  isToggled={(
                    (values.doAffiliateToMouratoglouFtt === 'true' || values.doAffiliateToMouratoglouFtt === true)
                    && (values.isAlreadyAffiliatedFtt === 'true' || values.isAlreadyAffiliatedFtt === true)
                  )}
                >
                  <FormBlock noMargin>
                    <TextField
                      label={t('sportsInformation.otherFttAffiliationClub')}
                      type="text"
                      name="otherFttAffiliationClub"
                      id="otherFttAffiliationClub"
                      value={values.otherFttAffiliationClub}
                      error={
                        errors.otherFttAffiliationClub && touched.otherFttAffiliationClub
                          ? t(`form.errors.${errors.otherFttAffiliationClub}`)
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                </Accordion>
                <Accordion
                  isToggled={(
                    (values.doAffiliateToMouratoglouFtt === 'false' || values.doAffiliateToMouratoglouFtt === false)
                  )}
                >
                  <FormBlock noMargin>
                    <TextField
                      label={t('sportsInformation.otherAffiliationClub')}
                      type="text"
                      name="otherAffiliationClub"
                      id="otherAffiliationClub"
                      value={values.otherAffiliationClub}
                      error={
                        errors.otherAffiliationClub && touched.otherAffiliationClub
                          ? t(`form.errors.${errors.otherAffiliationClub}`)
                          : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </FormBlock>
                </Accordion>

                { !IS_SCHOOL_PROGRAM
                  ? (
                    <div className="col-xs-12 col-lg-10 md-10">
                      <FormBlock label={t('sportsInformation.howOldTennisDebut')}>
                        <Counter
                          onPlusClick={age => setFieldValue('startingAge', age)}
                          onMinusClick={age => setFieldValue('startingAge', age)}
                          defaultNumber={values.startingAge ? +values.startingAge : 0}
                          min={5}
                          max={20}
                          disabled={isFormLocked}
                        />
                      </FormBlock>
                      <FormBlock label={t('sportsInformation.howManyMatches')}>
                        <Counter
                          onPlusClick={matches => setFieldValue('matchesNumber', matches)}
                          onMinusClick={matches => setFieldValue('matchesNumber', matches)}
                          defaultNumber={values.matchesNumber ? +values.matchesNumber : 0}
                          min={0}
                          max={99}
                          disabled={isFormLocked}
                        />
                      </FormBlock>
                      <FormBlock label={t('sportsInformation.howManyhours')}>
                        <Counter
                          onPlusClick={hours => setFieldValue('hoursNumber', hours)}
                          onMinusClick={hours => setFieldValue('hoursNumber', hours)}
                          defaultNumber={values.hoursNumber ? +values.hoursNumber : 0}
                          min={0}
                          max={50}
                          disabled={isFormLocked}
                        />
                      </FormBlock>
                      <FormBlock label={t('sportsInformation.isRanked')}>
                        <Radio
                          id="isRanked-yes"
                          name="isRanked"
                          value="true"
                          label={t('sportsInformation.yes')}
                          checked={values.isRanked === 'true' || values.isRanked === true}
                          onChange={e => handleIsRankedChange(e, handleChange)}
                          disabled={isFormLocked}
                        />
                        <Radio
                          id="isRanked-no"
                          name="isRanked"
                          value="false"
                          label={t('sportsInformation.no')}
                          checked={values.isRanked === 'false' || values.isRanked === false}
                          onChange={e => handleIsRankedChange(e, handleChange)}
                          disabled={isFormLocked}
                        />
                      </FormBlock>
                      <Accordion isToggled={isRankedAccordion || values.isRanked === 'true' || values.isRanked === true}>
                        <Select
                          isSearchable
                          menuPortalTarget={document.body}
                          id="rankedCountry"
                          name="rankedCountry"
                          label={t('sportsInformation.country')}
                          placeholder={t('sportsInformation.chooseCountry')}
                          value={values.rankedCountry}
                          options={getCountriesOptions()}
                          onChange={handleSelectChange}
                          onBlur={handleBlur}
                          disabled={isFormLocked}
                        />
                        <TextField
                          label={t('sportsInformation.rank')}
                          type="text"
                          name="rank"
                          id="rank"
                          value={values.rank}
                          error={errors.rank && touched.rank
                            ? t(`form.errors.${errors.rank}`) : null
                        }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          disabled={isFormLocked}
                        />
                        <TextField
                          label={t('sportsInformation.otherRank')}
                          type="text"
                          name="otherRank"
                          id="otherRank"
                          value={values.otherRank}
                          error={errors.otherRank && touched.otherRank
                            ? t(`form.errors.${errors.otherRank}`) : null
                        }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          disabled={isFormLocked}
                        />
                      </Accordion>
                    </div>
                  )
                  : (
                    <div className="col-xs-12 col-lg-10 md-10">
                      <h2 className="card-title">{t('sportsInformation.sportsProgram')}</h2>
                      <FormBlock label={t('sportsInformation.followSportsProgram')}>
                        <Radio
                          id="followSportsProgram-yes"
                          name="followSportsProgram"
                          value="true"
                          checked={values.followSportsProgram === 'true'}
                          label={t('scholarshipInformation.yes')}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                        <Radio
                          id="followSportsProgram-no"
                          name="followSportsProgram"
                          value="false"
                          checked={values.followSportsProgram === 'false'}
                          label={t('scholarshipInformation.no')}
                          onChange={handleChange}
                          disabled={isFormLocked}
                        />
                      </FormBlock>
                      <Accordion
                        isToggled={values.followSportsProgram === 'true'}
                      >
                        <div>
                          <label className={cn([style.formLabel, style.mt2])}>{t('sportsInformation.whichSport')}</label>
                          { [{ name: 'sport1', value: 'Football' }, { name: 'sport2', value: 'Golf' }, { name: 'sport3', value: 'Danse' }, { name: 'sport4', value: 'Judo' }, { name: 'sport5', value: 'Equitation' }, { name: 'sport6', value: 'Basketball' }, { name: 'sport7', value: 'other' }].map((sport, index) => (
                            <div key={`whichSport_${sport.value}`} className={cn(['col-xs-12 col-lg-12 col-md-12', index !== 0 ? style.mt2 : ''])}>
                              <Radio
                                id={`${sport.value}_sport_radio`}
                                name="sport"
                                label={t(`sportsInformation.${sport.name}`)}
                                onChange={() => setFieldValue('sport', sport.value)}
                                checked={values.sport === sport.value}
                                disabled={isFormLocked}
                              />
                            </div>
                          )) }

                          { values.sport === 'other' ? (
                            <div>
                              <div className={cn(['col-xs-12 col-lg-12 col-md-12'])}>
                                <TextField
                                  label={t('sportsInformation.whichSportLabel')}
                                  type="text"
                                  name="otherSport"
                                  id="otherSport"
                                  value={values.otherSport}
                                  error={errors.otherSport && touched.otherSport
                                    ? t(`form.errors.${errors.otherSport}`) : null
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                  disabled={isFormLocked}
                                />
                              </div>
                            </div>
                          ) : null }

                          { values.sport === 'Golf' ? (
                            <div>
                              <div className={cn(['col-xs-12 col-lg-12 col-md-12'])}>
                                <FormBlock label={t('sportsInformation.golfAcademyMemberLabel')}>
                                  <Radio
                                    id="golfAcademyMember-yes"
                                    name="golfAcademyMember"
                                    value="true"
                                    checked={values.golfAcademyMember === 'true'}
                                    label={t('scholarshipInformation.yes')}
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                  />
                                  <Radio
                                    id="golfAcademyMember-no"
                                    name="golfAcademyMember"
                                    value="false"
                                    checked={values.golfAcademyMember === 'false'}
                                    label={t('scholarshipInformation.no')}
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                  />
                                </FormBlock>
                              </div>
                            </div>
                          ) : null }

                          <div>
                            <div className={cn(['col-xs-12 col-lg-12 col-md-12', style.mt2])}>
                              <TextField
                                label={t('sportsInformation.trainingHours')}
                                type="text"
                                name="trainingHours"
                                id="trainingHours"
                                value={values.trainingHours}
                                error={errors.trainingHours && touched.trainingHours
                                  ? t(`form.errors.${errors.trainingHours}`) : null
                        }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                disabled={isFormLocked}
                              />
                            </div>
                          </div>

                          <div>
                            <div className={cn(['col-xs-12 col-lg-12 col-md-12', style.mt2])}>
                              <FormBlock label={t('sportsInformation.doesCompetition')}>
                                <Radio
                                  id="doesCompetition-yes"
                                  name="doesCompetition"
                                  value="true"
                                  checked={values.doesCompetition === 'true'}
                                  label={t('scholarshipInformation.yes')}
                                  onChange={handleChange}
                                  disabled={isFormLocked}
                                />
                                <Radio
                                  id="doesCompetition-no"
                                  name="doesCompetition"
                                  value="false"
                                  checked={values.doesCompetition === 'false'}
                                  label={t('scholarshipInformation.no')}
                                  onChange={handleChange}
                                  disabled={isFormLocked}
                                />
                              </FormBlock>
                            </div>
                          </div>

                        </div>
                      </Accordion>
                    </div>
                  ) }
              </div>
            </Card>

            <div className={style.submitBlock}>
              {formError || isFormLocked ? (
                <div>{formError || isFormLocked}</div>
              ) : (
                <AutoSave
                  formId={match.params.id}
                  values={values}
                  isSaving={submitLoading}
                  onSave={updateStudentForm}
                  error={submitError}
                />
              )}
              <Button
                className={style.submitForm}
                type="button"
                color="green"
                label={
                submitted || isFormLocked
                  ? t('ratesInformation.submitApplicationSuccess')
                  : t('ratesInformation.submitApplication')
              }
                onClick={submitForm}
                disabled={!allowToSubmit(progress) || submitted || isFormLocked}
              />
              <Button
                className={style.submit}
                type="button"
                label={t('generalInformation.next')}
                onClick={() => saveForm(values)}
                disabled={submitLoading}
              />
            </div>
          </form>
        )
      }
    </WithSide>
  );
};

SportInformation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default SportInformation;
