import { MANDATORY_FIELDS } from '../constants/constants';

export default function (formProgress) {
  return Object.keys(MANDATORY_FIELDS).every((formItem) => {
    if (formItem === 'scholarshipInformationSchool1' || formItem === 'scholarshipInformationSchool2' || formItem === 'scholarshipInformationCenter' || formItem === 'scholarshipInformationProTeam') {
      return formProgress.scholarshipInformation >= 1;
    }
    if (formItem === 'sportsInformationSchoolProgram') {
      return formProgress.sportsInformation >= 1;
    }
    return formProgress[formItem] >= 1;
  });
}
