// Views
import Confirmation from './views/Confirmation';
import LegalTerms from './views/LegalTerms';
import PrivacyPolicy from './views/PrivacyPolicy';
import Students from './views/Students';
import GeneralInformation from './views/GeneralInformation';
import ScholarshipInformation from './views/ScholarshipInformation';
import SportInformation from './views/SportInformation';
import MedicalInformation from './views/MedicalInformation';
import AdministrativeInformation from './views/AdministrativeInformation';
import RatesInformation from './views/RatesInformation';
import MiddleOffice from './views/MiddleOffice';
import AdminMode from './views/AdminMode';

export const routes = [
  {
    path: '/legal-terms',
    component: LegalTerms,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: '/admin',
    component: AdminMode,
    exact: true,
  },
];

export const protectedRoutes = [
  {
    path: '/confirmation',
    component: Confirmation,
    exact: true,
  },
  {
    path: '/students',
    component: Students,
    exact: true,
  },
  {
    path: '/:id/general-information',
    component: GeneralInformation,
    exact: true,
  },
  {
    path: '/:id/scholarship-information',
    component: ScholarshipInformation,
    exact: true,
  },
  {
    path: '/:id/sports-information',
    component: SportInformation,
    exact: true,
  },
  {
    path: '/:id/medical-information',
    component: MedicalInformation,
    exact: true,
  },
  {
    path: '/:id/administrative-information',
    component: AdministrativeInformation,
    exact: true,
  },
  {
    path: '/:id/rates-information',
    component: RatesInformation,
    exact: true,
  },
  {
    path: '/:id/dashboard',
    component: MiddleOffice,
    exact: true,
  },
];
