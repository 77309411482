// Form validators
import generalValidator from './generalValidator';
import scholarshipValidator from './scholarshipValidator';

export const generalValidate = generalValidator;
export const scholarshipValidate = scholarshipValidator;

export default {
  generalValidate,
  scholarshipValidate,
};
