import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils & misc
import cn from '../../../utils/cn';

// Style
import style from './Checkbox.module.css';

export const Checkbox = ({
  label, id, className, ...propsLeft
}) => {
  const [absolute, setAbsolute] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setAbsolute(style.absolute);
    }, 100);
    return () => {};
  }, []);

  return (
    <label className={cn([style.group, className])} htmlFor={id}>
      <input className={style.input} id={id} type="checkbox" {...propsLeft} />
      <span className={cn([style.label, absolute])}>{label}</span>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  className: null,
};

export default Checkbox;
