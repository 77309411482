export default function (formStatus, role) {
  switch (formStatus) {
    case 1: return role !== 'admin';
    case 2: return role === 'admin';
    case 3:
    case 4:
    case 5:
      return true;
    default: return false;
  }
}
