import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Card from '../components/atoms/Card';
import { ReactComponent as Loader } from '../assets/loader/oval.svg';

// Utils & misc
import queryParser from '../utils/queryParser';
import { KeycloakAuthContext } from '../components/templates/KeycloakAuthProvider';

// Hooks
import useAppContext from '../hooks/useAppContext';
import useApi from '../hooks/useApi';

const AdminMode = ({ location, history }) => {
  // This view serve only to redirect admins and populate the localstorage to mimic
  // the usual flow of the app.

  const queryParams = queryParser(location.search);
  const { setIsAdmin, setActiveStudent } = useAppContext();
  const { getFormInfo } = useApi();
  const { keycloak } = useContext(KeycloakAuthContext);

  useEffect(() => {
    if (keycloak.authenticated) {
      getFormInfo(queryParams.formId);
    } else {
      setIsAdmin();
      setActiveStudent({ id: queryParams.studentId, currentFormId: queryParams.formId });
      history.push('/login');
    }
  }, []);

  return (
    <div>
      <Card rounded style={{ maxWidth: '30%', margin: '3rem auto', textAlign: 'center' }}>
        <h1>Redirection...</h1>
        <Loader />
      </Card>
    </div>
  );
};

AdminMode.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AdminMode;
