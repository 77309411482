import React from 'react';
import PropTypes from 'prop-types';

// Utils
import cn from '../../../utils/cn';

import styles from './FlashMessage.module.css';

const FlashMessage = ({ children, className, color }) => (
  children ? (
    <div
      className={cn([
        styles.flash,
        className || '',
        color ? styles[color] : '',
      ])}
    >
      {children}
    </div>
  ) : null
);

FlashMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['success']),
};

FlashMessage.defaultProps = {
  children: null,
  className: '',
  color: null,
};

export default FlashMessage;
