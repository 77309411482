import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { find, isEmpty } from 'lodash';

// Components
import WithUserSide from '../components/templates/WithUserSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';
import StatusMessage from '../components/atoms/StatusMessage';
import DropZone from '../components/molecules/DropZone';

// Utils & misc
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import { API_URL, FORM_STATUS, API_URL_BASE } from '../constants/constants';

// Style
import style from './MiddleOffice.module.css';
import cn from '../utils/cn';

const MiddleOffice = ({ match, history }) => {
  const { t } = useTranslation();
  const {
    context: { activeStudent, activeForm, auth },
  } = useAppContext();
  const [documents, setDocuments] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const formLoaded = useRef(false);
  const [initialUploadedDocuments, setInitialUploadedDocuments] = useState([
    // 1,
    // 2,
    // 3,
    // 4,
    // 5,
    // 6,
    // 7,
    // 8,
    // 9,
    // 10,
    // 11,
  ]);
  const [loadDocuSIgn, setLoadDocuSIgn] = useState(false);
  const { loading: loadDocs, uploadDocument } = useApi();
  const [documentsEdited, setDocumentsEdited] = useState(false);

  const { updateFormInfo } = useApi();
  const {
    loading: loadMsgs,
    responseData: responseMsgs,
    getFormMessages,
  } = useApi();
  const {
    loading: loadForm,
    responseData: responseForm,
    getFormInfo,
  } = useApi();
  const { responseData: responseSign, getSigningUrl } = useApi();
  const { getDocument } = useApi();
  const { loading: studentFormLoading, responseData: studentFormData, getStudentForm } = useApi();

  useEffect(() => {
    getStudentForm(match.params.id);
    getFormInfo(match.params.id);
    getFormMessages(match.params.id);
  }, []);

  useEffect(() => {
    if (responseForm) {
      if (!formLoaded.current) {
        setInitialUploadedDocuments(Object.keys(responseForm.documents).map(docId => ({
          ...responseForm.documents[docId],
        })));
      }
      formLoaded.current = true;

      setDocuments(Object.keys(responseForm.documents).map(docId => ({
        ...responseForm.documents[docId],
      })));
    }
  }, [responseForm]);

  useEffect(() => {
    if (responseSign && responseSign.url) {
      setLoadDocuSIgn(false);
      window.location.assign(responseSign.url);
    }
  }, [responseSign]);

  const postDocument = (file, typeId) => {
    uploadDocument(file, activeStudent.id).then((response) => {
      const newDocuments = [...documents];

      newDocuments.push({
        name: file.name,
        id: response.id,
        typeId,
      });

      setDocuments(newDocuments);
      setDocumentsEdited(true);
    });
  };

  const handleDocumentClear = (typeId) => {
    const newDocuments = documents.filter(doc => doc.typeId !== typeId);
    const newInitialDocuments = initialUploadedDocuments.filter(doc => doc.typeId !== typeId);

    setDocuments(newDocuments);
    setInitialUploadedDocuments(newInitialDocuments);
    setDocumentsEdited(true);
  };

  const handleSendFiles = () => {
    const documentBody = documents.filter(doc => typeof doc === 'object');
    updateFormInfo(match.params.id, { documents: documentBody }).then(() => {
      setInitialUploadedDocuments(documents);
    });
  };

  const getStatusBarStyle = () => {
    if (responseForm) {
      switch (responseForm.stateId) {
        case 1:
        case 2:
        case 3:
          return null;
        case 4:
        case 5:
          return style.complete;
        default:
          return null;
      }
    }
    return null;
  };

  const handleCompleteClick = () => history.push(`/${match.params.id}/general-information`);

  const handleSignClick = () => {
    setLoadDocuSIgn(true);
    getSigningUrl(match.params.id);
  };

  const handleFileNameClick = (file) => {
    getDocument(file.id, match.params.id).then((response) => {
      if (response) saveAs(response.data, file.name);
    });
  };

  return (
    <WithUserSide headerStyle="fillWhite" user={activeStudent}>
      {!loadForm && !studentFormLoading ? (
        <Card className="card-margin">
          <h2 className={style.filesTitle}>
            {t('middleOffice.filesTitleCard')}
          </h2>

          {/* ETUDIANT */}
          <h3 className={style.subtitle}>
            {t('middleOffice.documentsRequired', {
              studentName: `${activeStudent.firstname} ${activeStudent.lastname}`,
            })}
          </h3>
          <div className="row">
            {/* CARTE IDENTITE - RECTO */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 7)}
                onClearFile={() => handleDocumentClear(7)}
                label={t('middleOffice.identityCardFront')}
                completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 7 }))
                }
                hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 7 }))
                }
                fileName={
                  find(documents, { typeId: 7 })
                  && find(documents, { typeId: 7 }).name
                }
                file={find(documents, { typeId: 7 })}
                fileLink={
                  find(documents, { typeId: 7 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 7 }).id
                    }`
                    : null
                }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>

            {/* CARTE IDENTITE - VERSO */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 8)}
                onClearFile={() => handleDocumentClear(8)}
                label={t('middleOffice.identityCardBack')}
                completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 8 }))
                }
                hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 8 }))
                }
                file={find(documents, { typeId: 8 })}
                fileName={
                  find(documents, { typeId: 8 })
                  && find(documents, { typeId: 8 }).name
                }
                fileLink={
                  find(documents, { typeId: 8 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 8 }).id
                    }`
                    : null
                }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>

            {/* Autorisation de sortie du territoire (formulaire CERFA) */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 2)}
                onClearFile={() => handleDocumentClear(2)}
                label={t('middleOffice.outingAuth')}
                completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 2 }))
                }
                hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 2 }))
                }
                file={find(documents, { typeId: 2 })}
                fileName={
                  find(documents, { typeId: 2 })
                  && find(documents, { typeId: 2 }).name
                }
                fileLink={
                  find(documents, { typeId: 2 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 2 }).id
                    }`
                    : null
                }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>

            {/* Attestation de responsabilité civile (old SCHOOL DOCUMENTS) */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 3)}
                onClearFile={() => handleDocumentClear(3)}
                label={t('middleOffice.civilResponsability')}
                completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 3 }))
                }
                hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 3 }))
                }
                file={find(documents, { typeId: 3 })}
                fileName={
                  find(documents, { typeId: 3 })
                  && find(documents, { typeId: 3 }).name
                }
                fileLink={
                  find(documents, { typeId: 3 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 3 }).id
                    }`
                    : null
                }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>

            {/* Demande de transfert de tous les dossiers scolaires */}
            { activeForm && activeForm.schoolId === 2
              ? (
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], 9)}
                    onClearFile={() => handleDocumentClear(9)}
                    label={t('middleOffice.educationalRecordsTransfer')}
                    completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 9 }))
                }
                    hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 9 }))
                }
                    file={find(documents, { typeId: 9 })}
                    fileName={
                  find(documents, { typeId: 9 })
                  && find(documents, { typeId: 9 }).name
                }
                    fileLink={
                  find(documents, { typeId: 9 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 9 }).id
                    }`
                    : null
                }
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>
              ) : null }

            {/* REQUEST FOR STUDENT RECORDS */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 10)
                    }
                onClearFile={() => handleDocumentClear(10)}
                label={t('middleOffice.studentRecordRequest')}
                completed={
                      initialUploadedDocuments.length > 0
                      && !isEmpty(find(initialUploadedDocuments, { typeId: 10 }))
                    }
                hasFile={
                      documents.length > 0
                      && !isEmpty(find(documents, { typeId: 10 }))
                    }
                file={find(documents, { typeId: 10 })}
                fileName={
                      find(documents, { typeId: 10 })
                      && find(documents, { typeId: 10 }).name
                    }
                fileLink={
                      find(documents, { typeId: 10 })
                        ? `${API_URL}/documents/${
                          find(documents, { typeId: 10 }).id
                        }`
                        : null
                    }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
                template={`${API_URL_BASE}public/request_for_student_records.docx`}
              />
            </div>

            {/* PSYCHO_EDUCATIONAL_REPORT */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 11)
                    }
                onClearFile={() => handleDocumentClear(11)}
                label={t('scholarshipInformation.psychoeducationalReport')}
                completed={
                      initialUploadedDocuments.length > 0
                      && !isEmpty(find(initialUploadedDocuments, { typeId: 11 }))
                    }
                hasFile={
                      documents.length > 0
                      && !isEmpty(find(documents, { typeId: 11 }))
                    }
                file={find(documents, { typeId: 11 })}
                fileName={
                      find(documents, { typeId: 11 })
                      && find(documents, { typeId: 11 }).name
                    }
                fileLink={
                      find(documents, { typeId: 11 })
                        ? `${API_URL}/documents/${
                          find(documents, { typeId: 11 }).id
                        }`
                        : null
                    }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>
          </div>

          {/* PARENT 1 */}
          <h3 className={style.subtitle}>
            {t('middleOffice.documentsRequired', {
              studentName: `${studentFormData.parent1Firstname} ${studentFormData.parent1Lastname}`,
            })}
          </h3>
          <div className="row">
            {/* IDENTITY RECTO */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 1)}
                onClearFile={() => handleDocumentClear(1)}
                label={t('middleOffice.identityCardFront')}
                completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 1 }))
                }
                hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 1 }))
                }
                file={find(documents, { typeId: 1 })}
                fileName={
                  find(documents, { typeId: 1 })
                  && find(documents, { typeId: 1 }).name
                }
                fileLink={
                  find(documents, { typeId: 1 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 1 }).id
                    }`
                    : null
                }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>

            {/* IDENTITY VERSO */}
            <div
              className={cn([
                style.dropZoneWrapper,
                'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
              ])}
            >
              <DropZone
                onDrop={droppedFile => postDocument(droppedFile[0], 4)}
                onClearFile={() => handleDocumentClear(4)}
                label={t('middleOffice.identityCardBack')}
                completed={
                  initialUploadedDocuments.length > 0
                  && !isEmpty(find(initialUploadedDocuments, { typeId: 4 }))
                }
                hasFile={
                  documents.length > 0
                  && !isEmpty(find(documents, { typeId: 4 }))
                }
                file={find(documents, { typeId: 4 })}
                fileName={
                  find(documents, { typeId: 4 })
                  && find(documents, { typeId: 4 }).name
                }
                fileLink={
                  find(documents, { typeId: 4 })
                    ? `${API_URL}/documents/${
                      find(documents, { typeId: 4 }).id
                    }`
                    : null
                }
                disabled={loadDocs}
                onFileNameClick={handleFileNameClick}
              />
            </div>
          </div>

          {/* PARENT 2 */}
          {studentFormData.parent2Firstname
          && studentFormData.parent2Lastname ? (
            <>
              <h3 className={style.subtitle}>
                {t('middleOffice.documentsRequired', {
                  studentName: `${studentFormData.parent2Firstname} ${studentFormData.parent2Lastname}`,
                })}
              </h3>
              <div className="row">
                {/* IDENTITY RECTO */}
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], 5)
                    }
                    onClearFile={() => handleDocumentClear(5)}
                    label={t('middleOffice.identityCardFront')}
                    completed={
                      initialUploadedDocuments.length > 0
                      && !isEmpty(find(initialUploadedDocuments, { typeId: 5 }))
                    }
                    hasFile={
                      documents.length > 0
                      && !isEmpty(find(documents, { typeId: 5 }))
                    }
                    file={find(documents, { typeId: 5 })}
                    fileName={
                      find(documents, { typeId: 5 })
                      && find(documents, { typeId: 5 }).name
                    }
                    fileLink={
                      find(documents, { typeId: 5 })
                        ? `${API_URL}/documents/${
                          find(documents, { typeId: 5 }).id
                        }`
                        : null
                    }
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>

                {/* IDENTITY VERSO */}
                <div
                  className={cn([
                    style.dropZoneWrapper,
                    'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3',
                  ])}
                >
                  <DropZone
                    onDrop={droppedFile => postDocument(droppedFile[0], 6)
                    }
                    onClearFile={() => handleDocumentClear(6)}
                    label={t('middleOffice.identityCardBack')}
                    completed={
                      initialUploadedDocuments.length > 0
                      && !isEmpty(find(initialUploadedDocuments, { typeId: 6 }))
                    }
                    hasFile={
                      documents.length > 0
                      && !isEmpty(find(documents, { typeId: 6 }))
                    }
                    file={find(documents, { typeId: 6 })}
                    fileName={
                      find(documents, { typeId: 6 })
                      && find(documents, { typeId: 6 }).name
                    }
                    fileLink={
                      find(documents, { typeId: 6 })
                        ? `${API_URL}/documents/${
                          find(documents, { typeId: 6 }).id
                        }`
                        : null
                    }
                    disabled={loadDocs}
                    onFileNameClick={handleFileNameClick}
                  />
                </div>
              </div>
            </>
            ) : null}

          <div className={style.btnWrapper}>
            <Button
              label={t('middleOffice.send')}
              onClick={handleSendFiles}
              disabled={loadDocs && !documentsEdited}
            />
          </div>
        </Card>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {loadForm || studentFormLoading ? null : (
        <Card noPadding>
          <div className={cn([style.cardStatusBar, getStatusBarStyle()])}>
            <h2 className={style.statusTitle}>
              {t('middleOffice.applicationStatus')}
              {' '}
:
              {' '}
              <span className={style.status}>
                {t(`global.${FORM_STATUS[responseForm.stateId]}`)}
              </span>
            </h2>
            {responseForm.stateId === 4 && auth.role !== 'admin' ? (
              <Button
                className={style.statusBtn}
                label={t('middleOffice.signApplication')}
                onClick={handleSignClick}
                disabled={loadDocuSIgn}
              />
            ) : null}
            {responseForm.stateId === 2
            && responseMsgs
            && responseMsgs.messages.length > 0 ? (
              <Button
                className={style.statusBtn}
                onClick={handleCompleteClick}
                label={t('middleOffice.completeApplication')}
              />
              ) : null}
          </div>
          <div className={style.cardStatusContent}>
            <ul>
              {!loadMsgs && responseMsgs && responseMsgs.messages.length > 0
                ? responseMsgs.messages.map(message => (
                  <StatusMessage
                    key={message.date}
                    status="error"
                    date={format(new Date(message.date), 'dd/MM/')}
                    message={message.text}
                  />
                ))
                : null}
            </ul>
          </div>
        </Card>
      )}
    </WithUserSide>
  );
};

MiddleOffice.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default MiddleOffice;
