import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';
import { saveAs } from 'file-saver';

// Components
import WithSide from '../components/templates/WithSide';
import Card from '../components/atoms/Card';
import Button from '../components/atoms/Button';
import TextField from '../components/atoms/TextField';
import PhoneField from '../components/atoms/PhoneField';
import MediaLabel from '../components/atoms/MediaLabel';
import Radio from '../components/atoms/Radio';
import AutoSave from '../components/atoms/AutoSave';
import Modal, { useModal } from '../components/molecules/Modal';
import AddMedicineForm from '../components/templates/forms/AddMedicineForm';
import FormBlock from '../components/atoms/FormBlock';
import Accordion from '../components/organisms/Accordion';

// Utils & Misc
import cn from '../utils/cn';
import { generalValidate } from '../utils/validators';
import shouldDisable from '../utils/shouldDisable';
import allowToSubmit from '../utils/allowToSubmit';
import useApi from '../hooks/useApi';
import useAppContext from '../hooks/useAppContext';
import { COUNTRY_CODES, FORM_INITIAL_VALUES as FIV } from '../constants/constants';

// Style
import style from './formApp.module.css';
import medicalStyle from './MedicalInformation.module.css';

// TODO remove all useAccordion and refacto should disable

const MedicalInformation = ({ match, history }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const { getDocument } = useApi();
  const {
    context: {
      activeStudent, activeForm, auth, progress,
    }, computeProgress,
  } = useAppContext();
  const {
    loading, responseData: formData, responseError: formError, getStudentForm,
  } = useApi();
  const {
    loading: submitLoading, responseError: submitError, updateStudentForm,
  } = useApi();
  const {
    uploadDocument,
  } = useApi();
  const [medicines, setMedicines] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [medicineToEdit, setMedicineToEdit] = useState(null);
  const [displayMedicine, toggleMedicineModal] = useModal();
  const [displayPrescription, togglePrescriptionModal] = useModal();
  const {
    postFormEvent,
  } = useApi();
  const {
    values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched,
  } = useFormik({
    initialValues: {
      ...FIV.generalInfo,
      ...FIV.scholarshipInfo,
      ...FIV.sportInfo,
      ...FIV.medicalInfo,
      ...FIV.administrativeInfo,
      ...FIV.ratesInfo,
      ...formData,
    },
    enableReinitialize: true,
    validate: generalValidate,
  });

  const isFormLocked = useMemo(() => (
    activeForm ? shouldDisable(activeForm.stateId, auth.role) : true
  ), [activeForm]);

  const submitForm = () => {
    postFormEvent(match.params.id, 'submit').then(() => {
      setSubmitted(true);
      history.push('/confirmation', { studentId: match.params.id });
    });
    // if (allowToSubmit(progress)) postFormEvent(match.params.id, 'submit');
  };

  useEffect(() => {
    getStudentForm(match.params.id);
  }, []);

  useEffect(() => {
    if (formData && formData.medicines) setMedicines(formData.medicines);
    if (formData && formData.prescriptions) setPrescriptions(formData.prescriptions);
  }, [formData]);

  useEffect(() => {
    setFieldValue('prescriptions', prescriptions);
  }, [prescriptions]);

  useEffect(() => {
    setFieldValue('medicines', medicines);
  }, [medicines]);

  const saveForm = () => {
    updateStudentForm(match.params.id, values);
    computeProgress(values, activeForm);
  };

  const handleMedicineAdd = (newValues) => {
    setMedicines(medicines.concat([newValues]));
    toggleMedicineModal();
  };

  const handleMedicineDelete = (medicineIndex) => {
    const newMedicines = medicines.filter((med, index) => index !== medicineIndex);
    setMedicines(newMedicines);
  };

  const handlePrescriptionDelete = (prescriptionIndex) => {
    const newPrescriptions = prescriptions.filter((med, index) => index !== prescriptionIndex);
    setPrescriptions(newPrescriptions);
    setFieldValue('prescriptions', newPrescriptions);
  };

  const handlePrescriptionClick = (prescriptionId, name) => {
    getDocument(prescriptionId, activeStudent.id).then((response) => {
      if (response) saveAs(response.data, name);
    });
  };

  const handleEditMedicine = (medicineIndex) => {
    setMedicineToEdit(medicineIndex);
    toggleMedicineModal();
  };

  const handlePrescriptionChange = (e) => {
    const file = e.target.files[0];

    uploadDocument(file, activeStudent.id).then((response) => {
      if (response && response.id) {
        const newPrescriptions = prescriptions.concat([{
          name: file.name,
          id: response.id,
        }]);
        setPrescriptions(newPrescriptions);
        // setFieldValue('prescriptions', newPrescriptions);
        togglePrescriptionModal();
      }
    });
  };

  const getGuardianIdentity = (newValues) => {
    if (newValues.parentsSeparated === 'true' && newValues.guardian === 'Parent 1') {
      return `${newValues.parent1Firstname} ${newValues.parent1Lastname}`;
    }
    if (newValues.parentsSeparated === 'true' && newValues.guardian === 'Parent 2') {
      return `${newValues.parent2Firstname} ${newValues.parent2Lastname}`;
    }
    if (newValues.parentsSeparated === 'true' && newValues.guardian === 'other') {
      return `${values.guardianFirstname} ${values.guardianLastname}`;
    }
    if (newValues.parentsSeparated === 'false' || !newValues.parentsSeparated) {
      return `${newValues.parent1Firstname} ${newValues.parent1Lastname}`;
    }
    return '';
  };

  const getGuardianAddress = (newValues) => {
    if (newValues.parentsSeparated === 'true' && newValues.guardian === 'Parent 1') {
      return newValues.parent1Address;
    }
    if (newValues.parentsSeparated === 'true' && newValues.guardian === 'Parent 2') {
      return newValues.parent2Address;
    }
    if (newValues.parentsSeparated === 'true' && newValues.guardian === 'other') {
      return values.guardianAddress;
    }
    if (newValues.parentsSeparated === 'false' || !newValues.parentsSeparated) {
      return newValues.parent1Address;
    }
    return '';
  };

  const hasSocialSecurityNumber = useMemo(() => (
    values.hasSocialSecurityNumber === true || values.hasSocialSecurityNumber === 'true'
  ), [values]);
  const noSocialSecurityNumber = useMemo(() => (
    values.hasSocialSecurityNumber === false || values.hasSocialSecurityNumber === 'false'
  ), [values]);

  const hasMedicalInsurance = useMemo(() => (
    values.hasMedicalInsurance === true || values.hasMedicalInsurance === 'true'
  ), [values]);
  const noMedicalInsurance = useMemo(() => (
    values.hasMedicalInsurance === false || values.hasMedicalInsurance === 'false'
  ), [values]);

  return (
    <WithSide title={t('medicalInformation.viewTitle')}>
      {loading
        ? <div />
        : (
          <form>
            <Card className="card-margin">
              <h2 className="card-title">{t('medicalInformation.emergencySheet')}</h2>

              {/* Emergency informations */}
              <fieldset className={style.fieldSet}>
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <TextField
                      label={t('medicalInformation.firstname')}
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={values.firstname}
                      disabled
                    />
                    <TextField
                      label={t('medicalInformation.lastname')}
                      type="text"
                      name="lastname"
                      id="lastname"
                      value={values.lastname}
                      disabled
                    />
                    <TextField
                      label={t('medicalInformation.birthdate')}
                      type="text"
                      name="birthdate"
                      id="birthdate"
                      value={values.birthdate}
                      disabled
                    />
                    <TextField
                      label={t('medicalInformation.legalGuardian')}
                      type="text"
                      name="legalGuardian"
                      id="legalGuardian"
                      value={getGuardianIdentity(values)}
                      disabled
                    />
                    <TextField
                      label={t('medicalInformation.guardiansAdresse')}
                      type="text"
                      name="legalGuardian"
                      id="legalGuardian"
                      value={getGuardianAddress(values)}
                      disabled
                    />
                  </div>
                </div>
              </fieldset>

              {/* Health insurance */}
              <div
                className={hasSocialSecurityNumber
                  ? medicalStyle.no_mb
                  : medicalStyle.form_section_small}
              >
                <FormBlock label={t('medicalInformation.hasSocialSecurityNumber')}>
                  <Radio
                    id="hasSocialSecurityNumber-yes"
                    name="hasSocialSecurityNumber"
                    value="true"
                    label={t('medicalInformation.yes')}
                    onChange={() => setFieldValue('hasSocialSecurityNumber', true)}
                    checked={hasSocialSecurityNumber}
                    disabled={isFormLocked}
                  />
                  <Radio
                    id="hasSocialSecurityNumber-no"
                    name="hasSocialSecurityNumber"
                    value="false"
                    label={t('medicalInformation.no')}
                    onChange={() => setFieldValue('hasSocialSecurityNumber', false)}
                    checked={noSocialSecurityNumber}
                    disabled={isFormLocked}
                  />
                </FormBlock>
              </div>

              {hasSocialSecurityNumber ? (
                <div className={medicalStyle.form_section_small}>
                  <TextField
                    label={t('medicalInformation.dptVaccine')}
                    type="text"
                    name="dptVaccineDate"
                    id="dptVaccineDate"
                    value={values.dptVaccineDate}
                    error={errors.dptVaccineDate && touched.dptVaccineDate
                      ? t(`form.errors.${errors.dptVaccineDate}`) : null
                        }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled={isFormLocked}
                  />
                </div>
              ) : null}

              {noSocialSecurityNumber ? (
                <>
                  <div className={medicalStyle.form_section_small}>
                    <FormBlock label={t('medicalInformation.hasMedicalInsurance')}>
                      <Radio
                        id="hasMedicalInsurance-yes"
                        name="hasMedicalInsurance"
                        value="true"
                        label={t('medicalInformation.yes')}
                        onChange={() => setFieldValue('hasMedicalInsurance', true)}
                        checked={values.hasMedicalInsurance === true || values.hasMedicalInsurance === 'true'}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="hasMedicalInsurance-no"
                        name="hasMedicalInsurance"
                        value="false"
                        label={t('medicalInformation.no')}
                        onChange={() => setFieldValue('hasMedicalInsurance', false)}
                        checked={values.hasMedicalInsurance === false || values.hasMedicalInsurance === 'false'}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                  </div>

                  {hasMedicalInsurance ? (
                    <div className={medicalStyle.form_section}>
                      <p className={cn([style.interParagraph, medicalStyle.no_mb])}>
                        {t('medicalInformation.coordSocSecurity')}
                      </p>

                      <div className="row">
                        <div className="col-xs-12 col-md-10">
                          <TextField
                            label={t('medicalInformation.organization')}
                            type="text"
                            name="medInsuranceName"
                            id="medInsuranceName"
                            value={values.medInsuranceName}
                            error={errors.medInsuranceName && touched.medInsuranceName
                              ? t(`form.errors.${errors.medInsuranceName}`) : null
                          }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-md-10">
                          <PhoneField
                            id="medInsurancePhone"
                            name="medInsurancePhone"
                            label={t('generalInformation.phone')}
                            selectPlaceholder={t('generalInformation.country')}
                            countriesList={COUNTRY_CODES.map(({ code }) => code)}
                            initialValue={values.medInsurancePhone}
                            onPhoneChange={({
                              fullNumber, e164, code, iso,
                            }) => {
                              setFieldTouched('medInsurancePhone');
                              setFieldValue('medInsurancePhoneDetails', { e164, code, iso });
                              setFieldValue('medInsurancePhone', fullNumber);
                            }}
                            error={errors.medInsurancePhone && touched.medInsurancePhone
                              ? t(`form.errors.${errors.medInsurancePhone}`) : null
                          }
                            required
                            disabled={isFormLocked}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-md-10">
                          <TextField
                            label={t('medicalInformation.country')}
                            type="text"
                            name="medInsuranceCountry"
                            id="medInsuranceCountry"
                            value={values.medInsuranceCountry}
                            error={errors.medInsuranceCountry && touched.medInsuranceCountry
                              ? t(`form.errors.${errors.medInsuranceCountry}`) : null
                          }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            disabled={isFormLocked}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {noMedicalInsurance ? (
                    <p className={medicalStyle.form_section}>
                      {t('medicalInformation.noMedicalInsuranceWarning')}
                    </p>
                  ) : null}
                </>
              ) : null}

              {/* Emergency contact */}
              <fieldset
                className={cn([medicalStyle.margin_top, style.fieldSet, style.fieldSetWithMargin])}
              >
                <p className={cn([style.interParagraph, style.inputMargin, medicalStyle.no_mb])}>
                  {t('medicalInformation.emergency')}
                </p>
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <PhoneField
                      id="emergencyPhone1"
                      name="emergencyPhone1"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.emergencyPhone1 || values.parent1Phone}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('emergencyPhone1');
                        setFieldValue('emergencyPhone1Details', { e164, code, iso });
                        setFieldValue('emergencyPhone1', fullNumber);
                      }}
                      error={errors.emergencyPhone1 && touched.emergencyPhone1
                        ? t(`form.errors.${errors.emergencyPhone1}`) : null
                        }
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <PhoneField
                      id="emergencyPhone2"
                      name="emergencyPhone2"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.emergencyPhone2 || values.parent2Phone}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('emergencyPhone2');
                        setFieldValue('emergencyPhone2Details', { e164, code, iso });
                        setFieldValue('emergencyPhone2', fullNumber);
                      }}
                      error={errors.emergencyPhone2 && touched.emergencyPhone2
                        ? t(`form.errors.${errors.emergencyPhone2}`) : null
                        }
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <PhoneField
                      id="emergencyPhone3"
                      name="emergencyPhone3"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.emergencyPhone3}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('emergencyPhone3');
                        setFieldValue('emergencyPhone3Details', { e164, code, iso });
                        setFieldValue('emergencyPhone3', fullNumber);
                      }}
                      error={errors.emergencyPhone3 && touched.emergencyPhone3
                        ? t(`form.errors.${errors.emergencyPhone3}`) : null
                        }
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
              </fieldset>

              {/* DOCTOR */}
              <div className={cn([medicalStyle.margin_top, medicalStyle.form_section_large])}>
                <p className={style.interParagraph}>{t('medicalInformation.coordDoctorRef')}</p>
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <TextField
                      label={t('medicalInformation.doctorName')}
                      type="text"
                      name="doctorName"
                      id="doctorName"
                      value={values.doctorName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-10">
                    <PhoneField
                      id="doctorPhone"
                      name="doctorPhone"
                      label={t('generalInformation.phone')}
                      selectPlaceholder={t('generalInformation.country')}
                      countriesList={COUNTRY_CODES.map(({ code }) => code)}
                      initialValue={values.doctorPhone}
                      onPhoneChange={({
                        fullNumber, e164, code, iso,
                      }) => {
                        setFieldTouched('doctorPhone');
                        setFieldValue('doctorPhoneDetails', { e164, code, iso });
                        setFieldValue('doctorPhone', fullNumber);
                      }}
                      error={errors.doctorPhone && touched.doctorPhone
                        ? t(`form.errors.${errors.doctorPhone}`) : null
                      }
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
              </div>

              {/* Special observations */}
              <fieldset className={cn([style.fieldSetFullWidth, medicalStyle.padding_top])}>
                <p className={style.interParagraph}>{t('medicalInformation.specialObservations')}</p>

                <div className="row">
                  <div className="col-xs-12 col-lg-10 md-10">
                    <FormBlock label={t('medicalInformation.specialTreatment')}>
                      <Radio
                        id="specificTreatment-yes"
                        name="specificTreatment"
                        value="true"
                        label={t('medicalInformation.yes')}
                        onChange={handleChange}
                        checked={values.specificTreatment === 'true'}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="specificTreatment-no"
                        name="specificTreatment"
                        value="false"
                        label={t('medicalInformation.no')}
                        onChange={handleChange}
                        checked={values.specificTreatment === 'false'}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <TextField
                      label={`
                        ${t('medicalInformation.allergies')}
                        ${', '}
                        ${t('medicalInformation.chronicDiseases')}
                        ${', '}
                        ${t('medicalInformation.ongoingTreatment')}
                        ${'...'}
                      `}
                      type="text"
                      name="specialObservations"
                      id="specialObservations"
                      value={values.specialObservations}
                      onChange={handleChange}
                      required
                      disabled={isFormLocked}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <Accordion isToggled={values.specificTreatment === 'true'}>
                      <TextField
                        label={t('medicalInformation.treatment')}
                        type="text"
                        name="treatment"
                        id="treatment"
                        value={values.treatment}
                        onChange={handleChange}
                        required
                        disabled={isFormLocked}
                      />

                    </Accordion>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className={style.mediaLabelsRow}>
                      {/* FIXME: Disabled the add medicine modal, it is now a text input */}
                      {/* <MediaLabel icon="plus" btnStyle="border" onClick={toggleMedicineModal}>
                        <span className={style.text}>{t('medicalInformation.addMedicine')}</span>
                      </MediaLabel> */}
                      <MediaLabel icon="plus" btnStyle="border" onClick={togglePrescriptionModal}>
                        <span className={style.text}>{t('medicalInformation.addPrescription')}</span>
                      </MediaLabel>
                    </div>
                  </div>
                </div>
                {medicines.length > 0
                  ? (
                    <div className="row">
                      <div className="col-xs-12">
                        <div className={style.mediaLabelsRow}>
                          {medicines.map(({ medicineName }, index) => (
                            <MediaLabel
                              key={medicineName}
                              icon="plus"
                              btnStyle="fill"
                              textOverflow={medicineName.length > 10}
                              onDelete={() => handleMedicineDelete(index)}
                              onClick={() => handleEditMedicine(index)}
                              isActive
                            >
                              <span className={style.text}>{medicineName.length > 10 ? `${medicineName.substring(0, 10)}...` : medicineName}</span>
                            </MediaLabel>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                {prescriptions.length > 0
                  ? (
                    <div className="row">
                      <div className="col-xs-12">
                        <div className={style.mediaLabelsRow}>
                          {prescriptions.map(({ name, id }, index) => (
                            <MediaLabel
                              key={id}
                              icon="plus"
                              btnStyle="fill"
                              textOverflow={name.length > 10}
                              onClick={() => handlePrescriptionClick(id, name)}
                              onDelete={() => handlePrescriptionDelete(index)}
                              isActive
                            >
                              <span className={style.text}>{name.length > 10 ? `${name.substring(0, 10)}...` : name}</span>
                            </MediaLabel>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                <p className={style.text}>{t('medicalInformation.medicalTreatmentAuth')}</p>

                {/* DOES WEAR GLASSES */}
                <div className="row">
                  <div className="col-xs-12 col-lg-10 md-10">
                    <FormBlock label={t('medicalInformation.doesWearGlasses')}>
                      <Radio
                        id="doesWearGlasses-yes"
                        name="doesWearGlasses"
                        value="true"
                        label={t('medicalInformation.yes')}
                        onChange={handleChange}
                        checked={values.doesWearGlasses === 'true'}
                        disabled={isFormLocked}
                      />
                      <Radio
                        id="doesWearGlasses-no"
                        name="doesWearGlasses"
                        value="false"
                        label={t('medicalInformation.no')}
                        onChange={handleChange}
                        checked={values.doesWearGlasses === 'false'}
                        disabled={isFormLocked}
                      />
                    </FormBlock>
                  </div>
                </div>
              </fieldset>

              {/* Paracetamol */}
              <p className={style.interParagraph}>{t('medicalInformation.paracetamol')}</p>
              <div className="row">
                <div className="col-xs-12">
                  <label>{t('medicalInformation.paracetamolAuth')}</label>
                  <div className={medicalStyle.acceptParacetamolRadios}>
                    <Radio
                      id="paracetamolAuthYes"
                      name="paracetamolAuthYes"
                      value="true"
                      label={t('global.yes')}
                      onChange={() => setFieldValue('acceptParacetamol', true)}
                      checked={values.acceptParacetamol === true || values.acceptParacetamol === 'true'}
                      disabled={isFormLocked}
                    />
                    <Radio
                      id="paracetamolAuthNo"
                      name="paracetamolAuthNo"
                      value="false"
                      label={t('global.no')}
                      onChange={() => setFieldValue('acceptParacetamol', false)}
                      checked={values.acceptParacetamol === false || values.acceptParacetamol === 'false'}
                      disabled={isFormLocked}
                    />
                  </div>
                </div>
              </div>

              {/* Emergency certifications */}
              <p className={cn([style.inputMargin, medicalStyle.margin_top])}>
                <Trans
                  i18nKey="medicalInformation.emergencyCertification"
                  count={values.parent2Firstname ? 2 : 1}
                >
                  This document certifies that
                  <strong>{{ parent1: `${values.parent1Firstname} ${values.parent1Lastname}` }}</strong>
                  {values.parent2Firstname
                    ? <strong>{{ parent2: ` & ${values.parent2Firstname} ${values.parent2Lastname}` }}</strong>
                    : null
                  }
                  parent of
                  <strong>{{ child: `${values.firstname} ${values.lastname}` }}</strong>
                  authorize the Academy to perform on their behalf necessary acts in case of
                  hospitalization entailing the obligation to intervene of any kind
                  including surgery.
                </Trans>
              </p>
              {activeForm.schoolId === 1
                ? (
                  <div className="row">
                    <div className="col-xs-12">
                      <p>
                        <b>
                          {t('medicalInformation.confidentialDoc')}
                          {' '}
                            :
                          {' '}
                        </b>
                        {t('medicalInformation.medicalConfDoc')}
                      </p>
                    </div>
                  </div>
                )
                : null
              }
            </Card>


            {/* Submit block */}
            <div className={style.submitBlock}>
              {formError || isFormLocked ? (
                <div>{formError || isFormLocked}</div>
              ) : (
                <AutoSave
                  formId={match.params.id}
                  values={values}
                  isSaving={submitLoading}
                  onSave={updateStudentForm}
                  error={submitError}
                />
              )}
              <Button
                className={style.submitForm}
                type="button"
                color="green"
                label={
                submitted || isFormLocked
                  ? t('ratesInformation.submitApplicationSuccess')
                  : t('ratesInformation.submitApplication')
              }
                onClick={submitForm}
                disabled={!allowToSubmit(progress) || submitted || isFormLocked}
              />
              <Button
                className={style.submit}
                type="button"
                label={t('generalInformation.next')}
                onClick={() => saveForm(values)}
                disabled={submitLoading}
              />
            </div>
          </form>
        )
      }

      {/* Modals */}

      <Modal title={t('medicalInformation.addMedicine')} isDisplayed={displayMedicine} onClose={toggleMedicineModal}>
        <AddMedicineForm
          onSubmit={handleMedicineAdd}
          initialValues={medicines[medicineToEdit] || null}
        />
      </Modal>
      <Modal title={t('medicalInformation.addPrescription')} isDisplayed={displayPrescription} onClose={togglePrescriptionModal}>
        <input type="file" onChange={handlePrescriptionChange} />
      </Modal>
    </WithSide>
  );
};

MedicalInformation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape().isRequired,
};

export default MedicalInformation;
