import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SPA_HOSTNAME } from '../../constants/constants';

// Component
import ProgressCircle from '../atoms/ProgressCircle';
import MenuList from '../molecules/MenuList';
import SideHint from '../molecules/SideHint';
import Header from '../organisms/Header';
import Footer from '../organisms/Footer';
import SideMenu from '../organisms/SideMenu';
import MenuMobile from '../organisms/MenuMobile';

// Utils & Misc
import cn from '../../utils/cn';
import useAppContext from '../../hooks/useAppContext';
import { KeycloakAuthContext } from './KeycloakAuthProvider';

import infoImage from '../../assets/icons/info.png';
import logoShort from '../../assets/icons/logoshort.png';

// Style
import style from './WithSide.module.css';


const WithSide = ({ children, title }) => {
  const { t } = useTranslation();
  const { context: { activeStudent, progress }, clearAuth } = useAppContext();
  const { keycloak } = useContext(KeycloakAuthContext);

  const navPaths = useMemo(() => (
    activeStudent ? (
      [
        { to: `/${activeStudent.currentFormId}/general-information`, i18nKey: 'generalInformation', icon: 'userCircle' },
        { to: `/${activeStudent.currentFormId}/scholarship-information`, i18nKey: 'scholarshipInformation', icon: 'mortarboard' },
        { to: `/${activeStudent.currentFormId}/sports-information`, i18nKey: 'sportsInformation', icon: 'tennisPlayer' },
        { to: `/${activeStudent.currentFormId}/medical-information`, i18nKey: 'medicalInformation', icon: 'stethoscope' },
        { to: `/${activeStudent.currentFormId}/administrative-information`, i18nKey: 'administrativeInformation', icon: 'file' },
        { to: `/${activeStudent.currentFormId}/rates-information`, i18nKey: 'ratesInformation', icon: 'squareTargeting' },
      ]
    ) : []
  ), [activeStudent]);

  const logout = () => {
    clearAuth();
    keycloak.logout({
      redirectUri: `https://${SPA_HOSTNAME}`,
    });
  };

  const headerMenuOptions = useMemo(() => (
    [
      { to: activeStudent ? `/${activeStudent.currentFormId}/dashboard` : '/', label: t('global.dashboard') },
      { to: '', onClick: logout, label: t('global.disconnect') },
    ]
  ), [activeStudent]);

  return (
    <div className={style.wrapper}>
      <Header
        color="blue"
        languages={['fr', 'en']}
        user={activeStudent}
        menuOptions={headerMenuOptions}
      />
      <MenuMobile
        title={t('sideMenu.title')}
        subtitle={t('sideMenu.subtitle')}
        navItems={navPaths}
        className={style.menuMobile}
      />
      <SideMenu
        title={t('sideMenu.title')}
        subtitle={t('sideMenu.subtitle')}
        className={style.sideMenu}
      >
        {navPaths.map(({ to, i18nKey, icon }) => (
          <MenuList key={i18nKey} to={to} label={t(`sideMenu.${i18nKey}`)} icon={icon}>
            <ProgressCircle isComplete={progress ? progress[i18nKey] >= 1 : false} number={progress ? (Math.ceil(progress[i18nKey] * 100) || 0) : 0} unit="%" isActive />
          </MenuList>
        ))}
      </SideMenu>
      <div className="container-fluid">
        <div className={cn(['row', style.titleRow])}>
          <h1 className={style.pageTitle}>{title}</h1>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-9">
            <main className={style.main}>
              {children}

            </main>
          </div>
          <div className="col-md-3">
            <SideHint
              title={t('sideHint.help.title')}
              alignType="centered"
              background={<img className={style.imageBackground} src={logoShort} alt="Logo" />}
              className={style.sideStick}
            >
              <p>{t('sideHint.help.text')}</p>
              <p>{t('sideHint.help.phone')}</p>
            </SideHint>
            <SideHint
              alignType="deferred"
              title={t('sideHint.info.title')}
              background={<img className={style.imageBackground} src={infoImage} alt="Info" />}
              className={style.sides}
            >
              <p>{t('sideHint.info.text')}</p>
            </SideHint>
          </div>
        </div>
        <Footer color="black" className={style.footer} />

      </div>
    </div>
  );
};
WithSide.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default WithSide;
