import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils & Misc
import cn from '../../../utils/cn';

// Style
import style from './Range.module.css';

export const Range = ({
  id,
  label,
  showSteps,
  max,
  min,
  step,
  defaultValue,
  value,
  onChange,
  rangeLabels,
  className,
  ...propsLeft
}) => {
  const steps = Array(((max - min) + 1) / step).fill().map((_, idx) => Number(min) + idx);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (defaultValue) setInputValue(defaultValue);
  }, []);

  useEffect(() => {
    if (value && inputValue !== value) setInputValue(value);
  }, [value]);

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <label htmlFor={id} className={cn([style.label, className])}>
      {label}
      <div className={cn([style.inputGroup, rangeLabels ? style.withLabels : null])}>
        {rangeLabels
          ? (
            <span className={cn([
              style.rangeLabelLeft,
              Number(inputValue) === Number(min) ? style.rangeLabelActive : '',
            ])}
            >
              {rangeLabels[0]}
            </span>
          )
          : null
        }
        <input
          type="range"
          className={style.input}
          id={id}
          max={max}
          min={min}
          step={step}
          value={value}
          onChange={handleOnChange}
          {...propsLeft}
        />
        {showSteps
          ? (
            <div className={style.dots}>
              {steps.map(oneStep => (
                <div key={oneStep} className={cn([style.dot, oneStep <= inputValue ? style.active : ''])} />
              ))}
            </div>
          )
          : null}
        <div
          className={style.progress}
          // Minus the padding added in CSS
          style={{ width: `calc(${((inputValue - min) / (max - min)) * 100}% - 0.625rem)` }}
        />
        <div className={style.rulerbar} />
        {rangeLabels
          ? (
            <span className={cn([
              style.rangeLabelRight,
              Number(inputValue) === Number(max) ? style.rangeLabelActive : '',
            ])}
            >
              {rangeLabels[1]}
            </span>
          )
          : null
        }
      </div>
    </label>
  );
};

Range.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  showSteps: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  rangeLabels: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

Range.defaultProps = {
  showSteps: false,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
  rangeLabels: null,
  className: null,
};

export default Range;
