import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Components
import useAppContext from '../../../hooks/useAppContext';
import Picto from '../../atoms/Picto';

// Utils & misc
import cn from '../../../utils/cn';

// Style
import style from './MenuMobile.module.css';

export const MenuMobile = ({
  title, subtitle, navItems, className,
}) => {
  const {
    context: { progress },
  } = useAppContext();
  return (
    <>
      <div className={className}>
        {title || subtitle ? (
          <div className={style.titleBlock}>
            {title ? <span className={style.title}>{title}</span> : null}
            {subtitle ? (
              <span className={style.subtitle}>{subtitle}</span>
            ) : null}
          </div>
        ) : null}
      </div>
      <nav className={cn([style.navBar, className])}>
        <ul className={style.navList}>
          {navItems.map(({ to, i18nKey, icon }) => (
            <NavLink
              key={to}
              to={to}
              activeClassName={style.active}
              className={style.navItem}
            >
              <li className={style.navItemWrapper}>
                <Picto className={style.picto} icon={icon} />
              </li>
              <div className={style.percent}>
                <div className={style.progress} style={{ width: `${progress ? Math.ceil(progress[i18nKey] * 100) : 0}%` }} />
              </div>
            </NavLink>
          ))}
        </ul>
      </nav>
    </>
  );
};

MenuMobile.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

MenuMobile.defaultProps = {
  title: null,
  subtitle: null,
  className: null,
};

export default MenuMobile;
