import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from '../../atoms/Card';
import Picto from '../../atoms/Picto';

// Style
import style from './ProfilCard.module.css';

export const ProfilCard = ({ label, picture, onEditClick }) => (
  <Card noPadding>
    <div className={style.content}>
      <div className={style.pictureWrapper}>
        {picture
          ? <img className={style.picture} src={picture} alt="player's profil" />
          : <Picto className={style.userPicto} icon="userCircle" />
        }
        <button className={style.editBtn} type="button" onClick={onEditClick}>
          <Picto icon="pen" />
        </button>
      </div>
      <span className={style.label}>{label}</span>
    </div>
  </Card>
);

ProfilCard.propTypes = {
  label: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  picture: PropTypes.string,
};

ProfilCard.defaultProps = {
  picture: null,
};

export default ProfilCard;
