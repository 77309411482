import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Picto from '../../atoms/Picto';

// Style
import style from './PlayerSelectItem.module.css';

export const PlayerSelectItem = ({
  picture, name, onClick,
}) => {
  const handleKeyPress = e => e.key === 'Enter' && onClick();
  return (
    <li className={style.listItem}>
      <div
        role="button"
        tabIndex={0}
        className={style.linkWrapper}
        onClick={onClick}
        onKeyPress={handleKeyPress}
      >
        {picture
          ? <img className={style.avatar} src={picture} alt="player profil" />
          : <Picto className={style.picto} icon="userCircle" />
        }
        <span className={style.text}>{name}</span>
      </div>
    </li>
  );
};
PlayerSelectItem.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string,
  onClick: PropTypes.func,
};

PlayerSelectItem.defaultProps = {
  picture: null,
  onClick: null,
};

export default PlayerSelectItem;
