import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import NoSide from '../components/templates/NoSide';
import Card from '../components/atoms/Card';
import PlayerSelectItem from '../components/molecules/PlayerSelectItem';

// Context
import useAppContext from '../hooks/useAppContext';
import useApi from '../hooks/useApi';

// Utils & misc
import background from '../assets/images/login_background.png';
import { ReactComponent as Loader } from '../assets/loader/oval.svg';

// Style
import style from './Students.module.css';

const Students = ({ history }) => {
  const { t } = useTranslation();
  const { context: { auth, activeStudent }, setActiveStudent } = useAppContext();
  const {
    loading, responseData, getGuardian,
  } = useApi();
  const { getFormInfo } = useApi();

  const handleStudentClick = (selectedStudent, path) => {
    setActiveStudent(selectedStudent);
    getFormInfo(selectedStudent.currentFormId)
      .then(res => res.id && history.push(path));
  };

  useEffect(() => {
    // Request /me to know if user is authorized to access Moura SE and store user data in context
    if (auth && auth.role !== 'admin') getGuardian();
    // For admins, skip the list
    if (auth.role === 'admin' && activeStudent) {
      handleStudentClick(activeStudent, `/${activeStudent.currentFormId}/general-information`);
    }
  }, []);

  return (
    <NoSide title={t('student.title')} subtitle={t('student.subtitle')} background={background}>
      <Card
        className={style.card}
        rounded
        noPadding
      >
        <span className={style.cardTitle}>{t('student.cardTitle')}</span>

        <ul className={style.playerList}>
          {loading
            ? <div className={style.loaderWrapper}><Loader /></div>
            : null
          }
          {!loading && responseData && responseData.students.map(student => (
            <PlayerSelectItem
              key={student.id}
              picture={student.thumbnail}
              name={`${student.firstname} ${student.lastname}`}
              to={`/${student.currentFormId}/general-information`}
              onClick={() => handleStudentClick(student, `/${student.currentFormId}/general-information`)}
            />
          ))}
        </ul>
      </Card>
    </NoSide>
  );
};

Students.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Students;
